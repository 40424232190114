import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {SettingsAboutusComponent} from "./settings-aboutus.component";


@NgModule({
    imports: [CommonModule],
    declarations: [SettingsAboutusComponent],
    exports: [SettingsAboutusComponent]
})

export class SettingsAboutusModule {}
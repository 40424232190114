import {Component, ViewEncapsulation} from '@angular/core';
import {ArticleBaseWithChildrenSelector, Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {ArticleService} from "../articles/article.service";
import {ContactService} from "./services/contact.service";
import {SocialSelector} from "../settings-social/common";

@Component({
    selector: 'cmp-footer',
    templateUrl: '../../tpl/app-footer.html',
    styleUrls: ['../../assets/styles/3-layout/footer.scss'],
    encapsulation: ViewEncapsulation.None
})

export class AppFooterComponent extends Translatable {

    footerRootArticle: ArticleBaseWithChildrenSelector;
    socialData: SocialSelector;

    ngUnsubscribe: Subject<any> = new Subject<any>();

    get articles(): Array<ArticleBaseWithChildrenSelector> {
        if (!this.footerRootArticle || !this.footerRootArticle.childArticles || !this.footerRootArticle.childArticles.length) {
            return null;
        }

        return this.footerRootArticle.childArticles;
    }

    constructor(public dataSvc: DataService, seSvc: SettingsService, private articleSvc: ArticleService, private conSvc: ContactService) {
        super(dataSvc, seSvc);


    }

    ngOnInit(): void {
        this.getFooterArticles();
        this.getSocialData();
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        super.ngOnDestroy();
    }

    getFooterArticles(): void {
        this.articleSvc.getFooterArticles()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (res) => {
                    this.footerRootArticle = res;
                }
            );
    }
    private getSocialData(): void {
        this.conSvc.getSocialData()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((res) => {
                this.socialData = res;
            });
    }

    get isSocialVisible(): boolean{
        return this.socialData && (this.socialData.facebook !== "" || this.socialData.instagram !== "" || this.socialData.pinterest !== "" || this.socialData.linkedIn !== "" || this.socialData.twitter !== "" || this.socialData.youTube !== "")
    }

}
import {EventEmitter, Injectable} from "@angular/core";
import {AuthInfo, LoginEmitterMessage, LoginResult} from "../interfaces/general";
import {SettingsService} from "./settings.service";
import {Router} from "@angular/router";
import {HttpClient, HttpErrorResponse, HttpHeaders} from "@angular/common/http";
import {CredentialStorage} from "./credential-storage.service";
import {CartService} from "../modules/cart/cart.service";
import {removeFromSession} from "../helpers/cookie.helper";
import {DeliverySettingsService} from "./delivery-settings.service";

declare let moment: any;

// todo: UNIT TEST FOR THIS SERVICE

@Injectable()
export class DigestService {

    badCredentialsMessage: string = 'bad credentials, login failed';
    errorType: string = 'error';

    userName: string;
    password: string;
    requestedUri: string;
    requestedMethod: string;

    loginStatus: EventEmitter<LoginEmitterMessage>;
    onLoginQuery: EventEmitter<any> = new EventEmitter<any>();

    constructor(private http: HttpClient, private seSvc: SettingsService,
                private router: Router, private  cartSvc: CartService, private dsSvc: DeliverySettingsService) {

        let aiValid: boolean = false;

        const ai: AuthInfo = CredentialStorage.authInfo;
        if (ai) {
            const mt = moment();
            const mtValidTo = moment(ai.validTo);
            if (mtValidTo > mt) {
                aiValid = true;
            }
        }

        if (!aiValid) {
            /**
             * This means fresh start, nothing in session/local, we settle new values
             */
            DigestService.initNotSigned();
        } else {
            /**
             * this means there was full page reload, so we rather take stored values
             * */
            this.userName = ai.userName;
        }

        this.loginStatus = new EventEmitter();
    }

    private static initNotSigned(): void {
        CredentialStorage.removeAuthInfo();
    }

    login(userName: string, pwd: string, redirectUrlAfterLogin: string = null): void {

        this.requestedUri = 'api/login';
        this.requestedMethod = 'GET';

        this.userName = userName;
        this.password = pwd;
        let headers: HttpHeaders = this.getLoginHeaders();

        this.dsSvc.ClearDeliverySettings();
        this.http.get<LoginResult>(this.requestedUri, {headers: headers})
            .subscribe(
                (r) => {
                    CredentialStorage.authInfo = {
                        jwTokenBody: r.jwTokenBody,
                        userName: this.userName,
                        displayName: r.companyDisplayName,
                        loggedIn: true,
                        readOnly: r.readOnly,
                        hideBanners: r.hideBanners,
                        hideCartAddInfo: r.hideCartAddInfo,
                        validTo: moment().add(r.tokenExpirationMinutes, 'minutes').format()

                    };

                    this.dsSvc.setDeliverySettings(r.companyId, r.pickUpTypeId, r.deliveryOADate);
                    this.seSvc.setCulture(r.cultureId, r.currencyId);

                    if(redirectUrlAfterLogin) {
                        this.router.navigateByUrl(redirectUrlAfterLogin).then(() => {
                            location.reload();
                        });
                    } else {
                        location.href = this.seSvc.baseHref + 'eshop';
                        /*this.router.navigateByUrl('/eshop').then(() => {
                            location.reload();
                        });*/

                    }
                },
                (e: HttpErrorResponse) => {
                    this.loginStatus.emit({
                        type: this.errorType,
                        message: this.badCredentialsMessage,
                        data: e
                    });
                }
            );
    }

    quietLogOut(): void {
        CredentialStorage.removeAuthInfo();
        this.userName = undefined;
        this.password = undefined;
        removeFromSession('cadj');

        this.cartSvc.cleanCartOnLogout();
    }

    logOut() {
        this.quietLogOut();

        location.href = this.seSvc.baseHref;
        return;
    }

    queryLogin(): void {
        this.onLoginQuery.emit();
    }

    private getLoginHeaders(): HttpHeaders {
        let result = `Basic username="${this.userName}", password="${this.password}"`;

        return new HttpHeaders({'Authorization': result});
    }
}

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { ImageCacheBustModule } from "../../directives/image-cache-bust.module";
import { ActionCategoryFilterComponent } from '../category/action-category-filter.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ImageCacheBustModule
    ],
    declarations: [
        ActionCategoryFilterComponent
    ],
    exports: [
        ActionCategoryFilterComponent
    ]
})

export class ActionCategoryFilterModule {
}

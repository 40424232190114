import { Component, ViewEncapsulation } from "@angular/core";
import { DataService } from "../../services/data.service";
import { SettingsService } from "../../services/settings.service";
import { Translatable } from "../../interfaces/general";
import { HttpClient } from "@angular/common/http";
import { IShopRatingSelector } from './common';
import { takeUntil } from "rxjs/operators"; //finalize,
import { Subject } from "rxjs";
import { CredentialStorage } from "../../services/credential-storage.service";

@Component({
    selector: 'cmp-rating-shop',
    templateUrl: '../../tpl/rating-shop.html',
    styleUrls: ['../../assets/styles/2-components/rating.scss'],
    encapsulation: ViewEncapsulation.None
})
export class RatingShopComponent extends Translatable {

    public hideStripe = false;
    private ngUnsubscribe: Subject<any> = new Subject<any>();
    public show: boolean = false;
    public rating: number = 3;
    public saved: boolean = false;
    public saving: boolean = false;
    public description: string = '';

    public get userLoggedIn(): boolean {
        return CredentialStorage.userLoggedIn;
    }

    constructor(dataSvc: DataService, seSvc: SettingsService, private http: HttpClient) {
        super(dataSvc, seSvc);
        this.hideStripe =   localStorage.getItem('ShopRatingClosed') == '1';
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        super.ngOnDestroy();
    }

    public close() {
        localStorage.setItem('ShopRatingClosed', '1');
        this.hideStripe = true;
        this.show = false;
    }

    public save() {
        if (this.saving) return;
        this.saving = true;
        let data: IShopRatingSelector = {
            stars: this.rating,
            description: this.description
        }
        this.http.post<boolean>(`api/shop-rating/save`, data)
            .pipe(
                //finalize(() => ),
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe((res) => {
                this.saving = false
                this.saved = res;
            });
    }
}
import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {ArticleListComponent} from './article-list.component';
import {ArticleBoxModule} from "../article-box/article-box.module";
import {ArticleListPagedComponent} from "./article-list-paged.component";
import {FormsModule} from "@angular/forms";
import {ImageCacheBustModule} from "../../directives/image-cache-bust.module";
import {PaginationModule} from "ngx-bootstrap/pagination";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ArticleBoxModule,
        PaginationModule,
        FormsModule,
        ImageCacheBustModule
    ],
    declarations: [
        ArticleListComponent,
        ArticleListPagedComponent
    ],
    exports: [
        ArticleListComponent,
        ArticleListPagedComponent
    ],
    providers: [],
})

export class ArticleListModule {
}

import {Pipe, PipeTransform} from '@angular/core';
import {SafeHtml} from "@angular/platform-browser";

@Pipe({
    name: 'crophtml'
})
export class CropHtml implements PipeTransform {
    transform(val: string | SafeHtml) {
        let regex = /(<([^>]+)>)/ig;
        let res = val.toString();
        return res.replace(regex, "");
    }
}
import { Component, ViewEncapsulation, Input, EventEmitter, Output, SimpleChanges } from '@angular/core';
import { Translatable, CategoryItemSelector, FilterCategoryItemSelector } from '../../interfaces/general';
import { DataService } from '../../services/data.service';
import { SettingsService } from '../../services/settings.service';
import { CategoryService } from './services/category.service';

@Component({
    templateUrl: '../../tpl/action-category-filter.html',
    selector: 'cmp-action-category-filter',
    styleUrls: ['../../assets/styles/4-routes/category.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ActionCategoryFilterComponent extends Translatable {

    @Input() category: CategoryItemSelector;
    @Input() withProducts: boolean = false;
    @Output() categorySelected: EventEmitter<void> = new EventEmitter<void>();
    categories: FilterCategoryItemSelector[];
    catImagePathPrefix: string;
    subCatImagePathPrefix: string;
    bannerDirectory: string;

    public selectedCategoryId: number = 0;

    constructor(
        public dataSvc: DataService,
        public seSvc: SettingsService,
        private catSvc: CategoryService
    ) {
        super(dataSvc, seSvc);
        this.catImagePathPrefix = this.seSvc.settings.imageServerPathPrefix + '/fotocache/cattop/images/';
        this.subCatImagePathPrefix = this.seSvc.settings.imageServerPathPrefix + '/fotocache/catitem/images/';
        this.bannerDirectory = this.seSvc.settings.imageServerPathPrefix + '/fotocache/categoryBanner/images/';
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['category'] && this.category) {
            this.catSvc.getCategoriesForFilter(this.category.id)
                .subscribe((res) => {
                    this.categories = res;
                });
        }
    }

    selectCategory(id: number): void {
        this.selectedCategoryId = id;
        this.categorySelected.emit();
    }

    getClass(id: number):string {
        return id != this.selectedCategoryId ? 'subaction-category-button' : 'subaction-category-selected-button';
    }

    getMainClass(): string {
        if (this.withProducts) return "category-with-products";
        return "";
    }
}
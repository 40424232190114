import {Component, OnInit} from '@angular/core';
import {Translatable} from "../../../interfaces/general";
import {SettingsService} from "../../../services/settings.service";
import {DataService} from "../../../services/data.service";
import {GeneralDialogConfig} from "../../general-dialog/general-dialog-config";
import {CartProductListDialogMode, ICartProductListDialogConfig} from "../interfaces/ICartProductListDialogConfig";
import {ICartProductListDialogItem} from "../interfaces/ICartProductListDialogItem";
import {GeneralDialogRef} from "../../general-dialog/common";

@Component({
    selector: 'app-cart-product-list-dialog',
    templateUrl: './cart-product-list-dialog.component.html'
})
export class CartProductListDialogComponent extends Translatable implements OnInit {

    public CartProductListDialogMode = CartProductListDialogMode;

    public imageServerPathPrefix: string = null;
    public products: ICartProductListDialogItem[] = null;
    public senKey: string = 'order-top-order-dialog-title';

    constructor(
        seSvc: SettingsService,
        dataSvc: DataService,
        public config: GeneralDialogConfig<ICartProductListDialogConfig>,
        private dialogRef: GeneralDialogRef
    ) {
        super(dataSvc, seSvc);
        this.imageServerPathPrefix = this.seSvc.settings.imageServerPathPrefix;
        if(this.config.data.CodeOfDialog ==='last'){
            this.senKey = 'order-last-order-dialog-title';
        }
        this.products = this.config.data.Products;
    }

    ngOnInit() {
    }

    public CloseDialog(): void {
        debugger;
        this.dialogRef.close(false);
    }

    public ConfirmDialog(): void {
        this.dialogRef.close(true);
    }

    public IsProductActive(product: ICartProductListDialogItem): boolean {
        return !!product.Price;
    }
}

import {EventEmitter, Injectable} from "@angular/core";
import {IWishList, PagedResponse} from "../interfaces/general";
import {DigestService} from "./digest.service";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {ISaveWishListProductRequest} from "../modules/wish-list/interfaces/ISaveWishListProductRequest";
import {tap} from "rxjs/operators";
import {ISavedProduct} from "../modules/account/interfaces/ISavedProduct";

declare let StringView: any;

@Injectable()
export class WishListService {

    private _wishLists: IWishList[];
    onWishListsChanged: EventEmitter<IWishList[]>;

    get wishLists(): IWishList[] {
        return this._wishLists;
    }

    private _totalCount: number;
    onTotalCountChanged: EventEmitter<number>;
    get totalCount(): number {
        return this._totalCount;
    }

    constructor(private digestSvc: DigestService, private http: HttpClient) {
        this.onWishListsChanged = new EventEmitter<IWishList[]>();
        this.onTotalCountChanged = new EventEmitter<number>();

        this._wishLists = null;
        this._totalCount = null;
        if (this.digestSvc.userName) {
            this.getWishListsPaged();
        }
    }

    public getWishLists(): Promise<void> {
        const p: Promise<void> = new Promise<void>((resolve, reject) => {
            let url = 'api/wish-lists/wish-lists-for-user';
            this.http.get<IWishList[]>(url)
                .subscribe(res => {
                    this._wishLists = res;
                    this.onWishListsChanged.emit(this._wishLists);
                    resolve();
                }, () => {
                    reject();
                });
        });

        return p;
    }

    public getWishListsPaged(pageIndex: number = 1, pageSize: number = 36, more: boolean = false): Promise<void> {
        const p: Promise<void> = new Promise<void>((resolve, reject) => {
            let url = 'api/wish-lists/wish-lists-for-user';
            this.http.get<PagedResponse<IWishList>>(`${url}/${pageIndex}/${pageSize}`)
                .subscribe(res => {
                    //console.log('getWishListsPaged:' , res);
                    if(!!more) {
                        res.data.forEach(p => this._wishLists.push(p));
                        this._totalCount = res.total;
                    }
                    else {
                        this._wishLists = res.data;
                        this._totalCount = res.total;
                    }
                    //console.log('getWishListsPaged:' , this._wishLists);
                    this.onWishListsChanged.emit(this._wishLists);

                    //console.log('getWishListsPagedTotal:' , this._totalCount);
                    this.onTotalCountChanged.emit(this._totalCount);
                    resolve();
                }, () => {
                    reject();
                });
        });

        return p;
    }


    createNewList(name: string,pageIndex: number = 1, pageSize: number = 36): Promise<void> {
        const p: Promise<void> = new Promise<void>((resolve, reject) => {
            let url = 'api/wish-lists/add-new';
            let newListNameBase64 = encodeURIComponent(new StringView(name).toBase64());

            this.http.get<PagedResponse<IWishList>>(`${url}/${pageIndex}/${pageSize}?qs=${newListNameBase64}`)
                .subscribe(res => {
                    this._wishLists = res.data;
                    this._totalCount = res.total;
                    this.onWishListsChanged.emit(this._wishLists);
                    this.onTotalCountChanged.emit(this._totalCount);
                    resolve();
                }, () => {
                    reject();
                });
        });

        return p;
    }

    deleteWishList(hash: string,pageIndex: number = 1, pageSize: number = 36): Promise<void> {
        const p: Promise<void> = new Promise<void>((resolve, reject) => {
            let url = `api/wish-lists/delete/${hash}`;
            this.http.get<boolean>(url)
                .subscribe(res => {
                    if (res) {
                        this.getWishListsPaged(pageIndex, pageSize).then(() => {
                            resolve();
                        }).catch(() => {
                            reject();
                        });
                    }
                }, () => {
                    reject();
                });
        });

        return p;
    }

    addItemToWishList(wishListHash: string, variantId: number, pageIndex: number = 1, pageSize: number = 36): EventEmitter<ISavedProduct[]> {

        let emitter = new EventEmitter<ISavedProduct[]>();
        let url = `api/wish-lists/add-item/${wishListHash}/${variantId}/${pageIndex}/${pageSize}`;


        this.http.get<IWishList>(url)
            .subscribe(res => {
                let filtered = this._wishLists.filter(wl => wl.hash == wishListHash);
                if (filtered) {
                    let filteredList = filtered[0];
                    if (filteredList && res.variants) {
                        filteredList.variantIds = res.variants.products.map(p => p.id);
                    }
                }
                emitter.emit(res.variants.products);
                emitter.complete();
            }, (err) => {
                emitter.error(err);
            });

        return emitter;
    }

    removeFromWishList(wishListHash: string, variantId: number, pageIndex: number = 1, pageSize: number = 36): EventEmitter<ISavedProduct[]> {

        let emitter = new EventEmitter<ISavedProduct[]>();
        let url = `api/wish-lists/remove-item/${wishListHash}/${variantId}/${pageIndex}/${pageSize}`;

        this.http.get<IWishList>(url)
            .subscribe(res => {
                let filtered = this._wishLists.filter(wl => wl.hash == wishListHash);
                if (filtered) {
                    let filteredList = filtered[0];
                    if (filteredList && res.variants) {
                        filteredList.variantIds = res.variants.products.map(p => p.id);
                    }
                }
                emitter.emit(res.variants.products);
                emitter.complete();
            }, (err) => {
                emitter.error(err);
            });
        return emitter;
    }


/*    getWishList(wishListHash: string): EventEmitter<IWishList> {

        let emitter = new EventEmitter<IWishList>();
        let url = `api/wish-lists/${wishListHash}`;

        this.http.get<IWishList>(url)
            .subscribe(res => {
                emitter.emit(res);
                emitter.complete();
            }, (err) => {
                emitter.error(err);
            });
        return emitter;
    }*/
    getWishList(wishListHash: string, pageIndex: number = 1, pageSize: number = 36, availOnly:boolean): EventEmitter<IWishList> {

        let emitter = new EventEmitter<IWishList>();
        let url = `api/wish-lists/${wishListHash}/${pageIndex}/${pageSize}/${availOnly}`;

        this.http.get<IWishList>(url)
            .subscribe(res => {
                emitter.emit(res);
                emitter.complete();
            }, (err) => {
                emitter.error(err);
            });
        return emitter;
    }

    isOnList(wishListHash: string, productId: number): boolean {
        if (!this._wishLists) {
            return false;
        }

        let filteredLists = this._wishLists.filter(wl => wl.hash == wishListHash);
        if (!filteredLists) {
            return false;
        }

        let list = filteredLists[0];
        if (!list || !list.variantIds) {
            return false;
        }

        let filtered = list.variantIds.filter(id => id == productId);
        if (!filtered) {
            return false;
        }

        return filtered[0] && filtered[0] == productId;
    }

    public SaveProductWishLists(wl: ISaveWishListProductRequest): Observable<IWishList[]> {
        return this.http.post<IWishList[]>(`api/wish-lists/save-by-product`, wl)
            .pipe(tap((res) => {
                if (res) {
                    this._wishLists = res;
                    this.onWishListsChanged.emit(this._wishLists);
                }
            }));
    }
}
import {Injectable} from '@angular/core';
import {loadFromLocalStorage, saveToLocalStorage} from "../../../helpers/cookie.helper";

@Injectable({
    providedIn: 'root'
})
export class PriceModeSwitcherService {

    private static stateToken: string = 'price-mode-switcher-state-token';

    private isWithVat: boolean = false;

    constructor() {
        this.RestoreState();
    }

    private SaveState(): void {
        saveToLocalStorage(PriceModeSwitcherService.stateToken, this.isWithVat);
    }

    private RestoreState(): void {
        try {
            this.isWithVat = loadFromLocalStorage(PriceModeSwitcherService.stateToken);
            if (this.isWithVat == null) {
                this.isWithVat = false;
            }
        } catch {
            this.isWithVat = false;
        }
    }

    public get IsWithVat(): boolean {
        return this.isWithVat;
    }

    public set IsWithVat(value: boolean) {
        this.isWithVat = value;
        this.SaveState();
    }
}

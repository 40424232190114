import {Component, Input} from "@angular/core";
import {Translatable} from "../../interfaces/general";
import {Subject} from "rxjs";
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";
import {HttpClient} from "@angular/common/http";
import {takeUntil} from "rxjs/operators";
import {aboutUsSelector} from "./common";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
    selector: 'cmp-settings-aboutus',
    templateUrl: '../../tpl/settings-aboutus.html'
})

export class SettingsAboutusComponent extends Translatable {

    @Input() cssClass: string;

    data: aboutUsSelector;
    ngUnsubscribe: Subject<any> = new Subject<any>();

    constructor(dataSvc: DataService, public seSvc: SettingsService,
                private http: HttpClient, private sanitizer: DomSanitizer) {
        super(dataSvc, seSvc);
    }

    ngOnInit(): void {
        this.getData();
    }

    private getData(): void {
        this.http.get<aboutUsSelector>(`api/settings/about-us`)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((res) => {
                this.data = this.handleData(res);
            });
    }

    private handleData(inp: aboutUsSelector): aboutUsSelector {
        if (inp && inp.aboutUs) inp.aboutUs = this.sanitizer.bypassSecurityTrustHtml(<string>(inp.aboutUs));
        return inp;
    }
}
import {EventEmitter, Injectable} from "@angular/core";
import {loadFromLocalStorage, loadFromSession, removeFromLocalStorage, removeFromSession, saveToLocalStorage, saveToSession} from "../../helpers/cookie.helper";
import {DataService} from "../../services/data.service";
import {delay, finalize, takeUntil} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {Observable, Subject} from "rxjs";
import {
    AddDeliveryPaymentRequest,
    BranchServices,
    CartItemSelectorBrief,
    CartItemSelectorFull,
    CartSelector,
    DeliveryPayment,
    DeliverySelector,
    GiftPriceSelector,
    GiftSelector,
    ICartChangeRequest,
    ICartCountsChange, QuickOrderFilteredAddedProducts, QuickOrderProductEanSelector, QuickOrderResultSelector,
    StoredCartAdjustCartResult,
    UnitType
} from "./common";
import {CartTokenService} from "./cart-token.service";
import {AddressSelector, CompanySelector, UserSelector} from "../address/common";
import {GeneralDialogService} from "../general-dialog/general-dialog.service";
import {CartDialogConfig, CartFromStoredDialogConfig} from "../cart-modal/common";
import {GeneralDialogConfig} from "../general-dialog/general-dialog-config";
import {SettingsService} from "../../services/settings.service";
import {CartChangeModalComponent} from "../cart-modal/cart-change-modal.component";
import {CartAdjustAction} from "../../interfaces/general";
import {CartMultiChangeModalComponent} from "../cart-modal/cart-multi-change-modal.component";
import {IQuickOrderItem} from "../quick-order/common";
import {ICartProductListDialogItem} from "../cart-product-list-dialog/interfaces/ICartProductListDialogItem";
import {jsonCopy} from "../../helpers/object.helper";
import {ProductAvailabilityService} from "../product-availability/services/product-availability.service";
import {CredentialStorage} from "../../services/credential-storage.service";
import {ProductAmountComponent} from "../product-amount/product-amount/product-amount.component";

let cartNoteMaxLen: number = 78;

@Injectable()
export class CartService {

    getContentDelayMilliseconds: number = 500;

    orderHash: string;

    cartContentChanged: EventEmitter<CartSelector>;
    productsImported: EventEmitter<QuickOrderFilteredAddedProducts>;
    cartCountsChanged: EventEmitter<CartItemSelectorFull[]> = new EventEmitter<CartItemSelectorFull[]>();
    cartPricesChanged: EventEmitter<ICartCountsChange> = new EventEmitter<ICartCountsChange>();
    cartItemCountChanged: EventEmitter<CartItemSelectorBrief>;
    cartInvoiceAddressChanged: EventEmitter<AddressSelector>;
    cartDeliveryAddressChanged: EventEmitter<AddressSelector>;
    cartWithDeliveryAddressChanged: EventEmitter<boolean>;
    cartContentFromStoredChanged: EventEmitter<StoredCartAdjustCartResult>;
    cartEmptied: EventEmitter<any>;

    public isNextStep4: boolean = false;

    private ngUnsubscribe_cartContent: Subject<any> = new Subject();
    private ngUnsubscribe_removeFromCart: Subject<any> = new Subject();
    private ngUnsubscribe_addToCart: Subject<any> = new Subject();
    private ngUnsubscribe_changeCount: Subject<any> = new Subject();
    private ngUnsubscribe_replaceItem: Subject<any> = new Subject();
    private ngUnsubscribe_addCouponToCart: Subject<any> = new Subject();
    private ngUnsubscribe_setDeliveryAndPayment: Subject<any> = new Subject();
    private ngUnsubscribe_emptyCart: Subject<any> = new Subject();

    private invoiceAddressKey: string = 'cart.invoiceAddressKey';
    private deliveryAddressKey: string = 'cart.deliveryAddressKey';
    private companyKey: string = 'cart.companyKey';
    private withCompanyKey: string = 'cart.withCompanyKey';
    private userKey: string = 'cart.userCompanyKey';
    private withDeliveryAddressKey: string = 'cart.withDeliveryAddressKey';
    private parcelShopBranchKey: string = 'cart.parcelShopBranchKey';
    private noteKey: string = 'cart.noteKey';
    private extNumOrderKey: string = 'cart.extNumOrderKey';

    get token(): string {
        return this.cartTokenSvc.getCartToken();
    }

    set token(val: string) {
        this.cartTokenSvc.saveCartToken(val);
    }

    public get IsEmpty(): boolean {
        return !(this.cart.cartContent && !!this.cart.cartContent.length);
    }

    private _cart: CartSelector;
    set cart(value: CartSelector) {

        if (value) {
            let company = loadFromSession(this.companyKey);
            if (company) {
                value.company = company;
            }

            let withCompany = loadFromSession(this.withCompanyKey);
            value.withCompany = withCompany;

            let withDeliveryAddress = loadFromSession(this.withDeliveryAddressKey);
            value.withDeliveryAddress = withDeliveryAddress;
            this.cartWithDeliveryAddressChanged.emit(withDeliveryAddress);

            let user = loadFromSession(this.userKey);
            if (user) {
                value.user = user;
            }

            let invoiceAddress = loadFromSession(this.invoiceAddressKey);
            if (invoiceAddress) {
                value.invoiceAddress = invoiceAddress;
                this.cartInvoiceAddressChanged.emit(value.invoiceAddress);
            }

            let deliveryAddress = loadFromSession(this.deliveryAddressKey);
            if (deliveryAddress) {
                value.deliveryAddress = deliveryAddress;
                this.cartDeliveryAddressChanged.emit(value.deliveryAddress);
            }

            let cartNote = this.loadCartNote();
            if (cartNote) {
                value.note = cartNote;
            }

            let cartExtNumOrder = this.loadCartExtNumOrder();
            if (cartExtNumOrder) {
                value.extNumOrder = cartExtNumOrder;
            }

            let cartNoteLength = this.loadCartNote();
            if (cartNoteLength) {
                value.noteLength = cartNoteMaxLen - cartNoteLength.length;
            }
            else {
                value.noteLength = cartNoteMaxLen;
            }

            let cartNoteMaxLength = cartNoteMaxLen;
            if (cartNoteMaxLength) {
                value.noteMaxLength = cartNoteMaxLength;
            }
        }

        this._cart = value;
    }

    get cart(): CartSelector {
        return this._cart;
    }

    constructor(private dataSvc: DataService, private seSvc: SettingsService, private http: HttpClient, public cartTokenSvc: CartTokenService,
                private dialogSvc: GeneralDialogService, private availabilitySvc: ProductAvailabilityService) {
        this.cartContentChanged = new EventEmitter();
        this.productsImported = new EventEmitter();
        this.cartItemCountChanged = new EventEmitter<CartItemSelectorBrief>();
        this.cartInvoiceAddressChanged = new EventEmitter<AddressSelector>();
        this.cartDeliveryAddressChanged = new EventEmitter<AddressSelector>();
        this.cartWithDeliveryAddressChanged = new EventEmitter<boolean>();
        this.cartEmptied = new EventEmitter();
        this.cartContentFromStoredChanged = new EventEmitter<StoredCartAdjustCartResult>();

        this.cart = CartService.getOrderSessionData(true);

        this.initiateModalDialogHook();
    }



    private initiateModalDialogHook() {

        this.cartItemCountChanged
            .subscribe((res: CartItemSelectorBrief) => {

                if (res && !res.fromCart && !res.disableMessage) {

                    let cartItem = this.productList.find(p => p.id == res.id);
                    this.availabilitySvc
                        .GetProductAvailabilities([cartItem.productDetail.id])
                        .subscribe(res => {
                            cartItem.productDetail.productAvailability = res.Availabilities.find(x => x.ProductId === cartItem.productDetail.id);
                            this.showCartAddDialog(cartItem);
                        });

                }
            });
        this.cartContentFromStoredChanged
            .subscribe((res: StoredCartAdjustCartResult) => {
                if (res) {
                    this.showCartAddFromStoredDialog(res);
                }
            });
    }

    private showCartAddDialog(cartItem: CartItemSelectorFull): void {
        if (!CredentialStorage.hideCartAddInfo) {

        const data: CartDialogConfig = {cartItem: cartItem};
        const config: GeneralDialogConfig<CartDialogConfig> = {
            data: data,
            cssClassModifier: 'cart-added',
            isCloseAble: true,
            title: this.getDialogTitle(cartItem)
        };
        const dialogRef = this.dialogSvc.open(CartChangeModalComponent, config);
        dialogRef.afterClosed
            .subscribe((cartItem: CartItemSelectorFull) => {
                if (cartItem)
                    this.removeFromCart(cartItem)
            });
        }
    }

    private getDialogTitle(cartItem: CartItemSelectorFull): string {

        let availability = cartItem.productDetail.productAvailability;

        if (!availability ||
            (availability.Quantity <= 0 && (availability.VisibilityId === 4 ||
                                            availability.VisibilityId === 1)) ||
            availability.VisibilityId === 2) return this.seSvc.sen['cart-modal-title-insert-unavailable'];

        if (availability.VisibilityId === 3) return this.seSvc.sen['cart-modal-title-insert-ten-days'];

        return this.seSvc.sen['cart-modal-title-insert'];
    }

    private showCartAddFromStoredDialog(cartResult: StoredCartAdjustCartResult): void {

            const data: CartFromStoredDialogConfig = {result: cartResult};
            const config: GeneralDialogConfig<CartFromStoredDialogConfig> = {
                data: data,
                cssClassModifier: 'cart-added',
                isCloseAble: true,
                title: this.seSvc.sen['buy-window-inserted-items']
            };
            const dialogRef = this.dialogSvc.open(CartMultiChangeModalComponent, config);
            dialogRef.afterClosed
                .subscribe(() => {
                });
    }

    public UnitPackageProducts : number[];


    private checkAndInitUnitPackageProducts(){
        if(typeof this.UnitPackageProducts === 'undefined'){
            this.UnitPackageProducts = loadFromSession('UnitPackageProducts');
            if(typeof this.UnitPackageProducts === 'undefined'){
                this.UnitPackageProducts = [];
            }
        }
        if(this.UnitPackageProducts == null){
            this.UnitPackageProducts = [];
        }
    }

    public addByPackage(productId: number) : boolean{
        this.checkAndInitUnitPackageProducts();

        return this.UnitPackageProducts.includes(productId)
    }

    public togglePackageUnit(id: number, minQtyOrder: number, productAmount: ProductAmountComponent){
        this.setAddSize(id);
        productAmount.Amount = this.addByPackage(id) ? 1 : minQtyOrder || 1
    }

    public setAddSize(productId: number){

        this.checkAndInitUnitPackageProducts();

        //pokud obsahuje vyhodim
        if(this.UnitPackageProducts.includes(productId)) {
            this.UnitPackageProducts.splice(this.UnitPackageProducts.indexOf(productId), 1);
        }
        else{
            this.UnitPackageProducts.push(productId) //pokud neobsahuje pridam
        }

        saveToSession("UnitPackageProducts", (this.UnitPackageProducts));
    }

    get productList(): CartItemSelectorFull[] {
        if (this.cart && this.cart.cartContent) {
            return this.cart.cartContent.filter(cc => cc.productDetail);
        }
        return null;
    }

    get giftList(): CartItemSelectorFull[] {
        if (this.cart && this.cart.cartContent) {
            return this.cart.cartContent.filter(cc => cc.giftProductDetail);
        }
        return null;
    }

    get coupons(): CartItemSelectorFull[] {
        if (this.cart && this.cart.cartContent) {
            return this.cart.cartContent.filter(cc => cc.couponDetail);
        }
        return null;
    }

    get couponsCode() : string {
        let codes = "";

        this.coupons.forEach(x => {
            codes += x.couponDetail.code + ",";
        });

        if (codes.length > 0)
            codes = codes.substring(0, codes.length - 1); // odjebkat carku

        return codes;
    }

    private _generalPendingOperation: number = 0;
    set generalPendingOperation(value: boolean) {
        if (value) {
            this._generalPendingOperation++;
        } else {
            this._generalPendingOperation--;
        }
    }

    get generalPendingOperation(): boolean {
        return this._generalPendingOperation > 0;
    }

    getCartStatus(): any {
        let url = `api/cart/getCartStatus`;
        return this.http.get(url);
    }

    addToCart(cart: CartItemSelectorBrief): void {

        if (!cart.unitType) {
            cart.unitType = UnitType.Original;
        }

        this.dataSvc.dataLoading = true;
        let url = `api/cart/addToCart`;

        cart.cartToken = this.token;
        cart.fromCart = false;

        this.http.post(url, cart)
            .pipe(takeUntil(this.ngUnsubscribe_addToCart))
            .pipe(finalize(() => {
                this.dataSvc.dataLoading = false;
            }))
            .subscribe(
                (res: CartSelector) => {
                    this.token = res.cartToken;
                    this.cart = res;
                    this.cartContentChanged.emit(res);

                    let full = this.productList.find(p => p.productId == cart.productId && p.unitType === cart.unitType);
                    full.disableMessage = cart.disableMessage;
                    this.storeOrderSessionData();
                    this.cartItemCountChanged.emit(full);

                    // this.gtSvc.addGoogleTagBasketInfoStep(1, this.couponsCode,
                    //     /*(this.coupons && this.coupons[0] && this.coupons[0].couponDetail) ?
                    //                     this.coupons[0].couponDetail.name : '', */ "begin_checkout" )
                }, () => {

                });
    }

    addListToCart(productList: ICartChangeRequest[]): void {
        productList.forEach(x => {
            x.unitType = x.unitType || UnitType.Original
        });

        this.dataSvc.dataLoading = true;
        let url = `api/cart/add-product-list`;

        this.http.post(url, productList)
            .pipe(takeUntil(this.ngUnsubscribe_addToCart))
            .pipe(finalize(() => {
                this.dataSvc.dataLoading = false;
            }))
            .subscribe(
                (res: CartSelector) => {
                    this.token = res.cartToken;
                    this.cart = res;
                    this.cartContentChanged.emit(res);
                });
    }

    addQuickOrder(data: IQuickOrderItem[]): void {
        this.dataSvc.dataLoading = true;
        let url = 'api/cart/add-quick-order';

        this.http.post<QuickOrderResultSelector>(url, data)
            .pipe(takeUntil(this.ngUnsubscribe_addToCart))
            .pipe(finalize(() => {
                this.dataSvc.dataLoading = false;
            }))
            .subscribe((res: QuickOrderResultSelector) => {
                this.token = res.cart.cartToken;
                
                let filteredAddedProducts = this.getFilteredAddedProducts(data, res.cart,res.productEans);
                this.cart = res.cart;
                this.cartContentChanged.emit(res.cart);
                this.productsImported.emit(filteredAddedProducts);
            });
    }

    getFilteredAddedProducts(quickOrderItems: IQuickOrderItem[], newCart: CartSelector, productEans: QuickOrderProductEanSelector[] ): QuickOrderFilteredAddedProducts {
        // let oldCartContent = this.cart.cartContent.map(x => x.id);
        let codesAndEans = quickOrderItems.map(x => x.key);
        let productsFromEans = productEans.filter(x=> codesAndEans.includes(x.ean)).map(x => x.id);
        let newlyAddedProducts = newCart.cartContent.filter(x => codesAndEans.includes(x.productDetail.code)
            || codesAndEans.includes(x.productDetail.ean)
            || productsFromEans.includes(x.productDetail.id));
        // set correct count

        let notAddedIndices: number[] = [];
        quickOrderItems.forEach((x, idx) => {
            if (!newlyAddedProducts.some(y => y.productDetail.code == x.key || y.productDetail.ean == x.key || productEans.map(e => e.ean).includes(x.key))){
                notAddedIndices.push(idx);
            }
            else{
                console.log(x,idx);
            }
        });

        return {
            dialogItems: this.cartItemSelectorToDialogItems(newlyAddedProducts),
            notAddedIndices: notAddedIndices,
            quickOrderItems: quickOrderItems
        };
    }
    
    cartItemSelectorToDialogItems(cartItems: CartItemSelectorFull[]): ICartProductListDialogItem[] {
        let dialogItems: ICartProductListDialogItem[] = [];
        cartItems.forEach((cartItem) => {
            let dialogItem: ICartProductListDialogItem = {
                ProductId: cartItem.productDetail.id,
                SeoUrl: cartItem.productDetail.seoUrl,
                ImageUrl: cartItem.productDetail.imagePath,
                Name: cartItem.productDetail.name,
                Count: cartItem.count,
                Price: cartItem.priceWithOutVAT * cartItem.count,
                Unit: cartItem.productDetail.unit.shortCut,
            }

            dialogItems.push(dialogItem);
        })

        return dialogItems;
    }

    addGiftToCart(cart: CartItemSelectorBrief): void {

        this.dataSvc.dataLoading = true;
        let url = `api/cart/addGiftToCart`;

        cart.cartToken = this.token;
        cart.fromCart = false;

        this.http.post(url, {
            VariantId: (<any>cart).giftProductDetail.product.id,
            GiftId: (<any>cart).giftProductDetail.giftId,
            Count: (<any>cart).count
        })
            .pipe(takeUntil(this.ngUnsubscribe_addToCart))
            .pipe(finalize(() => {
                this.dataSvc.dataLoading = false;
            }))
            .subscribe(
                (res: CartSelector) => {
                    this.token = res.cartToken;
                    this.cart = res;
                    this.cartContentChanged.emit(res);

                }, () => {

                });
    }

    InsertSavedCart(storedCartId: number, cAdjTp: CartAdjustAction): void {
        this.dataSvc.dataLoading = true;
        let url = `api/saved-cart/insert-to-cart`;

        this.http.post<StoredCartAdjustCartResult>(url, {
            ActionCode: cAdjTp,
            SavedCartId: storedCartId
        })
            .pipe(takeUntil(this.ngUnsubscribe_addToCart))
            .pipe(finalize(() => {
                this.dataSvc.dataLoading = false;
            }))
            .subscribe((res) => {
                if (res && !res.Error && (res.Inserted.length > 0 || res.Failed.length > 0)) {
                    this.cartContentFromStoredChanged.emit(res);
                    this.token = res.Cart.cartToken;
                    this.cart = res.Cart;
                    this.storeOrderSessionData();
                    this.cartContentChanged.emit(res.Cart);
                } else {
                    this.cartContentFromStoredChanged.emit({Error: true});
                }
            }, () => {
            });
    }

    getCartContentForToken(loading: boolean = true): void {
        if (loading)
            this.dataSvc.dataLoading = true;

        let url = 'api/cart/getCartContentForToken';
        this.http.get<CartSelector>(url)
            .pipe(takeUntil(this.ngUnsubscribe_cartContent))
            .pipe(delay(this.getContentDelayMilliseconds))
            .pipe(finalize(() => {
                if (loading)
                    this.dataSvc.dataLoading = false;
            }))
            .subscribe((res: CartSelector) => {
                this.cart = res;
                this.storeOrderSessionData();
                this.cartContentChanged.emit(res);
            });
    }

    broadcastCartEmptied(): void {
        this.cartEmptied.emit()
    }

    removeFromCart(cart: CartItemSelectorBrief): void {
        this.dataSvc.dataLoading = true;

        let url = `api/cart/removeFromCart`;

        this.http.post(url, cart)
            .pipe(
                finalize(() => this.dataSvc.dataLoading = false),
                takeUntil(this.ngUnsubscribe_removeFromCart)
            )
            .subscribe(
                () => {
                    this.getCartContentForToken();
                }
            );
    }

    removeListFromCart(cart: CartItemSelectorBrief[]): void {
        this.dataSvc.dataLoading = true;

        let url = `api/cart/removeListFromCart`;

        this.http.post(url, cart)
            .pipe(
                finalize(() => this.dataSvc.dataLoading = false),
                takeUntil(this.ngUnsubscribe_removeFromCart)
            )
            .subscribe(
                () => {
                    this.getCartContentForToken();

                    // cart.forEach(c => {
                    //     this.gtSvc.addGoogleTagBasketRemoveProduct(c.id);
                    // });
                }
            );
    }

    changeCount(cart: CartItemSelectorBrief): void {

        this.dataSvc.dataLoading = true;
        cart.cartToken = this.token;

        let url = `api/cart/changeCount`;

        this.http.post(url, cart)
            .pipe(takeUntil(this.ngUnsubscribe_changeCount))
            .pipe(
                finalize(() => {
                    this.dataSvc.dataLoading = false;
                })
            )
            .subscribe((res: CartSelector) => {
                this.cart = res;
                let changedItem = this.cart.cartContent.find(item => item.productId == cart.productId);
                if (changedItem) {
                    changedItem.fromCart = cart.fromCart;
                    changedItem.disableMessage = cart.disableMessage;
                    this.cartItemCountChanged.emit(changedItem);
                }
                this.cartContentChanged.emit(res);
            }, () => {

            });

    }

    changeProductCount(productId: number, delta: number): void {
        delta = parseFloat(delta.toString());
        let cartItem = this.cart.cartContent.find(cc => cc.productDetail && cc.productDetail.id == productId);
        if (!cartItem) {
            return;
        }
        let cart: CartItemSelectorBrief = {
            count: cartItem.count + delta,
            id: cartItem.id,
            fromCart: true
        };
        this.changeCount(cart);
    }

    replaceItem(cart: CartItemSelectorBrief): void {
        this.dataSvc.dataLoading = true;
        cart.cartToken = this.token;

        let url = `api/cart/replaceItem`;

        this.http.post(url, cart)
            .pipe(takeUntil(this.ngUnsubscribe_replaceItem))
            .pipe(
                finalize(() => {
                    this.dataSvc.dataLoading = false;
                })
            )
            .subscribe((res: CartSelector) => {
                this.cart = res;
                this.cartContentChanged.emit(res);
            });
    }

    getCouponFromServer(code: string): any {
        let url = `api/coupon/${code}`;
        return this.http.get(url)
    }

    getGifts(priceLevel: number): Observable<GiftSelector[]> {
        let url = `api/gift/getEnabled`;
        let request: GiftPriceSelector = {
            priceLevel: priceLevel
        };
        return this.http.post<GiftSelector[]>(url, request)
    }

    addCouponToCart(couponCode: string): Observable<any> {
        let url = `api/cart/addCouponToCart`;
        const data = {
            couponCode: couponCode,
            Token: this.token,
            FromCart: false
        };

        let sub = new Subject<CartSelector>();

        this.http.post(url, data)
            .pipe(takeUntil(this.ngUnsubscribe_addCouponToCart))
            .subscribe((res: CartSelector) => {
                if (res) {
                    this.cart = res;
                    this.cartContentChanged.emit(res);
                }
                sub.next(res);
                sub.complete();
            }, (error => {
                sub.error(error);
            }));


        return sub;
    }

    addDeliveryAndPayment(deliveryAndPayment: DeliveryPayment): void {
        let url = "api/cart/add-delivery-and-payment";

        if (!deliveryAndPayment || !deliveryAndPayment.Delivery || !deliveryAndPayment.Payment) {
            return;
        }

        let request: AddDeliveryPaymentRequest = {
            deliveryId: deliveryAndPayment.Delivery.id,
            paymentId: deliveryAndPayment.Payment.id
        };

        this.dataSvc.dataLoading = true;

        this.http.post(url, request)
            .pipe(takeUntil(this.ngUnsubscribe_setDeliveryAndPayment),
                finalize(() => {
                    this.dataSvc.dataLoading = false;
                }))
            .subscribe((res: CartSelector) => {
                if (res) {
                    this.cart = res;
                }
            });
    }

    couponChanged(): void {
        this.cartContentChanged.emit();
    }


    public static getOrderSessionData(emptyIfNull?: boolean): CartSelector {
        let ss: CartSelector = <CartSelector>loadFromLocalStorage('order');
        if (!ss && emptyIfNull) {
            ss = {
                cartActionResult: null,
                cartContent: null,
                delivery: null,
                payment: null,
                priceTotalWithVat: null,
                priceWithoutPackagingWithOutVat: null,
                priceTotalWithOutVat: null,
                priceWithoutPaymentAndDeliveryWithVat: null,
                priceWithoutPaymentAndDeliveryWithOutVat: null,
                priceProductsWithVat: null,
                priceProductsWithOutVat: null,
                itemsCount: null,
                deliveryFreeFrom: null,
                errorMessage_SenKey: null,
                cartToken: null,
                fromCart: null,
                orderDone: false,
                invoiceAddress: null,
                deliveryAddress: null,
                company: null,
                user: null,
                vatInfo: [],

                //former Additional:

                logToNewsletter: false,
                maxStep: 1,
                withCompany: false,
                note: '',
                noteLength: 0,
                noteMaxLength: 78,
                extNumOrder: '',
                withDeliveryAddress: false
            };
        }
        return ss;
    }

    public storeOrderSessionData(): void {
        saveToLocalStorage('order', this.cart);
    }

    public emptyCart(): void {
        let url = 'api/cart/empty-cart';
        this.http.get(url)
            .pipe(takeUntil(this.ngUnsubscribe_emptyCart))
            .subscribe((res) => {
                if (res) {
                    removeFromLocalStorage('order');
                    //this.cart = null;
                    this.resetCart();
                    this.cartContentChanged.emit(null);
                    this.cartEmptied.emit();
                }
            });
    }

    public async emptyCartAsync(): Promise<boolean> {
        try {
            let url = 'api/cart/empty-cart';
            let res = await this.http.get(url)
                .pipe(takeUntil(this.ngUnsubscribe_emptyCart))
                .toPromise();

            if (res) {
                removeFromLocalStorage('order');
                //this.cart = null;
                this.resetCart();
                this.cartContentChanged.emit(null);
                this.cartEmptied.emit();
                return true;
            }
        } catch {
        }
        return false;
    }

    public emptyCartNextStep(): void {

        this.isNextStep4 = true;
        let url = 'api/cart/empty-cart';
        this.http.get(url)
            .pipe(takeUntil(this.ngUnsubscribe_emptyCart))
            .subscribe((res) => {
                if (res) {
                    this.clearOrderDataFromStorage();
                    //this.cart = null;
                    this.resetCart();
                    this.cartContentChanged.emit(this.cart);
                    this.cartEmptied.emit();
                }
            });
    }

    public resetCart(): void {
        this.cart = {
            cartActionResult: null,
            cartContent: null,
            delivery: null,
            payment: null,
            priceTotalWithVat: null,
            priceWithoutPackagingWithOutVat: null,
            priceTotalWithOutVat: null,
            priceWithoutPaymentAndDeliveryWithVat: null,
            priceWithoutPaymentAndDeliveryWithOutVat: null,
            priceProductsWithVat: null,
            priceProductsWithOutVat: null,
            itemsCount: null,
            deliveryFreeFrom: null,
            errorMessage_SenKey: null,
            cartToken: null,
            fromCart: null,
            orderDone: false,
            invoiceAddress: null,
            deliveryAddress: null,
            company: null,
            user: null,
            vatInfo: [],

            logToNewsletter: null,
            maxStep: null,
            withCompany: null,
            note: null,
            noteLength: null,
            noteMaxLength: null,
            extNumOrder: null,
            withDeliveryAddress: null
        }
    }

    public sendOrder(skipCounts: boolean = false, skipPrices: boolean = false): Observable<any> {

        this.onGetParcelShop();
        let sub = new Subject();

        if (skipCounts && skipPrices) {
            // přeskočit kontroly
            // - z dialogu o změnách cen
            this.sendOrderRequest(sub);
        }
        else if (skipCounts && !skipPrices) {
            // příchod z dialogu pro změnu množství
            this.checkCartPrices(sub);
        }
        else {
            // klik na Odeslat objednávku
            this.checkCartCounts(sub);
        }
        return sub;
    }

    public checkCartCountsRequest(): Observable<ICartCountsChange> {
        let url = 'api/cart/check-counts';
        return this.http.post<ICartCountsChange>(url, null);
    }

    public checkCartCounts(sub: Subject<any>): void {
        this.checkCartCountsRequest()
            .subscribe(res => {
                if (!res.success) {
                    // není vše na skladě
                    if (res.changes) {
                        this.cartCountsChanged.emit(res.changes);
                    }

                    sub.complete();
                }
                else {
                    // pokračovat na kontrolu cen
                    this.checkCartPrices(sub);
                }
            });
    }

    public updateCartCounts(): void {
        let url = 'api/cart/update-counts';
        this.http.post<ICartCountsChange>(url, null)
            .subscribe(res => {
                this.cart = res.cart;
                this.cartContentChanged.emit(res.cart);
                this.storeOrderSessionData();
            });
    }

    public checkPricesRequest(): Observable<ICartCountsChange> {
        let url = 'api/cart/check-prices';
        return this.http.post<ICartCountsChange>(url, null);
    }

    public checkCartPrices(sub: Subject<any>): void {
        this.checkPricesRequest()
            .subscribe(res => {
                if (!res.success) {
                    // ceny nesouhlasí
                    if (res.changes) {
                        res.oldCart = jsonCopy(this.cart);
                        this.cartPricesChanged.emit(res);
                    }

                    this.cart = res.cart;
                    this.cartContentChanged.emit(res.cart);

                    sub.complete();
                } else {
                    // odeslat objednávku
                    this.sendOrderRequest(sub);
                }
            });
    }

    private sendOrderRequest(sub: Subject<any>): void {
        let url = 'api/order/save';
        this.http.post(url, this.cart)
            .subscribe((res: any) => {
                sub.next(res);
                sub.complete();
            });
    }

    //giftChangedKey
    /*  onGiftChangeInBasket(gift: IGift){

      }*/
    onParcelShopChanged(branch: BranchServices): void {
        this.cart.branchInfo = branch;
        saveToSession(this.parcelShopBranchKey, this.cart.branchInfo);
    }

    onGetParcelShop(): void {
        this.cart.branchInfo = loadFromSession(this.parcelShopBranchKey);
    }

    onDeliveryChanged(delivery: DeliverySelector): void {
        this.cart.delivery = delivery;
    }

    onInvoiceAddressChanged(address: AddressSelector): void {
        this.cart.invoiceAddress = address;
        this.cartInvoiceAddressChanged.emit(this.cart.invoiceAddress);
        saveToSession(this.invoiceAddressKey, this.cart.invoiceAddress);
    }

    onDeliveryAddressChanged(address: AddressSelector): void {
        this.cart.deliveryAddress = address;
        this.cartDeliveryAddressChanged.emit(this.cart.deliveryAddress);
        saveToSession(this.deliveryAddressKey, this.cart.deliveryAddress);
    }

    onCompanyChanged(company: CompanySelector): void {
        this.cart.company = company;
        saveToSession(this.companyKey, this.cart.company);
    }

    onWithDeliveryAddressChanged(withDeliveryAddress: boolean): void {
        this.cart.withDeliveryAddress = withDeliveryAddress;
        this.cartWithDeliveryAddressChanged.emit(withDeliveryAddress);
        saveToSession(this.withDeliveryAddressKey, this.cart.withDeliveryAddress);
    }

    onWithCompanyChanged(withCompany: boolean): void {
        this.cart.withCompany = withCompany;
        saveToSession(this.withCompanyKey, this.cart.withCompany);
    }

    onUserChanged(user: UserSelector): void {
        this.cart.user = user;
        saveToSession(this.userKey, this.cart.user);
    }

    cleanCartOnLogout(): void {

        this.cartTokenSvc.saveCartToken(null);
        removeFromSession(this.invoiceAddressKey);
        removeFromSession(this.deliveryAddressKey);
        removeFromSession(this.companyKey);
        removeFromSession(this.withDeliveryAddressKey);
        removeFromSession(this.withCompanyKey);
        removeFromSession(this.userKey);
        removeFromSession(this.noteKey);
        removeFromSession(this.extNumOrderKey);
    }

    public async TopOrdersToCartAsync(): Promise<boolean> {
        try {
            let url = 'api/cart/add-top-orders';
            let res = await this.http.post<CartSelector>(url, null).toPromise();

            if (res) {
                this.cart = res;
                this.token = res.cartToken;
                this.cartContentChanged.emit(res);
                return true;
            }
        } catch {
        }
        return false;
    }

    public async LastOrderToCartAsync(): Promise<boolean> {
        try {
            let url = 'api/cart/add-last-order';
            let res = await this.http.post<CartSelector>(url, null).toPromise();

            if (res) {
                this.cart = res;
                this.token = res.cartToken;
                this.cartContentChanged.emit(res);
                return true;
            }
        } catch {
        }
        return false;
    }

    public GetLastOrderProducts(): Observable<ICartProductListDialogItem[]> {
        return this.http.get<ICartProductListDialogItem[]>('api/cart/get-last-order-products');
    }

    public GetTopOrderProducts(): Observable<ICartProductListDialogItem[]> {
        return this.http.get<ICartProductListDialogItem[]>('api/cart/get-top-order-products');
    }

    public storeCartNote(note: string): void {
        saveToSession(this.noteKey, note);
    }

    public loadCartNote(): string {
        return loadFromSession(this.noteKey);
    }

    public storeCartExtNumOrder(extNumOrder: string): void {
        saveToSession(this.extNumOrderKey, extNumOrder);
    }

    public loadCartExtNumOrder(): string {
        return loadFromSession(this.extNumOrderKey);
    }

    public clearOrderDataFromStorage(): void {
        removeFromLocalStorage('order');
        removeFromSession(this.invoiceAddressKey);
        removeFromSession(this.deliveryAddressKey);
        removeFromSession(this.companyKey);
        removeFromSession(this.withCompanyKey);
        removeFromSession(this.userKey);
        removeFromSession(this.withDeliveryAddressKey);
        removeFromSession(this.parcelShopBranchKey);
        removeFromSession(this.noteKey);
        removeFromSession(this.extNumOrderKey);
    }

    public getProductCount(productId: number): number {
        if (!productId || productId < 0) {
            return 0;
        }

        if (this.cart.cartContent) {
            let products = this.cart.cartContent.find(x => x.productId == productId); 
            let productCount = 0;
            if (products) {
                productCount = products.count;
                return productCount;
            }
            else {
                return productCount;
            }
        }

        return 0;
    }
}

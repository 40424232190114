import {Component, Input, ViewEncapsulation} from '@angular/core';
import {Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";
import {Subject} from "rxjs";
import {SocialSelector} from "../settings-social/common";

@Component({
    selector: 'cmp-footer-socials',
    templateUrl: '../../tpl/footer-socials.html',
    styleUrls: ['../../assets/styles/3-layout/footer/socials.scss'],
    encapsulation: ViewEncapsulation.None
})

export class FooterSocialsComponent extends Translatable {

    @Input() data: SocialSelector;
    //data: SocialSelector;

    ngUnsubscribe: Subject<any> = new Subject<any>();

    constructor(public dataSvc: DataService, seSvc: SettingsService) {
        super(dataSvc, seSvc);

    }

    ngOnInit(): void {
        //this.getData();
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        super.ngOnDestroy();
    }

    // private getData(): void {
    //     this.conSvc.getSocialData()
    //         .pipe(takeUntil(this.ngUnsubscribe))
    //         .subscribe((res) => {
    //             this.data = res;
    //         });
    // }
}
/**
 * @description
 * Provides functionality for logging user in and out
 * The template of this component is the place to place Login Control GUI
 * In case of a need to implement custome validators, look here:
 * https://angular.io/docs/ts/latest/cookbook/form-validation.html#!#custom-validation
 */
import {Component, ElementRef, HostListener, Input, ViewEncapsulation} from '@angular/core';
import {DigestService} from "../../services/digest.service";
import {SettingsService} from "../../services/settings.service";
import {DataService} from "../../services/data.service";
import {NavigationStart, Router} from "@angular/router";
import {isRouteSecured} from "../../helpers/string.helper";
import {filter, takeUntil} from "rxjs/operators";
import {CredentialStorage} from "../../services/credential-storage.service";
import {AuthInfo, LoginEmitterMessage, PageModeType, Translatable} from "../../interfaces/general";
import {GeneralDialogService} from "../general-dialog/general-dialog.service";
import {LoginDialogComponent} from "./login-dialog.component";
import {GeneralDialogConfig} from "../general-dialog/general-dialog-config";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {FormUtils} from "../../helpers/FormUtils";

declare let sha256: any;
declare let $: any;

@Component({
    selector: 'cmp-login',
    templateUrl: '../../tpl/login.html',
    styleUrls: ['../../assets/styles/3-layout/header/user.scss', '../../assets/styles/3-layout/header/login.scss'],
    encapsulation: ViewEncapsulation.None
})

export class LoginComponent extends Translatable {

    @Input() pageMode: PageModeType;

    displayUser: string;
    loggedIn: boolean = false;
    errorMessage: string;

    userForm: FormGroup;
    mailPattern: RegExp;

    constructor(
        dataSvc: DataService,
        seSvc: SettingsService,
        public digestSvc: DigestService,
        private router: Router,
        private generalDialogSvc: GeneralDialogService,
        private fb: FormBuilder,
        private elementRef: ElementRef
    ) {
        super(dataSvc, seSvc);

        this.digestSvc.loginStatus
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(res => {
                console.log(res);
            });

        this.digestSvc.onLoginQuery
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.showDialog();
            });

        let ai: AuthInfo = CredentialStorage.authInfo;
        if (ai) {
            this.loggedIn = ai.loggedIn;
            if (this.loggedIn) this.displayUser = ai.displayName;
        }

        this.digestSvc.loginStatus
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((res: LoginEmitterMessage) => {
                if (res.type === 'error') this.errorMessage = this.sen['login-error'];
                else this.errorMessage = null;
            });

        this.createForm();
    }

    /**
     * @description
     * Logs user off by deleting Authorization header info from session / local
     * You can optionally show a message
     */
    logOut(): void {
        this.digestSvc.logOut();
    }

    ngOnInit(): void {
        this.router.events
            .pipe(
                filter(f => f instanceof NavigationStart),
                takeUntil(this.unsubscribe)
            )
            .subscribe((res: NavigationStart) => {
                if (!isRouteSecured(res.url)) return;

                let ai: AuthInfo = CredentialStorage.authInfo;
                if (ai && !/^\/odhlaseni$/.test(res.url)) {
                    if (!CredentialStorage.isAiTimeValid()) {
                        this.digestSvc.logOut();
                    }
                }
            })
    }

    showDialog(): void {
        const config: GeneralDialogConfig = {
            data: null,
            cssClassModifier: 'login',
            isCloseAble: true,
            title: this.sen['app-user-login-title']
        };
        this.generalDialogSvc.open(LoginDialogComponent, config);
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }


    login() {
        FormUtils.MakeDirtyGroup(this.userForm);

        if (this.userForm.valid) {
            this.digestSvc.login(this.userForm.get('userName').value, sha256(this.userForm.get('password').value));
        }
    }

    private createForm(): void {
        this.userForm = this.fb.group({
            userName: ['', [Validators.required, Validators.minLength(4), Validators.pattern(this.seSvc.settings.validationPatterns.email)]],
            password: ['', [Validators.required, Validators.minLength(4)]]
        })
    }

    onKey(evt: KeyboardEvent): void {
        this.errorMessage = null;
        if (evt.key === 'Enter' || evt.key == 'NumpadEnter') {
            if (this.userForm.valid) {
                this.login();
            }
        }
    }

    //*************************************************************

    public Toggle(): void {
        if (this.IsVisible()) {
            this.Hide();
        } else {
            this.Show();
        }
    }

    public IsVisible(): boolean {
        return $(this.elementRef.nativeElement).hasClass('user-panel--active');
    }

    public Show(): void {
        $(this.elementRef.nativeElement).addClass('user-panel--active');
    }

    public Hide(): void {
        $(this.elementRef.nativeElement).removeClass('user-panel--active');
    }

    //*************************************************************

    private clickedToComponent: boolean = false;

    @HostListener('document:mousedown', ['$event'])
    public OnClickDown(ev: MouseEvent): void {
        const $target = $(ev.target);
        if ($target.closest('.user-panel').length !== 0) {
            this.clickedToComponent = true;
        } else {
            this.clickedToComponent = false;
        }
    }

    @HostListener('document:mouseup', ['$event'])
    public OnClickUp(ev: any): void {
        const $target = $(ev.target);
        if ($target.closest('.user-panel').length === 0 && this.clickedToComponent === false) {
            this.Hide();
        }
    }
    //*************************************************************
}

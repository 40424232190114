import {EventEmitter, OnDestroy, OnInit} from "@angular/core";
import {DataService} from "../services/data.service";
import {SettingsService} from "../services/settings.service";
import {IParameterImage, IPriceInfo, IProductPriceSelector, ProductSelector} from "../modules/product/common";
import {SafeHtml} from "@angular/platform-browser";
import {CredentialStorage} from "../services/credential-storage.service";
import {ProductResponseModel, SeoInfoSelector} from "../modules/category/interfaces";
import {Subject} from "rxjs";
import {ScrollUtils} from "../helpers/ScrollUtils";
import {IProductAvailability} from "../modules/product-availability/services/product-availability.service";
import {ISavedProduct} from "../modules/account/interfaces/ISavedProduct";

declare let sha256: any;
declare let $: any;

/**
 * @description
 * A class for storing authorization data in localStorage
 * Can be instantiated via:
 * - new ai()
 * - ai.fromString()
 * */
export interface AuthInfo {
    userName: string;
    displayName: string;
    loggedIn: boolean;
    validTo: string;
    jwTokenBody: string;
    readOnly: boolean;
    hideBanners: boolean;
    hideCartAddInfo: boolean;
}

/**
 * @description
 * Interfaces for passing proper request values between function calls
 * Just to have one param instead of 3 or more
 */
export interface SettingItem {
    Key: string;
    Value: any;
}

export interface UserInfo {
    userDisplayName: string;
}

export interface IContactForHeader{
    IsEmail: boolean;
    Contact: string;
}

export interface IContact{
    name: string;
    fname: string;
    lname: string;
    street: string;
    streetNumber: string;
    city: string;
    zipCode: string;
    country: string;
    ic: string;
    dic: string;
    phone: string;
    email: string;
}

/**
 * @description
 * To have some structure of the emitted object to count with
 * Possible values for "type" are:
 * 'info'
 * 'error'
 */
export interface LoginEmitterMessage {
    type: string;
    message: string;
    status?: number;
    data?: any;
    routeToIndex?: boolean;
}

export interface CategoryItemSelector {
    id: number;
    parentId: number;
    level: number;
    displayName: string;
    idPath: string;
    seoUrl: string;
    imagePath: string;
    imageAlt: string;
    description: string;
    bcInfo: BreadCrumbInfo[];
    articles: ArticleBriefSelector[];
    subcategories: CategoryItemSelector[];
    siblingCategories: CategoryItemSelector[];
    parentCategories: CategoryItemSelector[];
    catSeoInfo: SeoInfoSelector;
    zboziCategoryText: string;
    parent: CategoryParent;
    isTag: boolean;
    isAction: boolean;
    reservationID?: number;
}

export interface FilterCategoryItemSelector extends CategoryItemSelector {
    firstBannerUrl: string;
    firstBannerAlt: string;
    secondBannerUrl: string;
    secondBannerAlt: string;
}

export interface CategoryParent
{
    Id: number;
    SeoUrl: string;
    Name: string;
}

/**
 * @description
 * Defines culture properties
 */
export interface Culture {
    code: string;
    name: string;
    cultureId: number;
    currencyCode: string;
    currencySymbol: string;
    translationKey: string;
    htmlLangAttribute: string;
    cultureBoundDomain: string;
}

export interface Country {
    id: number;
    lang: any;
}

export interface ArticleBaseSelector {
    name: string;
    seoUrl: string;
    inNewWindow: boolean;
}

export interface ArticleBaseWithChildrenSelector extends ArticleBaseSelector {
    childArticles: Array<ArticleBaseWithChildrenSelector>;
}

export interface ArticleBriefSelector extends ArticleBaseWithChildrenSelector {
    articleId: number;
    order: number;
    code: string;
    dateCreated: any;
    imagePath: string;
    imageTitle: string;
    annotation: string | SafeHtml;
    childArticles: Array<ArticleBriefSelector>;
    topChildArticle: ArticleBriefSelector;
    codePath: string;
    reservationID?: number;
}

export interface ArticleSelector extends ArticleBriefSelector {
    title: string;
    body: string | SafeHtml;
    extraData: string;
    dateLastUpdated?: any;
    seoDescription: string;
    creatorUserName: string;
    lastUpdatedUserName: string;
    articleIDPath: string;
    breadCrumbInfo?: BreadCrumbInfo[],
}

export interface SearchRequest {
    phrase: string;
    pageSize: number;
    pageIndex: number;
    forSuggest: boolean;
    orderBy?: string;
    parametricFilter?: ParametricFilterRequest;
    priceRange?: number[];
    flags?: string[];
    onlyAvailable: boolean;
}

export interface SearchSelector {
    categories: CategoryItemSelector[];
    parameters: SearchParamItem[];
    filterParameters: SearchParameter[];
    flags: SearchFlagItem[];
    products: ProductSelector[];
    articles: ArticleBriefSelector[];
    totalProductCount: number;
}

export interface SearchParameter {
    ParamNameId: number;
    ParamName: string;
    ParamValues: SearchParamValue[];
}

export interface SearchParamValue {
    ParamValueId: number;
    ParamValue: string;
}

export interface SearchParamItem {
    ParamNameId: number;
    ParamName: string;
    ParamValueId: number;
    ParamValue: string;
}

export interface SearchFlagItem {
    Id: string;
    Name: string;
}

export type CartAdjustAction = 'ask' | 'merge' | 'keep' | 'replace';

/**
 * Collection of available validation patterns used in Settings interface
 */
export interface ValidationPatterns {
    email: RegExp;
    phone: IntRegExpDictionary;
    naturalNumber: RegExp;
    numberWithLeadingZeros: RegExp;
    decimalNumber: RegExp;
    formattedNaturalNumber: RegExp;
    zipCodeCz: RegExp;
}

export interface DecimalSettings {
    ProductBox: number;
    Detail: number;
    Wishlist: number;
    Basket: number;
    Compare: number;
}

export interface CurrencyDict {
    [key: number]: string;
}

/**
 * @description
 * Structure of the application settings which are stored in app/services/settings.service.ts.
 * This enables usage of settings via dot convention both in ts and html files.
 * If you add a setting to the json file, please add it to this interface as well.
 */
export interface Settings {
    preAuth: boolean;
    preAuthToLocal: boolean; // this makes the pre-authentication less agressive but less secure
    preAuthLocalExpiration: number;
    cultures: Array<Culture>;
    currencies: CurrencyDict;
    currencyCodes: CurrencyDict;
    validationPatterns: ValidationPatterns;
    imageServerPathPrefix: string;
    countries: Country[];
    cartAdjustAction: CartAdjustAction;
    pageSizes: any[];
    localDomain: string;
    currentServerUrl: string;
    decimalSettings: DecimalSettings;
    shopSeo: ShopSeoSelector;
    routesWithDefaultSeo: any[];
    assetPathPrefix: string;
    howToHtml: string;
}

/**
 * @description
 * used to interchange info between settings service and auth.interceptor
 * for setting HttpHeaders
 */
export interface HttpRegionalSettings {
    cultureId: number;
    currencyId: number;
    comAllowed: boolean;
}

export interface StringIndexedObject {
    [key: string]: any;
}

export interface NumberIndexedObject {
    [key: number]: any;
}

export interface IntRegExpDictionary {
    [key: number]: RegExp;
}

/**
 * Base class for all components which use translations
 * translationPrefix is used to distinguish translations for particular routes (not to take them all from db)
 */
export abstract class Translatable implements OnInit, OnDestroy {

    unsubscribe: Subject<any> = new Subject<any>();
    sen: StringIndexedObject = {};
    protected _userLoggedIn: boolean = CredentialStorage.userLoggedIn;
    protected logoutForced: EventEmitter<void> = new EventEmitter<void>();

    protected constructor(public dataSvc: DataService, public seSvc: SettingsService) {
        this.sen = this.seSvc.sen;
    }

    get userLoggedIn(): boolean {
        if (this._userLoggedIn && this._userLoggedIn != CredentialStorage.userLoggedIn) {
            this._userLoggedIn = false;
            this.logoutForced.emit();
        }
        return CredentialStorage.userLoggedIn;
    }

    get isReadOnly(): boolean {
        return CredentialStorage.isReadonly;
    }

    get hideBanners(): boolean {
        return CredentialStorage.hideBanners;
    }

    get hideCartAddInfo(): boolean {
        return CredentialStorage.hideBanners;
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    public Replace(value: string, replaceSource: string, replaceValue: string): string {
        return value.replace(replaceSource, replaceValue);
    }

    scrollToAnchor(where: string, offset: number = 0): void {
        $('html, body').animate({
            scrollTop: $(`.${where}`).offset().top + offset + 1 //+ 1 jen mala ojebavka aby se spravne oznacovaly zalozky
        }, 250);
    }

    scrollToTop(): void {
        $('html, body').animate({
            scrollTop: 0
        }, 250);
    }

    scrollToBottom(): void {
        window.scrollTo(0,document.body.scrollHeight);
    }

    scroll(id: string) {
        let el = document.getElementById(id);
        el.scrollIntoView();
      }

    scrollToSelector(selector: string, speed: number = 250): void {
       return ScrollUtils.ScrollTo({selector: selector, speed: speed});
    }

    Min(a: number, b: number): number {
        return a < b ? a : b;
    }
}

export interface BreadCrumbInfo {
    displayName: string;
    targetId?: number;
    targetSeoTail?: string;
    entityPrefix?: string;
    categoryIdPath?: string;
    target?: string;
}

export interface LoginResult {
    success: boolean;
    cultureId: number;
    currencyId: number;
    companyDisplayName: string;
    jwTokenBody: string;
    tokenExpirationMinutes: string;
    readOnly:boolean;
    hideBanners:boolean;
    hideCartAddInfo: boolean;
    pickUpTypeId: number;
    companyId: number;
    deliveryOADate: number;
}

export interface PreAuthRequest {
    userName: string;
    password: string;
    validTo: any;
}

export interface PreAuthResponseModel {
    success: boolean;
}

export interface CategoryInfo {
    id: number;
    name: string;
    seoUrl: string;
}

export function mapPrices(thePrices: IPriceInfo, theProduct: ProductVariantBriefSelector) {

    theProduct.priceWithVAT = thePrices.PriceWithVAT;
    theProduct.priceWithoutVAT = thePrices.PriceWithoutVAT;
    theProduct.basePrice = thePrices.BasePrice;
}

export interface ProductVariantBriefSelector {
    id: number;
    code: string;
    ean: string;
    qtyInStock?: number;
    availability: AvailabilitySelector;
    name: string;
    annotation: string;
    description: string;
    disabled: boolean;
    discontinued: boolean;
    priceWithVAT?: number;
    priceWithoutVAT?: number;
    basePrice?: number;
    discount?: number | string;
    imagePath?: string;
    imageTitle?: string;
    commonImagePath?: string;
    commonImageTitle?: string;
    seoUrl?: string;
    producerId: number;
    producerName?: string;
    isAction?: boolean;
    isNew?: boolean;
    isSellOut?: boolean;
    isRecommended?: boolean;
    rbpTag?: boolean;
    unit: UnitSelector;
    order?: number | string;
    breadcrumbInfo: BreadCrumbInfo[];
    nearestCategory?: CategoryInfo;
    langId?: number;
    ranking: number;
    isPhrase: boolean;
    vat: number;
    categoryIdPath?: string;
    minQtyOrder: number;
    maxQtyOrder: number;
    factor: number;
    variantKey: number;
    commonName: string;
    commonAnnotation: string;
    commonDescription: string;
    prices: IProductPriceSelector[];
    productAvailability?: IProductAvailability;
    //commonSeoUrl:string;
    weight: number;
    parameterImages: IParameterImage[];
}

export interface IQuickOrderProduct extends ProductVariantBriefSelector {
    Count?: number;
}

export interface IWishList {
    id: number;
    name: string;
    hash: string;
    lastModified: string;
    variantIds: number[];
    variants: ProductResponseModel<ISavedProduct>;

}

/**
 * @description Represents a range of values for the comparison 'the value is >= and <='.
 */
export interface ValueRangeParameter {
    id: number;
    fromValue: number;
    toValue: number;
}

/**
 * @description Represents a parameter value for the equality comparison of product parameters.
 */
export interface ExactValueParameter {
    id: number;
    valueId: number;
}

/**
 * @description Represents the filter according to parameter values of the products.
 */
export class ParametricFilterRequest {
    exactValueParameters: ExactValueParameter[] = [];
    valueRangeParameters: ValueRangeParameter[] = [];

    get paramsHash(): string {
        return sha256([...this.exactValueParameters, ...this.valueRangeParameters].join('|'));
    }

    /**
     * @description Adds a condition for the equality comparison to the parametric filter.
     */
    addExactValue(id: number, valueId: number) {
        const exactValue: ExactValueParameter = {
            id: id,
            valueId: valueId
        };
        this.exactValueParameters.push(exactValue);
    }

    /**
     * @description Adds a condition for the comparison 'the value is >= and <=' to the parametric filter.
     */
    addValueRange(id: number, fromValue: number, toValue: number) {
        let from = null;
        if (fromValue !== undefined && fromValue !== null) {
            from = parseFloat(fromValue.toString());
            if (isNaN(from)) {
                from = null
            }
        }

        let to = null;
        if (toValue !== undefined && toValue !== null) {
            to = parseFloat(toValue.toString());
            if (isNaN(to)) {
                to = null
            }
        }

        if (from !== null && to !== null) {
            const range: ValueRangeParameter = {
                id: id,
                fromValue: from,
                toValue: to
            };
            this.valueRangeParameters.push(range);
        }
    }

    /**
     * @description Clears all conditions for the parametric filter and sets state 'no filtering'.
     */
    clear(): void {
        this.valueRangeParameters.splice(0);
        this.exactValueParameters.splice(0);
    }
}

export interface ErrorSelector {
    message: string;
    subject?: string;
    stack?: string;
    url: string;
}

export interface PagedResponse<T> {
    total: number;
    data: T[];
}

export interface ShopSeoSelector {
    ShopName: string;
    ShopTitle: string;
    ShopDescription: string;
}


export interface AvailabilitySelector {
    id: number;
    order: number;
    name: string;
    jsonLd: string;
    isBuyable: boolean;
    showProductOnShop: string;
    exportToXmlFeeds: boolean;
    crossDock: boolean;

    visibilityId?: number;
    quantity?: number;
}

export interface GaSelector {
    domains: GaDomainSelector[];
    analyticsId: string;
    adsId: string;
    adsLabel: string;
    remarkId: string;
    adsIdGA4: string;
}

export interface GaDomainSelector {
    domainId: number;
    analyticsId: string;
    analyticsIdGA4: string;
}

export interface SeznamSelector {
    sklikConversionId: string;
    sklikRetargetingId: string;
    zboziConversionBranchId: string;
}

export interface HeurekaSelector {
    publicKey: string;
}

export interface IUrl {
    schema: string;
    domain: string;
    path: string;
    query: string;
    hash: string;
}

export interface UnitSelector {
    id: number;
    shortCut: string;
}

export type PageModeType = 'eshop' | 'cms';

export interface LogoSelector {
    logo: string;
    favicon: string;
    themeClass: string;
}

export interface MottoSelector {
    motto: string;
}

export interface CompanyIdentitySelector {
    imagePath: string;
    message: string;
    theme: ICompanyTheme;
}

export interface ICompanyTheme {
    CssClass: string;
    Name: string;
}

export interface LinkSelector {
    id: number;
    name: string;
    seoUrl: string;
}

export interface IDeliverySettings {
    companyId: number;
    pickUpTypeId: number;
    deliveryDateId: number;

    companyName: string;
    dateString: string;
}

export interface ICurrencySelector {
    Id: number;
    Name: string;
    DisplayName: string;
    Code: string;
    RoundTo: number;
    Enabled: boolean;
    FormatString: string;
    IsMain: boolean;
}

export interface UserTokenLoginRequest {
    token: string;
}

export interface UserTokenLoginSelector {
    login: string;
    psw: string
}

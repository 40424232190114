import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {StoreDeadlineComponent} from './store-deadline.component';
import {CustomPipesModule} from "../pipe/custom-pipes.module";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        CustomPipesModule
    ],
    exports: [StoreDeadlineComponent],
    declarations: [StoreDeadlineComponent],
    providers: [],
})

export class StoreDeadlineModule {
}

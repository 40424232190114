/**
 * @description
 * Serves as a container for front end application settings
 * Place only things that are really needed
 */
import {Injectable} from '@angular/core';
import {
    Culture,
    StringIndexedObject,
    SettingItem,
    Settings,
    GaSelector,
    SeznamSelector,
    HeurekaSelector
} from "../interfaces/general";
import {SharedAppSettings} from "../shared-settings/shared-settings";
import {loadFromLocalStorage, loadFromSession, saveToSession} from "../helpers/cookie.helper";
import {IntegrationService} from "../interceptors/integration.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {map, tap} from "rxjs/operators";
import {Sentences} from "../helpers/sentences";
import {IdentityService} from "./identity.service";
import {EmergencyModeService} from "./emergency-mode.service";

let settings: Settings =
    {
        preAuth: false,
        preAuthToLocal: true,
        preAuthLocalExpiration: 12,
        cartAdjustAction: "ask",
        pageSizes: [{value: 36}, {value: 72}, {value: 90}],
        countries: [
            {
                id: 326,
                lang: {34: 'Česká republika', 147: 'Cộng hòa Séc'}
            },
            {
                id: 462,
                lang: {34: 'Slovenská republika', 147: 'Cộng hòa Slovakia'}
            }
        ],
        cultures: [
            {
                code: "cs-CZ",
                cultureId: 34,
                name: "Česky",
                currencyCode: "CZK",
                currencySymbol: "Kč",
                translationKey: "app-czech",
                htmlLangAttribute: "cs",
                cultureBoundDomain: ""
            },
            {
                code: "vi-VN",
                cultureId: 147,
                name: "Vietnamsky",
                currencyCode: "CZK",
                currencySymbol: "Kč",
                translationKey: "app-vietnam",
                htmlLangAttribute: "vi",
                cultureBoundDomain: ""
            }
        ],
        validationPatterns: {
            email: /^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*(\+[_a-zA-Z0-9-]+)?@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,8})$/,
            naturalNumber: /^[1-9][0-9]*$/,
            decimalNumber: /^(\d*[.,])?\d+$/,
            formattedNaturalNumber: /^(\d{1,3} (\d{3} )*\d{3}|\d{1,3})$|^[1-9][0-9]*$/,
            numberWithLeadingZeros:  /^[0-9]*$/,
            phone: {
                34: /^(\+420)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/,
                102: /^(\+421)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/,
                50: /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?#(\d{4}|\d{3}))?$/,
                147: /^(\+420)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/
            },
            zipCodeCz: /^\d{3}\s?\d{2}$/
        },
        imageServerPathPrefix: "https://img.flex.scdev.cz",
        localDomain: "http://localhost/jipb2b",
        currentServerUrl: "",
        decimalSettings: null,
        currencies: {
            3: "Kč"
        },
        currencyCodes: {
            3: "CZK"
        },
        shopSeo: null,
        routesWithDefaultSeo: [/^$/, /^\/$/],
        assetPathPrefix: 'app/common/classic',
        howToHtml: ''
    };

export function GetLocaleFactory(seSvc: SettingsService) {
    return seSvc.getLocale()
}

@Injectable()
export class SettingsService {

    private productAvailabilityThreshold: number = null;

    public get ProductAvailabilityThreshold(): number {
        return this.productAvailabilityThreshold;
    }

    public set ProductAvailabilityThreshold(threshold: number) {
        this.productAvailabilityThreshold = threshold;
    }

    //**************************************************

    private termsAndConditions: string = null;

    public get TermsAndConditions(): string {
        return this.termsAndConditions;
    }

    public set TermsAndConditions(termsAndConditions: string) {
        this.termsAndConditions = termsAndConditions;
    }

    //**************************************************

    private gdpr: string = null;

    public get Gdpr(): string {
        return this.gdpr;
    }

    public set Gdpr(gdpr: string) {
        this.gdpr = gdpr;
    }

    //**************************************************

    private _cacheBustKey: string;
    get cacheBustKey(): string {
        return this._cacheBustKey;
    }

    set cacheBustKey(value: string) {
        this._cacheBustKey = value;
    }

    private _availabilitySetting: AvailabilitySetting;
    get availabilitySetting(): AvailabilitySetting {
        return this._availabilitySetting;
    }

    set availabilitySetting(value: AvailabilitySetting) {
        this._availabilitySetting = value;
    }

    private _sen: StringIndexedObject;
    get sen(): StringIndexedObject {
        return this._sen;
    }

    set sen(value: StringIndexedObject) {
        this._sen = value;
        Sentences.sen = value;
    }

    private _culture: Culture;
    get culture(): Culture {
        return this._culture;
    }

    set culture(value: Culture) {
        this._culture = value;
    }

    private _gaSettings: GaSelector;
    get gaSettings(): GaSelector {
        return this._gaSettings;
    }

    set gaSettings(value: GaSelector) {
        this._gaSettings = value;
    }

    public rbpMetricsGaOn: boolean = true;

    public rbpMetricsLocOn: boolean = false;

    private _seznamSettings: SeznamSelector;
    get seznamSettings(): SeznamSelector {
        return this._seznamSettings;
    }

    set seznamSettings(value: SeznamSelector) {
        this._seznamSettings = value;
    }

    private _heurekaSettings: HeurekaSelector;
    get heurekaSettings(): HeurekaSelector {
        return this._heurekaSettings;
    }

    set heurekaSettings(value: HeurekaSelector) {
        this._heurekaSettings = value;
    }

    private _recaptchaSharedKey: string = null;
    public get recaptchaSharedKey(): string {
        return this._recaptchaSharedKey;
    }

    public set recaptchaSharedKey(value: string) {
        this._recaptchaSharedKey = value;
    }

    settings: Settings;
    sharedAppDefaults: StringIndexedObject;
    currencyId: number;
    defCulture: Culture;
    defCurrency: number;
    comAllowed: boolean = false;

    private _modalOpen: boolean = false;
    get modalOpen(): boolean {
        return this._modalOpen;
    }

    set modalOpen(value: boolean) {
        this._modalOpen = value;
    }

    get baseHref(): string {
        return document.head.getElementsByTagName('base').item(0).href;
    }

    constructor(
        saSvc: SharedAppSettings,
        private http: HttpClient,
        private iSvc: IntegrationService,
        private idSvc: IdentityService,
        private emergencyModeSvc: EmergencyModeService,
        private sapSvc: SharedAppSettings
    ) {

        this.settings = settings;
        this.sharedAppDefaults = saSvc.Defaults;

        // Culture and currency are retrieved from the shared application settings
        // and valid either until user logs in or changes them manually
        this.defCulture = this.settings.cultures.filter((c) => c.cultureId == this.sharedAppDefaults['CultureId'])[0];
        this.defCurrency = this.sharedAppDefaults['CurrencyId'];

        let cult = <Culture>loadFromSession('cult');
        if (cult) {

            this.culture = cult;
        } else {
            this.culture = this.defCulture;
            saveToSession('cult', this.culture)
        }
        let curr = loadFromSession('curr');
        if (curr) {
            this.currencyId = curr;
        } else {
            this.currencyId = this.defCurrency;
            saveToSession('curr', this.currencyId)
        }

        SettingsService.setHtmlLangAttribute(this.culture.htmlLangAttribute);
    }

    setCulture(cultureId: number, currencyId?: number) {
        this.setCultureAndReload(false, cultureId, currencyId);
    }

    setCultureAndReload(reload: boolean, cultureId: number, currencyId?: number) {

        this.culture = this.settings.cultures.filter(c => c.cultureId == cultureId)[0];
        saveToSession('cult', this.culture);

        if (currencyId) {
            this.currencyId = currencyId;
            saveToSession('curr', this.currencyId);
        }

        SettingsService.setHtmlLangAttribute(this.culture.htmlLangAttribute);

        if (reload) location.reload();
    }

    private setStartUpSettings(settingMap: Map<string, any>): void {

        this.settings.imageServerPathPrefix = settingMap.get('ImageServer');
        this.settings.currentServerUrl = settingMap.get('ServerUrl');
        this.settings.preAuth = settingMap.get('PreAuthEnabled') === "true";
        this.settings.decimalSettings = JSON.parse(settingMap.get('DecimalSettings'));

        if(JSON.parse(settingMap.get('ShopSeoSettings'))[this.idSvc.shopId])
            this.settings.shopSeo = JSON.parse(settingMap.get('ShopSeoSettings'))[this.idSvc.shopId][this.culture.cultureId];
        else
            this.settings.shopSeo = JSON.parse(settingMap.get('ShopSeoSettings'))[this.sapSvc.Defaults.ShopId][this.culture.cultureId];

        this.settings.assetPathPrefix = settingMap.get('AssetPathPrefix');
        this.settings.howToHtml = settingMap.get('HowTo');
        this.availabilitySetting = settingMap.get('Availability');
        this.cacheBustKey = settingMap.get('ImageCacheBust');
        this.gaSettings = settingMap.get('GaSettings');
        this.rbpMetricsGaOn = settingMap.get('RbpMetricsGaOn');
        this.rbpMetricsLocOn = settingMap.get('RbpMetricsLocOn');
        this.seznamSettings = settingMap.get('SeznamSettings');
        this.heurekaSettings = settingMap.get('HeurekaSettings');
        this.ProductAvailabilityThreshold = settingMap.get('ProductAvailabilityThreshold');
        this.TermsAndConditions = settingMap.get('TermsAndConditions');
        this.Gdpr = settingMap.get('Gdpr');
        this.idSvc.addLogoSettings(settingMap.get('Logo'));
        this.idSvc.addMottoSettings(settingMap.get('Motto'));
        this.recaptchaSharedKey = settingMap.get('RecaptchaSharedKey');
        this.idSvc.setFavicon(this.settings.imageServerPathPrefix);
        this.emergencyModeSvc.Enabled = settingMap.get('IsEmergencyModeEnabled');

        let pa = this.settings.preAuthToLocal ? loadFromLocalStorage('pa') : loadFromSession('pa');
        this.comAllowed = pa || !this.settings.preAuth;
        this.setCurrentServerUrl();
    }

    private setUpApplication(input: any): any {
        const m = SettingsService.makeSettingsMap(input['StartUpSettings']);
        this.idSvc.shopId = input['ShopId'];
        this.setStartUpSettings(m);
        return this.makeSentenceObject(input['Translations']);
    }

    getAllTranslations(): any {

        let options = {headers: new HttpHeaders()};
        options.headers.set('F-CC', `cu:${this.culture.cultureId},cy:${this.currencyId}`);

        this.iSvc.httpRegional = {
            comAllowed: this.comAllowed,
            cultureId: this.culture.cultureId,
            currencyId: this.currencyId
        };

        let result = this.http.get(`api/lang/${this.culture.cultureId}`, options)
            .pipe(
                map(res => this.setUpApplication(res)),
                tap(res => {
                    this.sen = res;
                    this.iSvc.httpRegional.comAllowed = this.comAllowed;
                })
            );
        return result.toPromise()
    }

    getLocale(): string {
        return this.culture.code;
    }

    private makeSentenceObject(input: Object[]): Object {
        let tmp: StringIndexedObject = {};
        if (input) {
            input.forEach((e: any) => tmp[e['Key']] = e['Value']);
        }
        return tmp;
    }

    private static makeSettingsMap(items: SettingItem[]): Map<string, any> {
        let m: Map<string, any> = new Map();
        for (let item of items) {
            m.set(item.Key, item.Value);
        }
        return m;
    }

    private static setHtmlLangAttribute(lang: string): void {
        document.querySelector('html').setAttribute('lang', lang);
    }

    private setCurrentServerUrl(): void {
        sessionStorage.setItem('currentServerUrl', this.settings.currentServerUrl)
    }

    public GetCalendarLocalization(cultureId: number): any {
        if (cultureId === 34) { // az bude potreba tak pridat vietnamstinu
            return {
                firstDayOfWeek: 1,
                dayNames: ["Neděle", "Pondělí", "Úterý", "Středa", "Čtvrtek", "Pátek", "Sobota"],
                dayNamesShort: ["Ne", "Po", "Út", "St", "Čt", "Pá", "So"],
                dayNamesMin: ["Ne", "Po", "Út", "St", "Čt", "Pá", "So"],
                monthNames: ["Leden", "Únor", "Březen", "Duben", "Květen", "Červen", "Červenec", "Srpen", "Září", "Říjen", "Listopad", "Prosinec"],
                monthNamesShort: ["Led", "Úno", "Bře", "Dub", "Kvě", "Čer", "Čvn", "Srp", "Zář", "Říj", "Lis", "Pro"],
                today: 'Dnes',
                clear: 'Clear'
            };
        } else if (cultureId === 147) {
            return {
                firstDayOfWeek: 1,
                dayNames: ["C.Nhật", "Thứ 2", "Thứ 3", "Thứ 4", "Thứ 5", "Thứ 6 ", "Thứ 7"],
                dayNamesShort: ["CN", "T2", "T3", "T4", "T5", "T6", "T7"],
                dayNamesMin: ["CN", "T2", "T3", "T4", "T5", "T6", "T7"],
                monthNames: ["Tháng một", "Tháng hai", "Tháng ba", "Tháng tư", "Tháng năm", "Tháng sáu", "Tháng bảy", "Tháng tám", "Tháng chín", "Tháng mười", "Tháng mười một", "Tháng mười hai"],
                monthNamesShort: ["Led", "Úno", "Bře", "Dub", "Kvě", "Čer", "Čvn", "Srp", "Zář", "Říj", "Lis", "Pro"],
                today: 'Hôm nay',
                clear: 'Clear'
            }
        }
    }

}

export enum AvailabilitySetting {
    onlyText = 'onlyText',
    onlyNumber = 'onlyNumber',
    both = 'both'
}
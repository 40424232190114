import {Component, Input, ViewEncapsulation} from '@angular/core';
import {PageModeType, Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";
import {Subject} from "rxjs";
import {MenuService} from "../../services/menu.service";

@Component({
    selector: 'cmp-header-responsive-menu',
    templateUrl: '../../tpl/header-responsive-menu.html',
    styleUrls: ['../../assets/styles/3-layout/header/responsive-menu.scss'],
    encapsulation: ViewEncapsulation.None
})

export class HeaderResponsiveMenuComponent extends Translatable {

    @Input() pageMode: PageModeType;

    ngUnsubscribe: Subject<any> = new Subject<any>();

    constructor(public dataSvc: DataService, seSvc: SettingsService, private menuSvc: MenuService) {
        super(dataSvc, seSvc);


    }

    ngOnInit(): void {

    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        super.ngOnDestroy();
    }

    toggleMenu(event:MouseEvent){
        this.menuSvc.toggleMenu.emit(event);
    }
}
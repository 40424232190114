import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {SettingsSocialComponent} from "./settings-social.component";


@NgModule({
    imports: [CommonModule],
    declarations: [SettingsSocialComponent],
    exports: [SettingsSocialComponent]
})

export class SettingsSocialModule {}
import {Component, Input, OnInit} from '@angular/core';
import {SettingsService} from "../../../services/settings.service";
import {DataService} from "../../../services/data.service";
import {Translatable} from "../../../interfaces/general";

@Component({
    selector: 'cmp-price',
    templateUrl: './price.component.html',
    styleUrls: ['./price.component.scss']
})
export class PriceComponent extends Translatable implements OnInit {

    @Input() price: number = null;
    @Input() decimalPattern: string = null;
    @Input() currencySymbol: string = null;

    constructor(
        seSvc: SettingsService,
        dataSvc: DataService
    ) {
        super(dataSvc, seSvc);
        this.currencySymbol = this.seSvc.settings.currencies[this.seSvc.currencyId];
        this.decimalPattern = `1.${this.seSvc.settings.decimalSettings.Detail}-${this.seSvc.settings.decimalSettings.Detail}`;
    }
    ngOnInit() {
    }

}

import {Injectable} from "@angular/core";
///////////////////////////////////////////

/**
 * Automatically generated based on AccountModule from SharedSettings dll
 */

@Injectable()
export class SharedAccountSettings 
    {
ContactType = {
Phone: 'Phone',
Mobile: 'Mobile',
Email: 'E-mail',
Fax: 'Fax'
};
Invoice = {
InvoiceStatus: {

}
};
SourceType = {
Eshop: 1,
IS: 2
}
}




/**
 * Automatically generated based on AddressModule from SharedSettings dll
 */

@Injectable()
export class SharedAddressSettings 
    {
AddressType = {
Invoice: 'Invoice',
Delivery: 'Delivery'
}
}




/**
 * Automatically generated based on AppModule from SharedSettings dll
 */

@Injectable()
export class SharedAppSettings 
    {
Defaults = {
CultureId: 34,
CurrencyId: 3,
MenuArticleCode: 'top-menu',
NewsArticleCode: 'news',
FooterArticleCode: 'footer',
AllowAnonymousCart: false,
FacebookShareDomain: 'https://www.jip-eshop.cz/',
Domain: 'https://www.jip-eshop.cz/',
ProductionDomain: 'https://www.jip-eshop.cz/',
TitlePrefix: 'JIP B2B',
GrAvatarCoreUrl: 'https://www.gravatar.com',
FeedFolder: '/tmp/feeds',
CustomerFeedFolder: '/tmp/customer-feeds',
Company: 'JIP',
ShopId: 78
};
Search = {
FlexSuggest: true,
LuigisSuggest: false,
FlexSearch: true,
LuigisSearch: false
}
}




/**
 * Automatically generated based on CartModule from SharedSettings dll
 */

@Injectable()
export class SharedCartSettings 
    {
CartItemType = {
PRODUCT: 'PRODUCT',
RELATED: 'RELATED',
PAYMENT: 'PAYMENT',
TRANSPORT: 'TRANSPORT',
COUPON: 'COUPON',
GIFT: 'GIFT'
};
ZboziSandbox = {
UseSandbox: false,
ZboziConversionApiUrl: 'https://www.zbozi.cz/action/#REPLACE#/conversion/backend',
ZboziConversionSandboxUrl: 'https://sandbox.zbozi.cz/action/#REPLACE#/conversion/backend'
}
}




/**
 * Automatically generated based on CategoryModule from SharedSettings dll
 */

@Injectable()
export class SharedCategorySettings 
    {
Sorting = {
order_asc: 'order_asc',
price_asc: 'price_asc',
price_desc: 'price_desc',
name_asc: 'name_asc',
name_desc: 'name_desc',
recommended_desc: 'recommended_desc',
BestSelling: 'best-selling',
FromLatest: 'from_latest',
ByName: 'by_name',
rank: 'rank'
}
}




/**
 * Automatically generated based on ProductModule from SharedSettings dll
 */

@Injectable()
export class SharedProductSettings 
    {
Flags = {
bNew: 'bNew',
bSellOut: 'bSellOut',
bFavorite: 'bFavorite',
bAction: 'bAction'
};
WatchDog = {
StockMonitorTypes: {
AllChanges: 'watch-dog-all-stock-changes',
ChangeFromZero: 'watch-dog-on-stock-change-from-zero'
},
PriceMonitorTypes: {
Cheapen: 'watch-dog-cheapen',
Threshold: 'watch-dog-cheapen-under-threshold'
}
}
}


/**
 * serves as a reporter of unhandled JS errors
 * errors are reported to tblLog with sSubject = 'GEH JS Error'
 */
import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {DigestService} from "./digest.service";
import {HttpClient} from "@angular/common/http";
import {ErrorSelector} from "../interfaces/general";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(private injector: Injector) {
    }

    handleError(error: any): void {
        const digestSvc: DigestService = <DigestService>this.injector.get(DigestService);
        const http: HttpClient = <HttpClient>this.injector.get(HttpClient);

        let errorSelector: ErrorSelector = this.TryStringifyError(error);

        http.post<ErrorSelector>('api/error/report', errorSelector)
            .subscribe(() => {
            });

        if (error.status && error.status == 403) {
            digestSvc.logOut();
        }
        throw error;
    }

    private TryStringifyError(error: any): ErrorSelector {
        let errorSelector: ErrorSelector = null;

        try {
            errorSelector = {
                message: `${error.name}|${error.message}`,
                stack: error.stack || new Error().stack,
                url: location.href
            };
        } catch (e) {
            errorSelector = {
                message: 'Unable to stringify error object.',
                stack: new Error().stack,
                url: location.href
            };
        }
        return errorSelector;
    }

}
import { Injectable } from "@angular/core";
import { SettingsService } from "./settings.service";
import {
    ProductDetailModel,
    ProductSelector,
    ProductVariantDetailSelector
} from "../modules/product/common";
import { CartService } from "../modules/cart/cart.service";
import {CartItemSelectorFull, OrderHeaderSelector, OrderItemSelector} from "../modules/cart/common";
import {IdentityService} from "./identity.service";
import {DataService} from "./data.service";
import {RbpMetricsService} from "./rbp-metrics.service";
import {loadFromSession} from "../helpers/cookie.helper";


@Injectable()
export class GoogleTagService {

    gaId: string; // hlavni ga kod
    gaAddsId: string; // reklamni ga kod
    debug: boolean = false;

    get isGa(): boolean {
        return this.gaId != null && this.gaId != undefined;
    }

    get isGaAdds(): boolean {
        return this.gaAddsId != null && this.gaAddsId != undefined;
    }

    constructor(private seSvc: SettingsService,
                private cartSvc: CartService,
                private idSvc: IdentityService,
                public dataSvc: DataService,
                private rbpMetricSvc: RbpMetricsService) {

        if(dataSvc.domain.includes("scdev.cz")) {
            this.debug = true;
        }

        if(this.seSvc.gaSettings.domains.filter(d => d.domainId == this.idSvc.shopId).length > 0) {
            this.gaId =this.seSvc.gaSettings.domains.filter(d => d.domainId == this.idSvc.shopId)[0].analyticsIdGA4;
        }
        else {
            this.gaId = "";
        }
        this.gaAddsId = this.seSvc.gaSettings.adsIdGA4;

        if(this.debug) {
            console.log('nacteno GaId: ' + this.gaId);
            console.log('nacteno GaAddsId: ' + this.gaAddsId);
        }
    }

    // To i je poradi v katalogu. Ale pro detail uz to postrada smysl. Ale zas na co mit 20 funcki. Mohl bych jjeste posilat | i to druhy.
    // Ale zase by tam musela byt detekce na class a stejne by tam bylo vsehcno jako v ty druhy
    private MapGtagItem(variant: ProductVariantDetailSelector, i: number, categoryName: string, quantity: number): any {

        if (quantity == null) {
            if (i != null) {
                return {
                    "id": variant.code,
                    "name": variant.name,
                    "brand": variant.producerName,
                    "category": categoryName,
                    "variant": variant.name,
                    "list_position": i.toString(),
                    "price": variant.priceWithVAT,
                };
            }

            return {
                "id": variant.code,
                "name": variant.name,
                "brand": variant.producerName,
                "category": categoryName,
                "variant": variant.name,
                "price": variant.priceWithVAT
            };
        }

        if (i != null) {
            return {
                "id": variant.code,
                "name": variant.name,
                "brand": variant.producerName,
                "category": categoryName,
                "variant": variant.name,
                "list_position": i.toString(),
                "price": variant.priceWithVAT,
                "quantity": quantity
            };
        }

        return {
            "id": variant.code,
            "name": variant.name,
            "brand": variant.producerName,
            "category": categoryName,
            "variant": variant.name,
            "price": variant.priceWithVAT,
            "quantity": quantity
        };

    }

    private MapGtagCartItem(variant: CartItemSelectorFull/*ProductCartSelector*/, i: number, categoryName: string, quantity: number): any {

        if(variant && variant.productDetail){
            return null;
        }

        if (quantity == null) {
            if (i != null) {
                return {
                    "id": variant.productDetail.code,
                    "name": variant.productDetail.name,
                    "brand": '', //variant.producerName,
                    "category": categoryName,
                    "variant": variant.productDetail.name,
                    "list_position": i.toString(),
                    "price": variant.priceWithVAT,
                };
            }

            return {
                "id": variant.productDetail.code,
                "name": variant.productDetail.name,
                "brand": '', //variant.producerName,
                "category": categoryName,
                "variant": variant.productDetail.name,
                "price": variant.priceWithVAT
            };
        }

        if (i != null) {
            return {
                "id": variant.productDetail.code,
                "name": variant.productDetail.name,
                "brand": '', //variant.producerName,
                "category": categoryName,
                "variant": variant.productDetail.name,
                "list_position": i.toString(),
                "price": variant.priceWithVAT,
                "quantity": quantity
            };
        }

        return {
            "id": variant.productDetail.code,
            "name": variant.productDetail.name,
            "brand": '', //variant.producerName,
            "category": categoryName,
            "variant": variant.productDetail.name,
            "price": variant.priceWithVAT,
            "quantity": quantity
        };

    }

    private MapGtagItemsFromBox(variant: ProductDetailModel, i: number, categoryName: string): any {

        if (i != null) {
            return {
                "id": variant.code,
                "name": variant.name,
                "brand": variant.producerName,
                "category": categoryName,
                "variant": variant.name,
                "list_position": i.toString(),
                "price": variant.priceWithVAT
            };
        }

        return {
            "id": variant.code,
            "name": variant.name,
            "brand": variant.producerName,
            "category": categoryName,
            "variant": variant.name,
            "price": variant.priceWithVAT
        };

    }

    private MapGtagItemOrder(orderItem: OrderItemSelector): any {
        return {
            "id": orderItem.code,
            "name": orderItem.name,
            "brand": '',
            "category": '',
            "variant": orderItem.variant,
            "quantity": orderItem.count,
            "price": orderItem.priceWithVat
        }
    }

    private mapBasketToItems(): any[] {

        let items = [];
        let length: number = this.cartSvc.cart.cartContent.length;

        for (let i = 0; i < length; i++) {
            if (this.cartSvc.cart.cartContent[i].productDetail) {
                let categoryName: string;
                if(this.cartSvc.cart.cartContent[i].productDetail.nearestCategory) {
                    categoryName = this.cartSvc.cart.cartContent[i].productDetail.nearestCategory.name;
                }
                else {
                    categoryName = '';
                }
                items.push(this.MapGtagCartItem(this.cartSvc.cart.cartContent[i]/*.productDetail*/, null, categoryName, this.cartSvc.cart.cartContent[i].count));
            }
        }

        return items;
    }

    private mapOrderToItems(orderItems: OrderItemSelector[]): any[] {
        let items = [];

        for (let i = 0; i < orderItems.length; i++) {
            let orderItem = orderItems[0];
            if (orderItem.typeItem == "PRODUCT") {
                let item = this.MapGtagItemOrder(orderItem);
                items.push(item);
            }
        }

        return items;
    }

    // Click na detail produktu v ramci vypisu
    public addGoogleTagClickToProductDetail(product: ProductDetailModel, categoryName: string, products: ProductSelector[]): void {
        let i = products.findIndex(x => x.variants[0].id == product.variantId);
        if (i) {
        }

        if (this.isGa && this.isGaAdds) {
            let items = [];
            let i = products.findIndex(x => x.variants[0].id == product.variantId);
            let item = this.MapGtagItemsFromBox(product, i, categoryName);
            items.push(item);

            let result = {
                "content_type": "product",
                "items": items,
                "send_to": [this.gaId, this.gaAddsId]
            }

            this.googleTagCall(result, "addGoogleTagClickToProductDetail", "select_content");

            //gtag('event', 'select_content', {
            //    "content_type": "product",
            //   "items": [ { "id": "P67890", "name": "Flame challenge TShirt", "brand": "MyBrand", "category": "Apparel/T-Shirts", "variant": "Red", "list_position": 2, "price": 3 } ],
            //   "send_to": [
            //       "UA-106123012-2", /* UA kód */
            //       "AW-953774234/PbLfCND1qm4QmuHlxgM"] /* Google Ads/conversion label*/
            //});
        }
    }

    // Koupeni produktu ve vypisu produktu
    public addGoogleTagBuyProduct(product: ProductDetailModel, categoryName?: string, products?: ProductSelector[]): void {
        if (products)
        {
            let i = products.findIndex(x => x.variants[0].id == product.variantId);
            if (i) {

            }

            if (this.isGa && this.isGaAdds) {
                let items = [];
                let i = products.findIndex(x => x.variants[0].id == product.variantId);
                let item = this.MapGtagItemsFromBox(product, i, categoryName);
                items.push(item);

                let result = {
                    "items": items,
                    "send_to": [this.gaId, this.gaAddsId]
                };

                this.googleTagCall(result, "addGoogleTagBuyProduct", "add_to_cart");
            }
        } else{
            if (this.seSvc.rbpMetricsLocOn) {
                this.rbpMetricSvc.addRbpAddToCartLocal(loadFromSession('reservationId'), product.code);
            }
        }
        // gtag('event', 'add_to_cart', { "items": [ { "id": "P12345", "name": "Android Warhol T-Shirt", "brand": "Google", "category": "Apparel/T-Shirts", "variant": "Black", "list_position": 1, "quantity": 2, /*počet ks/množství*/ "price": '2.0' } ], "send_to": ["UA-106123012-2", /* UA kód */"AW-953774234/PbLfCND1qm4QmuHlxgM" /* Google Ads/conversion label*/] });
    }

    // Ga pro kategorii - jsou tam 2 veci. Pro klasicke ga + pro adds.
    public addGoogleTagForCategory(type: string, products: ProductSelector[], categoryName: string): void {

        if (this.isGa && type == "UAProducts") {

            let gaProducts = [];

            for (let i = 0; i < products.length; i++) {
                let variant = products[i].variants[0];
                let product = this.MapGtagItem(variant, i, categoryName, null);
                gaProducts.push(product);
            }

            let result = {
                'items': gaProducts,
                'send_to': [this.gaId, this.gaAddsId]
            }

            this.googleTagCall(result, "addGoogleTagForCategory", "view_item_list")
        }

        this.addGoogleTagAddsCategoryOrArticleCategory(categoryName);
    }

    // Zobrazeni detailu
    public addGoogleTagProductDetailView(product: ProductVariantDetailSelector, categoryName: string): void {
        //gtag('event', 'view_item', { "items": [ { "id": "P12345", "name": "Android Warhol T-Shirt", "brand": "Google", "category": "Apparel/T-Shirts", "variant": "Black", "price": '255.0' } ], "send_to": ["UA-106123012-2", "AW-953774234/PbLfCND1qm4QmuHlxgM"] });
        let items = [];
        let item = this.MapGtagItem(product, null, categoryName, null);
        items.push(item);

        let result = {
            "items": items,
            "send_to": [this.gaId, this.gaAddsId]
        };

        this.googleTagCall(result, "addGoogleTagProductDetailView", "view_item")

        // Volani adds
        this.addGoogleTagAddsDetailProduct(product, categoryName);
    }

    // Koupeni z detailu produktu
    public addGoogleTagBuyProductDetail(product: ProductDetailModel, categoryName: string, reservationId?: number) {
        if (!reservationId)
        {
            if (this.isGa && this.isGaAdds) {
                let items = [];
                let item = this.MapGtagItemsFromBox(product, null, categoryName);
                items.push(item);

                let result = {
                    "items": items,
                    "send_to": [this.gaId, this.gaAddsId]
                };

                this.googleTagCall(result, "addGoogleTagBuyProductDetail", "add_to_cart")
            }
        } else {
            if (this.seSvc.rbpMetricsLocOn) {
                this.rbpMetricSvc.addRbpAddToCartLocal(reservationId, product.code);
            }
        }
    }

    // kosik 1. obsah kosiku - begin_checkout'
    // kosik 2. obsah kosiku po vyberu dopravy a platby checkout_progress'
    // Kosik 3. polzoky zbozi pri kontrole udaju 'checkout_progress',
    public addGoogleTagBasketInfoStep(step: number, couponCode: string, eventName: string): void {
        /*gtag('event', 'begin_checkout',
            {
                "checkout_step": 1,
                "items": [ { "id": "P12345", "name": "Android Warhol T-Shirt", "brand": "Google", "category": "Apparel/T-Shirts", "variant": "Black", "quantity": 2, "price": '2.0' }, { "id": "P67890", "name": "Flame challenge TShirt", "brand": "MyBrand", "category": "Apparel/T-Shirts", "variant": "Red","quantity": 2, "price": 3 } ],
                "coupon": "",
                "send_to": ["UA-106123012-2", "AW-953774234/PbLfCND1qm4QmuHlxgM"] });
        */

        if (this.cartSvc.cart.cartContent == null)
            return;

        let result = {
            "checkout_step": step,
            "items": this.mapBasketToItems(),
            "coupon": couponCode,
            "send_to": [this.gaId, this.gaAddsId]
        }

        this.googleTagCall(result, "addGoogleTagBasketInfoStep", eventName)

        this.addGoogleTagAddsCart(step);
    }

    // kosik 1. remove product z kosiku
    // Mozna se tu bude muset poslat id polozky a to se pak nacist z cartcontentu
    public addGoogleTagBasketRemoveProduct(cartId: number) {
        //gtag('event', 'remove_from_cart', { "items": [ { "id": "P12345", "name": "Android Warhol T-Shirt", "brand": "Google", "category": "Apparel/T-Shirts", "variant": "Black", "quantity": 2, "price": '2.0' } ], "send_to": ["UA-106123012-2", "AW-953774234/PbLfCND1qm4QmuHlxgM"] });
        let cartItem = this.cartSvc.cart.cartContent.find(x => x.id == cartId);

        if (cartItem.couponDetail != null)
            return;

        let items = [];
        let removeProduct = this.MapGtagCartItem(cartItem, null, cartItem.productDetail.nearestCategory.name, cartItem.count);

        items.push(removeProduct);

        let result = {
            "items": items,
            "send_to": [this.gaId, this.gaAddsId]
        }

        this.googleTagCall(result, "addGoogleTagBasketRemoveProduct", "remove_from_cart")
    }

    // kosik 2. vybrani dopravy a platby. Jen info o vybrane doprave a platbe
    public addGoogleTagDeliveryPaymentInfo(checkoutOptions: string, price: number): void {
        //gtag('event', 'set_checkout_option', { "checkout_step": 2,  "checkout_option": "PPL/dobírka", "value": 3 /*cena celkem za poštovné*/ });
        //  let checkout_option : string = relation.Delivery.name + "/" + relation.Payment.name;
        //  let price : number = relation.Delivery.priceWithVat + relation.Payment.priceWithVat;

        let result = {
            "checkout_step": 2, /*2.krok objednávky*/
            "checkout_option": checkoutOptions,  /*názvy zvolené dopravy a platby*/
            "value": price /*cena celkem za poštovné - tady asi mysli cenu dopravy a platby. */
        }

        this.googleTagCall(result, "addGoogleTagDeliveryPaymentInfo", "set_checkout_option")
    }

    // Kosik 4. finis order info
    public addGoogleTagOrderPurchase(orderHeader: OrderHeaderSelector, orderItems: OrderItemSelector[]): void {
        //gtag('event', 'purchase',
        //    {
        //        "transaction_id": "2022010122", /*číslo objednávky*/
        //        "affiliation": "Google online store", /*referer*/
        //        "value": 23.07, /*cena objednávky bez DPH a poštovného*/
        //        "currency": "CZK", /* měna */
        //        "tax": 1.24, /* hodnota DPH v aktuální měně (bez poštovného) */
        //        "shipping": 2, /* cena za poštovné bez DPH */
        //        "shippingTax": 0.79, /* hodnota DPH poštovného */
        //       "items": [ { "id": "P12345", "name": "Android Warhol T-Shirt", "brand": "Google", "category": "Apparel/T-Shirts", "variant": "Black", "quantity": 2, "price": '2.0' }, { "id": "P67890", "name": "Flame challenge TShirt", "brand": "MyBrand", "category": "Apparel/T-Shirts", "variant": "Red", "quantity": 2, "price": 3 } ],
        //      "send_to": ["UA-106123012-2", "AW-953774234/PbLfCND1qm4QmuHlxgM"] });

        let shipmentVat = orderHeader.shippingTypePriceWithVat - orderHeader.shippingTypePriceWithoutVat;
        let items = this.mapOrderToItems(orderItems);

        let result = {
            "transaction_id": orderHeader.id.toString(), /*číslo objednávky*/
            "affiliation": "neni referer", /*referer*/
            "value": orderHeader.priceSumWithoutVat - orderHeader.shippingTypePriceWithoutVat,/*cena objednávky bez DPH a poštovného*/
            "currency": orderHeader.currencyCode.toUpperCase(), /* měna */
            "tax": orderHeader.priceSumWithVat - shipmentVat, /* hodnota DPH v aktuální měně (bez poštovného) */
            "shipping": orderHeader.shippingTypePriceWithoutVat, /* cena za poštovné bez DPH */
            "shippingTax": shipmentVat, /* hodnota DPH poštovného */
            "items": items,
            "send_to": [this.gaId, this.gaAddsId]
        }

        this.googleTagCall(result, "addGooglTagOrderFinish", "purchase")

        this.addGoogleTagAddsOrderPurchase(orderHeader, orderItems);

        if (this.seSvc.rbpMetricsLocOn) {
            this.rbpMetricSvc.addRbpConversionLocal(orderItems);
        }
    }

    // Tady jsou jen reklamani nebo jak to rict
    // Homepage
    public addGoogleTagAddsHomePage(): void {
        //gtag('event', 'page_view', { "ecomm_pagetype": "home", "send_to": "AW-953774234/PbLfCND1qm4QmuHlxgM" /* Google Ads/conversion label*/ });

        // Neni kod neni laska
        if (!this.isGaAdds) {
            return;
        }

        let result = {
            "ecomm_pagetype": "home",
            "send_to": this.gaAddsId
        }

        this.googleTagCall(result, "addGoogleTagAddsHomePage", "page_view")
    }

    //Výsledky hledání

    public addGoogleTagAddsSearchResult(): void {
        //gtag('event', 'page_view', { "ecomm_pagetype": "searchresult", "send_to": "AW-953774234/PbLfCND1qm4QmuHlxgM" /* Google Ads/conversion label*/ });
        if (!this.isGaAdds) {
            return;
        }

        let result = {
            "ecomm_pagetype": "searchresult",
            "send_to": this.gaAddsId
        }

        this.googleTagCall(result, "addGoogleTagAddsSearchResult", "page_view")
    }

    //Kategorie produktů/článků
    public addGoogleTagAddsCategoryOrArticleCategory(categoryName: string): void {
        //gtag('event', 'page_view', { "ecomm_pagetype": "category", , /* název kategorie */"send_to": "AW-953774234/PbLfCND1qm4QmuHlxgM" /* ID Google Ads/conversion label*/ });
        if (!this.isGaAdds) {
            return;
        }

        let result = {
            "ecomm_pagetype": "category",
            "ecomm_category": categoryName,
            "send_to": this.gaAddsId
        }

        this.googleTagCall(result, "addGoogleTagAddsCategoryOrArticleCategory", "page_view")
    }

    //Detail produktu
    public addGoogleTagAddsDetailProduct(product: ProductVariantDetailSelector, categoryName: string): void {
        // gtag('event', 'page_view', { "ecomm_pagetype": "product", "ecomm_category": "Elektronika", /* název primární kategorie */ "ecomm_prodid": "ID_123", /*id produktu*/ "ecomm_totalvalue": 250.90, /*Prodejní cena bez měny*/ "google_business_vertical": "retail", "send_to": "AW-953774234/PbLfCND1qm4QmuHlxgM" /* ID Google Ads/conversion label*/ });
        if (!this.isGaAdds) {
            return;
        }

        let result = {
            "ecomm_pagetype": "product",
            "ecomm_category": categoryName, /* název primární kategorie */
            "ecomm_prodid": product.code, /*id produktu*/
            "ecomm_totalvalue": product.priceWithoutVAT, /*Prodejní cena bez měny*/
            "google_business_vertical": "retail",
            "send_to": this.gaAddsId /* ID Google Ads/conversion label*/
        }

        this.googleTagCall(result, "addGoogleTagAddsDetailProduct", "page_view")
    }

    // objednavka kosik 1 az 3 Objednávka
    public addGoogleTagAddsCart(step: number): void {
        //gtag('event', 'page_view', {'ecomm_prodid': ['po-123456', 123456, 'po-123456'], /*ID položek*/ 'ecomm_pagetype': 'cart', 'ecomm_totalvalue': 149.99, /* celková cena*/ "google_business_vertical": "retail","send_to": "AW-953774234/PbLfCND1qm4QmuHlxgM" /* ID Google Ads/conversion label*/});
        if (!this.isGaAdds) {
            return;
        }

        let items: Array<string> = [];
        this.cartSvc.cart.cartContent.forEach(x => {
            if (x.productDetail) {
                items.push(x.productDetail.code);
            }
        })

        // Chybi mi tu step. Ale kdo ja jsem abych poucoval nejakyho marketaka. Tady jsem robot:)
        let result = {
            'ecomm_prodid': items, /*ID položek*/
            'ecomm_pagetype': 'cart',
            'ecomm_totalvalue': this.cartSvc.cart.priceTotalWithOutVat, /* celková cena*/
            "google_business_vertical": "retail",
            "send_to": this.gaAddsId /* ID Google Ads/conversion label*/
        }

        this.googleTagCall(result, "addGoogleTagAddsCart" + step, "page_view")
    }


    //Potvrzení objednávky
    public addGoogleTagAddsOrderPurchase(orderHeader: OrderHeaderSelector, orderItems: OrderItemSelector[]): void {
        //gtag('event', 'page_view', {'ecomm_prodid': ['po-123456', 123456, 'po-123456'], /*ID položek*/ 'ecomm_pagetype': 'purchase','ecomm_totalvalue': 149.99, /* celková cena*/"google_business_vertical": "retail","send_to": "AW-953774234/PbLfCND1qm4QmuHlxgM" /* ID Google Ads/conversion label*/});
        if (!this.isGaAdds) {
            return;
        }

        let items: Array<string> = [];
        orderItems.forEach(x => {
            if (x.typeItem == "PRODUCT") {
                items.push(x.code);
            }
        })

        // Chybi mi tu step. Ale kdo ja jsem abych poucoval nejakyho marketaka. Tady jsem robot:)
        let result = {
            'ecomm_prodid': items, /*ID položek*/
            'ecomm_pagetype': 'purchase',
            'ecomm_totalvalue': orderHeader.priceSumWithoutVat, /* celková cena*/
            "google_business_vertical": "retail",
            "send_to": this.gaAddsId /* ID Google Ads/conversion label*/
        }

        this.googleTagCall(result, "addGoogleTagAddsOrderPurchase", "page_view")
    }

    //clanky
    public addGoogleTagAddsArticle(): void {
        //    gtag('event', 'page_view', { "ecomm_pagetype": "other", "send_to": "AW-953774234/PbLfCND1qm4QmuHlxgM" /* Google Ads/conversion label*/ });
        if (!this.isGaAdds) {
            return;
        }

        // Chybi mi tu step. Ale kdo ja jsem abych poucoval nejakyho marketaka. Tady jsem robot:)
        let result = {
            "ecomm_pagetype": "other",
            "send_to": this.gaAddsId /* ID Google Ads/conversion label*/
        }

        this.googleTagCall(result, "addGoogleTagAddsArticle", "page_view")
    }

    public addGoogleTagReservedAdvertisementClick(reservationID: number, productCode?: string): void {
        if (this.seSvc.rbpMetricsGaOn) {
            let result = {
                "reservation_id": reservationID,
                "send_to": [this.gaId]
            }

            this.googleTagCall(result, "addGoogleTagReservedAdvertisementClick", "reserved_advertisement_click")
        }

        if (this.seSvc.rbpMetricsLocOn) {
            this.rbpMetricSvc.addRbpClickLocal(reservationID, productCode);
        }
    }

    public addGoogleTagReservedAdvertisementVisit(reservationID: number, productCode?: string): void {
        if (this.seSvc.rbpMetricsGaOn) {
            let result = {
                "reservation_id": reservationID,
                "send_to": [this.gaId]
            }

            this.googleTagCall(result, "addGoogleTagReservedAdvertisementVisit", "reserved_advertisement_visit")
        }

        if (this.seSvc.rbpMetricsLocOn) {
            this.rbpMetricSvc.addRbpVisitLocal(reservationID, productCode);
        }
    }

    private googleTagCall(result: any, methodName: string, evenType: string) {

        if (this.debug) {
            console.log(methodName + " " + evenType, result);
        }

        (<any>window).gtag('event', evenType, result);
    }
}
import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent, merge, of } from 'rxjs';
import { delay, map, switchMap } from 'rxjs/operators';

@Directive({
    selector: '[delayed-hover]',
})
export class HoverDelayDirective implements OnInit, OnDestroy {
    @Input() delay = 1000;

    @Output('delayed-hover') hoverEvent = new EventEmitter();

    constructor(private readonly element: ElementRef) {}

    ngOnInit() {
        const hide$ = fromEvent(this.element.nativeElement, 'mouseleave').pipe(map(_ => false));
        const show$ = fromEvent(this.element.nativeElement, 'mouseenter').pipe(map(_ => true));

        merge(hide$, show$)
            .pipe(
                untilDestroyed(this),
                switchMap(show => {
                    if (!show) {
                        return of(false);
                    }
                    return of(true).pipe(delay(this.delay));
                })
            )
            .subscribe(show => {
                if (show) {
                    this.hoverEvent.emit(this.element);
                }
            });
    }

    ngOnDestroy() {}
}
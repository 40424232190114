import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class EmergencyModeService {

    private enabled: boolean = false;

    public get Enabled(): boolean {
        return this.enabled;
    };

    public set Enabled(value: boolean) {
        this.enabled = value;
    }

    constructor(
    ) {
    }

}

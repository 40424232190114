/**
 * Created by jan.hroch on 27.06.2017.
 */
import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation} from "@angular/core";
import {Culture, PageModeType, SearchSelector, Translatable} from "../../interfaces/general";
import {SettingsService} from "../../services/settings.service";
import {DataService} from "../../services/data.service";
import {ProductAmountAdapter} from "../../helpers/ProductAmountAdapter";
import {ProductSelector} from "../product/common";
import {CartItemSelectorBrief} from "../cart/common";
import {CartService} from "../cart/cart.service";
import {IProductAmountSettings} from "../product-amount/product-amount/product-amount.component";
import {StoreDeadlineService} from "../store-deadline/store-deadline.service";

declare let $: any;

@Component({
    selector: 'cmp-flex-suggest-list',
    templateUrl: '../../tpl/flex-suggest-list.html',
    styleUrls: ['../../assets/styles/2-components/whisperer.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FlexSuggestListComponent extends Translatable implements OnChanges {

    @Input() suggestResult: SearchSelector;
    @Output() queryHide: EventEmitter<boolean>;
    @Input() phrase: string;
    @Input() pageMode: PageModeType;

    imagePathPrefix: string;
    articlImagePathPrefix: string;
    culture: Culture;
    currencySymbol: string;

    public redirectUrl: string;

    constructor(
        dataSvc: DataService,
        seSvc: SettingsService,
        private cartSvc: CartService,
        private sdSvc: StoreDeadlineService
    ) {
        super(dataSvc, seSvc);

        this.imagePathPrefix = this.seSvc.settings.imageServerPathPrefix + '/fotocache/suggest/images/produkty/';
        this.articlImagePathPrefix = this.seSvc.settings.imageServerPathPrefix + '/fotocache/suggest/images/';
        this.queryHide = new EventEmitter();
        this.culture = this.seSvc.culture;
        this.currencySymbol = this.seSvc.settings.currencies[this.seSvc.currencyId];
    }

    linkClicked(): void {
        this.queryHide.emit(true);
    }

    onComponentClick(evt: any): void {
        evt.stopPropagation();
    }

    public GetProductAmountAdapter(product: ProductSelector): IProductAmountSettings {
        return new ProductAmountAdapter(product, this.cartSvc.addByPackage(product.variants[0].id));
    }

    public OnBuyButtonClick(productId: number, amount: number, unitsInPackage: number): void {
        //if (this.addingToCartInProgress) return;

        let cart: CartItemSelectorBrief = {
            count: amount * (this.cartSvc.addByPackage(productId) ? unitsInPackage : 1),
            productId: productId
        };

        this.cartSvc.addToCart(cart);

        const $jsSearch = $('.js-search');
        $jsSearch.val('');
        $jsSearch.attr('autofocus', 'autofocus');
        $jsSearch.focus();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes['pageMode']) {
            if (this.pageMode == 'eshop') {
                this.redirectUrl = '/eshop/vysledky-vyhledavani';
            } else {
                this.redirectUrl = '/vysledky-vyhledavani';
            }
        }
    }

    buyDisabled(item: ProductSelector): boolean {
        return !item.variants || !item.variants[0] || !item.variants[0].availability
            || (item.variants[0].availability.crossDock && this.sdSvc.blockCrossDockBuy)
    }
}

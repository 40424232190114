import {IProductPriceSelector} from "../modules/product/common";

export class ProductPriceUtils {

    private static ProductPriceAmountAscComparer(a: IProductPriceSelector, b: IProductPriceSelector): number {
        if (a.FromAmount > b.FromAmount) return -1;
        else if (a.FromAmount < b.FromAmount) return 1;
        else return 0;
    }

    private static ProductPriceAmountDescComparer(a: IProductPriceSelector, b: IProductPriceSelector): number {
        if (a.FromAmount > b.FromAmount) return 1;
        else if (a.FromAmount < b.FromAmount) return -1;
        else return 0;
    }

    private static ProductPriceAscComparer(a: IProductPriceSelector, b: IProductPriceSelector): number {
        if (a.PriceWithVat > b.PriceWithVat) return 1;
        else if (a.PriceWithVat < b.PriceWithVat) return -1;
        else return 0;
    }

    public static GetPrice(prices: IProductPriceSelector[], amount: number): IProductPriceSelector {
        if (prices && prices.length) {
            const price = prices.filter(x => x.FromAmount <= amount).sort(ProductPriceUtils.ProductPriceAmountAscComparer)[0];

            if (price == null) {
                return prices.sort(ProductPriceUtils.ProductPriceAmountDescComparer)[0];
            }

            return price;
        } else {
            return null;
        }
    }

    public static GetSmallestPrice(prices: IProductPriceSelector[]): IProductPriceSelector {
        if (prices) {
            return prices.sort(ProductPriceUtils.ProductPriceAscComparer)[0];
        }
        return null;
    }

}
export function jsonCompare(obj1: any, obj2: any): boolean {
    return JSON.stringify(obj1) == JSON.stringify(obj2);
}

export function jsonCopy<T>(obj: T): T {
    return JSON.parse(JSON.stringify(obj));
}

export function mathSum(array: any[], q: any = (c: any) => c, def: number = 0): number {
    return array.reduce((s, c) => {
        return s + q(c);
    }, def);
}

export function mathMin(array: any[], q: any = (c: any) => c, def: number = Infinity): number {
    return array.reduce((s, c) => {
        let qc = q(c);
        return s < qc ? s : qc;
    }, def);
}

export function mathMax(array: any[], q: any = (c: any) => c, def: number = -Infinity): number {
    return array.reduce((s, c) => {
        let qc = q(c);
        return s > qc ? s : qc;
    }, def);
}

export function findLastIndex<T>(array: T[], q: any = (c: T) => c): number {
    if (!array || !array.length)
        return -1;
    for(let i = array.length - 1; i >= 0; i--) {
        if (q(array[i]))
            return i;
    }
    return -1;
}

export function compareArrays(array1: any[], array2: any[]): boolean {
    let result = true;

    if (!array1 || !array2) {
        return false;
    }

    if (array1.length != array2.length)
        return false;

    if (array1 === array2)
        return true;

    array1.forEach(a1 => {
        result = result && !!array2.find(a2 => a1 === a2);
    });

    return result;
}

/**
 * Returns a null value if the two specified expressions are equal. Returns the first expression if the two expressions are not equal
 */
export function NullIf(expr1: any, expr2: any): any {
    return expr1 === expr2 ? null : expr1;
}

/**
 * Replaces NULL with the specified replacement value.
 * @check Is the expression to be checked for NULL.
 * @replacement Is the expression to be returned if check_expression is NULL.
 */
export function IsNull(check: any, replacement: any): any {
    return check === null ? replacement : check;
}


/**
 * Replaces NULL or Undefined with the specified replacement value.
 * @check Is the expression to be checked for NULL or Undefined.
 * @replacement Is the expression to be returned if check_expression is NULL.
 */
export function IsNotSet(check: any, replacement: any): any {
    return check === null || check === undefined ? replacement : check;
}
import {Component, Input, Output, ViewEncapsulation, EventEmitter} from "@angular/core";
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";
import {Translatable} from "../../interfaces/general";

@Component({
    selector: 'cmp-rating-star',
    templateUrl: '../../tpl/rating-star.html',
    styleUrls: ['../../assets/styles/2-components/rating.scss'],
    encapsulation: ViewEncapsulation.None
})
export class RatingStarComponent extends Translatable {
    @Input() maxRating: number = 5;
    @Input() currentRating: number = 0;
    @Output() currentRatingChange: EventEmitter<number> = new EventEmitter();

    constructor(dataSvc: DataService, seSvc: SettingsService) {
        super(dataSvc, seSvc);
    }

    ngOnInit(): void {
        this.currentRating = this.currentRating;
    }

    ngOnDestroy(): void {
    }

    changeRate(i: number): void {
        if (i == 0 && this.currentRating == 1)
            this.currentRating = i;
        else
            this.currentRating = i + 1;
        this.currentRatingChange.emit(this.currentRating);
    }
}

import {AfterViewInit, Component, ViewEncapsulation} from '@angular/core';
import {DataService} from "../../services/data.service";
import {
    PageModeType,
    Translatable, UserTokenLoginRequest, UserTokenLoginSelector
} from "../../interfaces/general";
import {SettingsService} from "../../services/settings.service";
import {DigestService} from "../../services/digest.service";
import {NavigationStart, Router} from "@angular/router";
import {loadFromLocalStorage, loadFromSession} from "../../helpers/cookie.helper";
import {filter, takeUntil} from 'rxjs/operators';
import {Subject} from "rxjs";
import {CartService} from "../cart/cart.service";
import {GeneralDialogService, MessageBoxButtons} from "../general-dialog/general-dialog.service";
import {AdultConfirmationService} from "../../services/adult-confirmation.service";
import {DeliverySettingsService} from "../../services/delivery-settings.service";
import {WishListService} from "../../services/wish-list-service";
import {HttpClient} from "@angular/common/http";
import {IdentityService} from "../../services/identity.service";

declare let moment: any;

declare let $: any;

@Component({
    selector: 'app',
    templateUrl: '../../tpl/app.html',
    styleUrls: ['../../assets/styles/layout.scss'],
    encapsulation: ViewEncapsulation.None
})

export class AppComponent extends Translatable implements AfterViewInit {

    pageMode: PageModeType = 'cms';
    showUpperBar: boolean = true;
    copyright: string;

    ngUnsubscribe: Subject<any> = new Subject<any>();

    constructor(
        public dataSvc: DataService,
        public seSvc: SettingsService,
        public digestSvc: DigestService,
        private router: Router,
        private wishListSvc: WishListService,
        private deliverySettSvc: DeliverySettingsService,
        public cartSvc: CartService,
        private dialogSvc: GeneralDialogService,
        private adultConfirmationSvc: AdultConfirmationService,
        private http: HttpClient,
        private idSvc: IdentityService
    ) {
        super(dataSvc, seSvc);

        this.RefreshCultureCssClass();

        this.router.events
            .pipe(
                filter((evt: any) => evt instanceof NavigationStart),
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe(res => {
                /**
                 * Ugly hack for not letting unauthorized users in on published DEV servers
                 */
                if (this.seSvc.settings.preAuth) {

                    if (res['url'].toLowerCase() != '/pa') {

                        let pa = this.seSvc.settings.preAuthToLocal ? loadFromLocalStorage('pa') : loadFromSession('pa');

                        let mt = moment();
                        let mtValidTo = pa ? moment(pa.validTo) : 0;

                        if (!pa || mt > mtValidTo) {
                            this.router.navigateByUrl('/pa').then(() => {
                            })
                        }
                    }
                }
                if (/registrace/.test(res['url']) && this.userLoggedIn) {
                    this.router.navigateByUrl('/').then(() => {
                    });
                }
                /**
                 * default title and description
                 */
                this.seSvc.settings.routesWithDefaultSeo.forEach(elem => {
                    if (elem.test(res['url'].toLowerCase())) {
                        this.setDefaultSeo();
                    }
                });

                if (!this.userLoggedIn || this.isCmsPage(res['url'])) {
                    this.pageMode = 'cms';
                } else {
                    this.pageMode = 'eshop';
                }
            });

        this.deliverySettSvc.DeliverySettingsChanged
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.wishListSvc.getWishLists();
                this.cartSvc.getCartContentForToken();
                this.idSvc.getCompanyIdentity();
            });
    }

    private RefreshCultureCssClass(): void {
        $('body').addClass(`lang-${this.seSvc.culture.cultureId}`);
    }

    ngOnInit(): void {

        if (this.userLoggedIn) {
            this.GetAdultnessInfo();
            this.GetUserData();
        }

        this.logoutForced.subscribe(() => {
            this.digestSvc.logOut();
        });
    }

    public GetUserData(): void {
        this.idSvc.GetUserData();
    }

    public GetAdultnessInfo(): void {

        this.adultConfirmationSvc
            .GetAdultnessInfo()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(adultConfirmationInfo => {

                if (adultConfirmationInfo.IsAdult === null) {

                    this.dialogSvc
                        .showMessageBox<string>(
                            this.sen['adult-confirmation-message-box-title'],
                            this.sen['adult-confirmation-message-box-text'],
                            [
                                MessageBoxButtons.Yes,
                                MessageBoxButtons.No
                            ],
                            'red_text'
                        )
                        .subscribe((res: string) => {
                            const isAdult = (res === MessageBoxButtons.Yes.value);

                            this.adultConfirmationSvc
                                .SetAdultnessInfo(isAdult)
                                .pipe(takeUntil(this.unsubscribe))
                                .subscribe(res2 => {
                                    console.log(res2);
                                });

                        });

                }

            });

    }

    ngAfterViewInit(): void {
        this.setDefaultSeo();
        this.readLoginToken();
    }

    readLoginToken(): void {
        let token: string;
        let params = window.location.search.substr(1).split('&');
        let tokenParam = params.find(q => q.startsWith('token='));
        token = tokenParam ? tokenParam.split('=')[1] : null;

        if (token){
            let pending: UserTokenLoginRequest = {
                token: token
            };
            this.http.post<UserTokenLoginSelector>('api/user/token', pending)
                .subscribe((res) => {
                    if (res) {
                        this.digestSvc.quietLogOut();
                        this.digestSvc.login(res.login, res.psw);
                    }
                });
        }
    }

    private setDefaultSeo(): void {
        let can = this.seSvc.settings.cultures.filter(f => f.cultureId === this.seSvc.culture.cultureId)[0].cultureBoundDomain;
        this.dataSvc.setPageHead(this.seSvc.settings.shopSeo.ShopDescription, can);
        this.dataSvc.setTitle(this.seSvc.settings.shopSeo.ShopTitle);
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        super.ngOnDestroy();
    }

    onNoArticles(): void {
        this.showUpperBar = false;
    }

    private isCmsPage(url: string): boolean {
        return url.toLowerCase() == '/pa' || /^\/clanek\//.test(url) || /^\/$/.test(url) || /^\/vysledky-vyhledavani(\?q=.*)?$/.test(url)
    }
}

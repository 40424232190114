import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest, HttpResponse
} from "@angular/common/http";
import {Observable, EMPTY} from "rxjs";
import {Injectable} from "@angular/core";
import {IntegrationService} from "./integration.service";
import {HttpRegionalSettings} from "../interfaces/general";
import {CredentialStorage} from "../services/credential-storage.service";
import {CartTokenService} from "../modules/cart/cart-token.service";
import {tap} from 'rxjs/operators';
import {Router} from "@angular/router";
import {ClientCacheTokenService} from "../services/client-cache-token.service";
import {DeliverySettingsService} from "../services/delivery-settings.service";
import {EmergencyModeService} from "../services/emergency-mode.service";
import {GeneralDialogService, MessageBoxButtons} from "../modules/general-dialog/general-dialog.service";
import {Sentences} from "../helpers/sentences";


@Injectable()
export class MainInterceptor implements HttpInterceptor {

    private settings: HttpRegionalSettings;

    constructor(
        private iSvc: IntegrationService,
        private cartTokenSvc: CartTokenService,
        private router: Router,
        private cacheTokenSvc: ClientCacheTokenService,
        private dsSvc: DeliverySettingsService,
        private emergencyModeSvc: EmergencyModeService,
        private dialogSvc: GeneralDialogService
    ) {
        this.settings = this.iSvc.httpRegional;
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (!/api\/preauth/.test(req.url) && !/api\/lang/.test(req.url) && !this.settings.comAllowed) return EMPTY;

        this.settings = this.iSvc.httpRegional;
        let newUrl;

        const authHeadersBody: string = CredentialStorage.getStoredJwTokenBody();
        const clientCacheToken: string = this.cacheTokenSvc.GetToken();

        if (req.method === 'GET') {
            newUrl = /\?/.test(req.url)
                ? `${req.url}&cu=${this.settings.cultureId}&cy=${this.settings.currencyId}&lin=${CredentialStorage.userLoggedIn}&cct=${clientCacheToken}&cmp=${this.dsSvc.companyId}&pck=${this.dsSvc.pickUpTypeId}&del=${this.dsSvc.deliveryDateId}`
                : `${req.url}?cu=${this.settings.cultureId}&cy=${this.settings.currencyId}&lin=${CredentialStorage.userLoggedIn}&cct=${clientCacheToken}&cmp=${this.dsSvc.companyId}&pck=${this.dsSvc.pickUpTypeId}&del=${this.dsSvc.deliveryDateId}`;
        } else newUrl = req.url;

        let cartToken = this.cartTokenSvc.getCartToken();

        let duplicate;
        if (authHeadersBody) {
            duplicate = req.clone({
                headers: req.headers
                    .append('F-CC', `cu:${this.settings.cultureId},cy:${this.settings.currencyId}`)
                    .append('F-DS', `companyId:${this.dsSvc.companyId},pickUpTypeId:${this.dsSvc.pickUpTypeId},deliveryDateId:${this.dsSvc.deliveryDateId}`) // DS - Delivery Settings
                    .append('Authorization', authHeadersBody)
                    .append('cart-token', cartToken || ''),
                url: newUrl
            });
        } else {
            duplicate = req.clone({
                headers: req.headers
                    .append('F-CC', `cu:${this.settings.cultureId},cy:${this.settings.currencyId}`)
                    .append('cart-token', cartToken || ''),
                url: newUrl
            });
        }

        return next.handle(duplicate)
            .pipe(
                tap((event: HttpEvent<any>) => {
                        if (event instanceof HttpResponse) {
                            const isEmergencyModeEnabled: boolean = event.headers.get('IsEmergencyModeEnabled') == '1';
                            this.CheckEmergencyMode(isEmergencyModeEnabled);
                        }
                    },
                    (err: any) => {
                        /**
                         * server side kick-off scenario
                         * this is a safety catch when for some reason the client did not react to token expiration
                         * but the server rejected the token and throws 403 back
                         */
                        if (!/api\/login/.test(req.url) && err instanceof HttpErrorResponse && (<HttpErrorResponse>err).status === 403) {
                            CredentialStorage.removeAuthInfo();

                            this.router.navigateByUrl('/')
                                .then(() => {
                                    location.reload();
                                });
                        }
                    })
            );
    }

    private CheckEmergencyMode(isEmergencyModeEnabled: boolean): void {
        if (Sentences.sen != null) {
            if (CredentialStorage.userLoggedIn && this.emergencyModeSvc.Enabled === true && isEmergencyModeEnabled === false) {
                this.dialogSvc.showMessageBox(
                    Sentences.sen['price-list-changed-dialog-title'],
                    Sentences.sen['price-list-changed-dialog-text'],
                    [MessageBoxButtons.OK]
                ).subscribe(() => {
                    location.reload();
                });
            }

            this.emergencyModeSvc.Enabled = isEmergencyModeEnabled;
        }
    }
}
import {Component, ElementRef, HostListener, Input, Renderer2, ViewEncapsulation, ViewChildren, QueryList} from "@angular/core";
import {CategoryItemSelector, Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";
import {filter, takeUntil} from "rxjs/operators";
import {MenuBehavior} from "./common";
import {MenuService} from "../../services/menu.service";
import {NavigationEnd, Router, NavigationExtras} from "@angular/router";
import {DeliverySettingsService} from "../../services/delivery-settings.service";
import {CategoryService} from "../category/services/category.service";
import {HttpClient} from "@angular/common/http";
import { ActionCategoryFilterComponent } from '../category/action-category-filter.component';
import {GoogleTagService} from "../../services/google-tag.service";

declare let $: any;
declare let StringView: any;

@Component({
    selector: 'cmp-menu',
    templateUrl: '../../tpl/menu.html',
    styleUrls: ['../../assets/styles/3-layout/menu.scss'],
    encapsulation: ViewEncapsulation.None
})

export class MenuComponent extends Translatable {

    @Input() menuBehavior: MenuBehavior = MenuBehavior.default;

    @ViewChildren(ActionCategoryFilterComponent) actionCategoryFilterComponent: QueryList<ActionCategoryFilterComponent>;

    private menuActive: boolean = false;
    private menuActive2: boolean = false;

    categories: Array<CategoryItemSelector>;
    menuImagePathPrefix: string;

    private _js_menu_element: any;
    private get js_menu_element(): any {
        if (!this._js_menu_element) {
            this._js_menu_element = this.elementRef.nativeElement.querySelector('.js-menu');
        }

        return this._js_menu_element;
    }

    private _menu_element: any;
    private get menu_element(): any {
        if (!this._menu_element) {
            this._menu_element = this.elementRef.nativeElement;
        }

        return this._menu_element;
    }

    constructor(
        public dataSvc: DataService,
        public seSvc: SettingsService,
        private elementRef: ElementRef,
        private renderer: Renderer2,
        private menuSvc: MenuService,
        private router: Router,
        private dsSvc: DeliverySettingsService,
        private catSvc: CategoryService,
        private http: HttpClient,
        private gtSvc: GoogleTagService
    ) {
        super(dataSvc, seSvc);
        this.menuImagePathPrefix = this.seSvc.settings.imageServerPathPrefix + '/fotocache/cattop/images/';

        this.menuSvc.toggleMenu
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.toggleMenu();
            });

        this.router.events
            .pipe(filter(evt => evt instanceof NavigationEnd))
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((_res: any) => {
                this.Hide();
            });

        this.dsSvc
            .DeliverySettingsChanged
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.getCategories();
            });
    }

    ngOnInit(): void {
        if (this.dsSvc.settings && !this.categories)//o�et�en� probl�mu, �e to �vodn� nastaven� settings prob�hne d��ve, ne� se stihne nastavit subciption v mistn�m konstruktoru. Druh� ��st podm�nky zas o�et�uje, aby se kategorie nestahovaly, pokud by p�eci jen subsribtion "to stihlo"
            this.getCategories();
    }

    private categoryIconReservationVisit(category: CategoryItemSelector): void {
        if(category.subcategories) {
            category.subcategories.forEach((subcategory) => {
                if(subcategory.reservationID) {
                    this.gtSvc.addGoogleTagReservedAdvertisementVisit(subcategory.reservationID);
                }
            });
        }
    }

    openMenu(event: any, category: CategoryItemSelector):void {
        event.nativeElement.classList.add('menu__item--active');
        this.categoryIconReservationVisit(category);
    }
    closeMenu(event: any):void {
        event.target.className = event.target.className.replace('menu__item--active', '');
    }

    get active(): boolean {
        return this.menuActive2;
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    public categoryIconReservationClick(category: CategoryItemSelector): void {
        if(category && category.reservationID) {
            this.gtSvc.addGoogleTagReservedAdvertisementClick(category.reservationID);
        }
    }

    private getCategories(): void {
        this.catSvc.GetRootCategories()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((res) => {
                this.categories = res;
                //this.getAllTreeLazy();
                this.rootSubcategories();

            });
    }

    rootCategoryMouseEnter(catId: number): void {
        const ofInterest = this.categories.filter(f => f.id === catId)[0];
        if (ofInterest.subcategories) return;
        // getRootCategorySubTree3  - posila uroven 3
        this.http.get<CategoryItemSelector[]>(`api/category/getRootCategorySubTree3/${catId}`)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((res) => {
                for (let i = 0; i < this.categories.length; i++) {
                    if (this.categories[i].id === catId) {
                        this.categories[i].subcategories = res;
                        break;
                    }
                }
            });
    }

    rootSubcategories(): void {
        const catIds = this.categories.map(f => f.id);
        const base64 = new StringView(JSON.stringify(catIds)).toBase64();
        this.http.get<CategoryItemSelector[]>(`api/category/getRootCategorySubTree3All?catIds=${base64}`)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((res) => {
                for (let i = 0; i < this.categories.length; i++) {
                    let subCats = res.filter(r => r.parentId == this.categories[i].id);
                    this.categories[i].subcategories = subCats;
                }
                if (this.actionCategoryFilterComponent && this.actionCategoryFilterComponent.first)
                    this.actionCategoryFilterComponent.first.categorySelected.subscribe(() => {
                        let cat = this.actionCategoryFilterComponent.first.category;
                        let objToSend: NavigationExtras = {
                            queryParams: {
                                preselectCategoryId: this.actionCategoryFilterComponent.first.selectedCategoryId
                            }
                        };
                        this.router.navigate(['/c', cat.id, cat.seoUrl], objToSend);
                    });
            });
    }

    hideMenu(): void {
        $('.menu').removeClass('display-block');
    }

    toggleMenu(): void {
        if (!this.menuActive) {
            this.Show();
        } else {
            this.Hide();
        }
    }

    private Show(): void {
        if (this.menu_element) {
            this.renderer.addClass(this.menu_element, "menu--active");
        }
        this.menuActive = true;
        $('.responsive-menu').addClass('responsive-menu--active');
    }

    private Hide(): void {
        if (this.menu_element) {
            this.renderer.removeClass(this.menu_element, "menu--active");
        }
        this.menuActive = false;
        $('.responsive-menu').removeClass('responsive-menu--active');
    }


    @HostListener('document:click', ['$event'])
    handleClick(ev: MouseEvent) {
        if (this.menuBehavior == MenuBehavior.openAndFixedOnIndex && this.dataSvc.pageType == 'index') {
            return;
        }
        if (this.js_menu_element) {
            this.renderer.removeClass(this.js_menu_element, 'menu--active');
        }

        const $target = $(ev.target);
        if ($target.closest('cmp-menu, .responsive-menu, .menu').length === 0) {
            this.Hide();
        }

    }

    @HostListener('document:keydown.escape', ['$event'])
    onKeydownHandler(/*event: KeyboardEvent*/) {
        if (this.menuBehavior == MenuBehavior.openAndFixedOnIndex && this.dataSvc.pageType == 'index') {
            return;
        }
        if (this.js_menu_element) {
            this.renderer.removeClass(this.js_menu_element, 'menu--active');
        }

        this.Hide();
    }

    toggleActive(event: any) {
        event.stopPropagation();

        if (this.js_menu_element) {
            if (this.js_menu_element.classList.contains('menu--active')) {
                this.renderer.removeClass(this.js_menu_element, 'menu--active');
            } else {
                this.renderer.addClass(this.js_menu_element, 'menu--active');
            }
        }

    }

    setItemStorage(key: string, value: string) {
        sessionStorage.setItem(key,value);
    }
}

import {Component, ViewEncapsulation} from '@angular/core';
import {Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";
import {IdentityService} from "../../services/identity.service";

@Component({
    selector: 'cmp-notify-stripe-company',
    templateUrl: '../../tpl/notify-stripe-company.html',
    styleUrls: ['../../assets/styles/2-components/notify-stripe.scss'],
    encapsulation: ViewEncapsulation.None
})

export class NotifyStripeCompanyComponent extends Translatable {

    hideMessage: boolean = false;

    constructor(public dataSvc: DataService, seSvc: SettingsService, public idSvc: IdentityService) {
        super(dataSvc, seSvc);
    }

    ngOnInit(): void {
        this.idSvc.getCompanyIdentity();
    }

    ngOnDestroy(): void {
    }
}
import {Component} from "@angular/core";
import {Translatable} from "../../interfaces/general";
import {SettingsService} from "../../services/settings.service";
import {DataService} from "../../services/data.service";
import {CartItemSelectorFull, UnitType} from "../cart/common";
import {Subject} from "rxjs";
import {GeneralDialogConfig} from "../general-dialog/general-dialog-config";
import {GeneralDialogRef} from "../general-dialog/common";
import {CartDialogConfig} from "./common";
import {PriceModeSwitcherService} from "../price-mode-switcher/services/price-mode-switcher.service";
import { IProductAvailability } from "../product-availability/services/product-availability.service";
import { HttpClient } from "@angular/common/http";


@Component({
    selector: 'cmp-cart-change-modal',
    templateUrl: '../../tpl/cart-change-modal.html'
})

export class CartChangeModalComponent extends Translatable {

    public UnitType = UnitType;

    cartItem: CartItemSelectorFull;
    productImagePathPrefix: string;
    timeoutId: any;
    timeOutDelay: number = 0;
    decimalPattern: string = '1.0-0';
    ngUnsubscribe: Subject<any> = new Subject<any>();
    currencySymbol: string;
    availability: IProductAvailability;
    checkCartLimitMessage: string = null;

    constructor(
        public dataSvc: DataService,
        public seSvc: SettingsService,
        private config: GeneralDialogConfig<CartDialogConfig>,
        private dialogRef: GeneralDialogRef,
        public priceModeSvc: PriceModeSwitcherService,
        private http: HttpClient
    ) {
        super(dataSvc, seSvc);

        if (this.config && this.config.data) {
            this.cartItem = this.config.data.cartItem;

            this.availability = this.cartItem.productDetail.productAvailability;
        }

        this.currencySymbol = this.seSvc.settings.currencies[this.seSvc.currencyId];
        this.productImagePathPrefix = seSvc.settings.imageServerPathPrefix + '/fotocache/basket/images/produkty/';
        this.decimalPattern = `1.${this.seSvc.settings.decimalSettings.ProductBox}-${this.seSvc.settings.decimalSettings.Basket}`;

        if (this.timeOutDelay > 0) {
            if (this.timeoutId) clearTimeout(this.timeoutId);
            this.timeoutId = setTimeout(() => {
                this.closeCartAddDialog();
            }, this.timeOutDelay);
        }
        this.http.get<string>('api/cart/checkCartLimit').subscribe((res) => { this.checkCartLimitMessage = res;})
    }

    closeCartAddDialog(): void {
        this.dialogRef.close();
        this.cartItem = null;
    }

    cancelCartAdd(): void {
        this.dialogRef.close(this.cartItem);
        this.cartItem = null;
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        super.ngOnDestroy();
    }
}

import {Pipe, PipeTransform} from "@angular/core";
import {ProductDetailModel, ProductSelector, ProductVariantDetailSelector} from "../product/common";
import {IProductAmountSettings} from "./product-amount/product-amount.component";
import {ProductAmountAdapter} from "../../helpers/ProductAmountAdapter";

@Pipe({name: 'ProductAmount'})
export class ProductAmountPipe implements PipeTransform {

    transform(product: ProductSelector | ProductVariantDetailSelector | ProductDetailModel, isSizePackage:boolean): IProductAmountSettings {
        if (!product)
            return null;

        if ((<ProductSelector>product).variants)
            return new ProductAmountAdapter(<ProductSelector>product, isSizePackage);


        return ProductAmountAdapter.FromProductVariantDetailSelector(<ProductVariantDetailSelector>product, isSizePackage);
    }

}
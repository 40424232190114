import {ProductCartSelector, ProductVariantDetailSelector} from "../product/common";
import {AddressSelector, CompanySelector, UserSelector} from "../address/common";
import {UnitSelector} from "../../interfaces/general";
import {StoredCartProductSelector} from "../saved-carts/common";
import {ICartProductListDialogItem} from "../cart-product-list-dialog/interfaces/ICartProductListDialogItem";
import {IQuickOrderItem} from "../quick-order/common";

export enum UnitType {
    Original = 1,
    Pieces = 2
}

export interface CartItemSelectorBrief {
    id?: number;
    productId?: number;
    couponId?: number;
    count?: number;
    userId?: number;
    cartToken?: string;
    inserted?: Date;
    fromCart?: boolean;
    disableMessage?: boolean;
    unitType?: UnitType
}

export interface CartItemSelectorFull extends CartItemSelectorBrief {
    productDetail: ProductCartSelector;
    couponDetail: ICoupon;
    giftProductDetail?: GiftProductSelector;

    notBuyable: boolean;
    priceAfterCouponApplied: number;
    priceAfterCouponAppliedWithOutVAT: number;
    wasCouponApplied: boolean;
    priceWithVAT: number;
    priceWithOutVAT: number;
    priceSum: number;
    priceSumWithOutVAT: number;

    old?: CartItemSelectorFull;
    amount?: number;
}

export interface QuickOrderFilteredAddedProducts {
    notAddedIndices: number[];
    dialogItems: ICartProductListDialogItem[];
    quickOrderItems: IQuickOrderItem[];
}

export interface ICartItemGiftSelector extends CartItemSelectorBrief {

    giftProductDetail?: GiftProductSelector;
}


export interface ICartStatus {
    cartToken: string;
    NotLoggedProductsCnt: number;
    PrevLoggedProductsCnt: number;
}

export interface GiftSelector {
    id: number;
    name?: string;
    description?: string;
    thankYouText?: string;
    priceLevel?: number;
    isDisabled?: boolean;
    isUnique?: boolean;

    products?: GiftProductSelector[];
}

export interface GiftProductSelector {
    id: number;
    giftId: number;
    isFree: boolean;
    discountPercent: number;
    price: number;
    discountType: DiscountType;
    count: number;

    product: ProductVariantDetailSelector;
}

export enum DiscountType {
    isFree = "IsFree",
    discount = "Discount",
    price = "Price"
}

export interface ICoupon {
    currencyId: number;
    id: number;
    name: string;
    code: string;
    validTo?: Date;
    categoryIds: Array<number>;
    value: number;
    valueWithoutVat: number;
    vat: number;
    percent: number;
    validFrom?: Date;
    disabled: boolean;
    deleted: boolean;
    maxCountOfAppliances: number;
    appliancesCount: number;
    productId: number;
    productName: string;
    categoryNames: Array<string>;
    categoryIdPaths: Array<string>;
    minValue: number;
    isCombinational: boolean;
    restingValue: number;// used only with value-based coupons in cart during the process of calculating cart cart and applying coupons
    couponImpactOnOrderPrice: number;// this is the value that was subtracted from the original order price because of this coupon
    couponImpactOnOrderPriceWithoutVat: number;
    combinationsCodes: string[];
}


export interface DeliverySelector {
    id: number;
    name: string;
    description: string;
    priceWithOutVAT: number;
    priceWithVat: number;
    freeFrom: number;
    vat: number;
    logo: string;
    carrier: ShipmentCarrierSelector;
    carrierService: ShipmentCarrierServiceSelector;
}

export interface ShipmentCarrierSelector {
    id: number;
    code: string;
    name: string;
}

export interface ShipmentCarrierServiceSelector {
    id: number;
    carrierId: number;
    code: string;
    name: string;
}

export interface UlozenkaSelector {
    data: UlozenkaSelectorData
}

export interface UlozenkaSelectorData {
    register: UlozenkaSelectorDataRegister[]
}

export interface UlozenkaSelectorDataRegister {
    id: number;
    name: string;
    town: string;
    zip: string;
    house_number: string;
    street: string;
    gps: UlozenkaSelectorGps;
}

export interface UlozenkaSelectorGps {
    latitude: number;
    longitude: number;
}

export interface IShipmentExtraData {
    isMailPacket: boolean;  //balik na pobočku
    isMailHand: boolean;  //balik do ruky
    isPpl: boolean; //ppl
    isCp: boolean; // česká pošta
    isStoredRoom: boolean;  //zasilkovna
    isMailBalikovna: boolean; // balikovna
    isUlozenka: boolean; // ulozenka
}

export class BranchServices {
    name1: string;
    name2: string;
    city: string;
    street: string;
    zipcode: string;
    id: string;
    nameStreet: string;
    ExtraData?: string;
    deliveryId?: number;
    deliveryBranch: string;
    //gps for ulozenka
    gps: UlozenkaSelectorGps;
}

export class ParcelShopsRequest {
    query: string;
    cultureCode?: string;
    type?: string
}

export interface PaymentSelector {
    id: number;
    name: string;
    description?: string;
    priceWithVat?: number;
    priceWithOutVAT?: number;
    vat?: number;
    logo?: string;
    webPayType?: string;
}

export interface DeliveryPaymentRelationSelector {
    deliveryId?: number;
    paymentId?: number;
    isDefault?: boolean;
}

export interface IWebPaySelector {
    gatewayUrl?: string;
}


export interface IBarionErrorSelector {
    Message: string;
    ErrorCode: string;
}

export interface DeliveryPayment {
    Delivery?: DeliverySelector;
    Payment?: PaymentSelector;
}


export interface CartAdditionalInfo {
    note?: string;
    logToNewsletter?: boolean;
    maxStep: number;
    withCompany?: boolean;
    withDeliveryAddress?: boolean;
}


export interface ICartActionResult {

    success: boolean;
    errorCode: string;
    extra: object;
}


export interface CartSelector {
    cartActionResult: ICartActionResult;
    cartContent: CartItemSelectorFull[];
    delivery: DeliverySelector;
    payment: PaymentSelector;
    priceTotalWithVat: number;
    priceWithoutPackagingWithOutVat: number;
    priceTotalWithOutVat: number;
    priceWithoutPaymentAndDeliveryWithVat: number;
    priceWithoutPaymentAndDeliveryWithOutVat: number;
    priceProductsWithVat: number;
    priceProductsWithOutVat: number;
    itemsCount: number;
    deliveryFreeFrom: number;
    errorMessage_SenKey: string;
    errorMessage_Data?: any;
    cartToken: string;
    fromCart: boolean;
    orderDone: boolean;
    invoiceAddress: AddressSelector;
    deliveryAddress: AddressSelector;
    company: CompanySelector;
    user: UserSelector;
    vatInfo: VatInfoSelector[];

    //former additional:
    logToNewsletter: boolean,
    maxStep: number,
    withCompany: boolean,
    note: string,
    noteLength: number,
    noteMaxLength: number,
    extNumOrder?: string,
    withDeliveryAddress: boolean
    branchInfo?: BranchServices;
}

export interface OrderSelector {
    header: OrderHeaderSelector;
    items: OrderItemSelector[];
}

export interface OrderHeaderSelectorBrief {
    id: number;
    orderNumber: string;
    guid: string;
    orderDate: any;
    priceSumWithVat: number;
    priceSumWithoutVat: number;
    note: string;
    email: string;
    paymentType: string;
    shippingType: string;
    packageTrackingUrl: string;
    cultureCode: string;
    currencyCode: string;
}

export interface OrderHeaderSelector extends OrderHeaderSelectorBrief {
    userId: number;
    round: number;
    finalPrice: number;
    ic: string;
    dic: string;
    orderState: OrderStatusSelector;
    shippingTypePriceWithVat: number;
    shippingTypePriceWithoutVat: number;
    paymentTypePriceWithVat: number;
    paymentTypePriceWithoutVat: number;
    invoiceFirstName: string;
    invoiceLastName: string;
    invoiceCompany: string;
    invoiceStreet: string;
    invoiceCity: string;
    invoiceZipCode: string;
    invoiceCountry: string;
    deliveryFirstName: string;
    deliveryLastName: string;
    deliveryCompany: string;
    deliveryStreet: string;
    deliveryCity: string;
    deliveryZipCode: string;
    deliveryCountry: string;
    phone: string;
    sourceTypeId: number;
}

export interface OrderStatusSelector {
    key: number;
    value: string;
}

export interface OrderItemSelector {
    name: string;
    code: string;
    variant: string;
    typeItem: string;
    count: number;
    unit: UnitSelector;
    priceWithoutVat: number;
    priceWithVat: number;
    priceSumWithVat: number;
    priceSumWithoutVat: number;
    seoUrl: string;
    productId: number;
    imagePath: string;
    relatedItems: OrderItemSelector[];
}

export interface AddDeliveryPaymentRequest {
    deliveryId: number;
    paymentId: number;
}

export interface FreeDeliveryResult {
    [key: number]: number;
}

export enum CouponExtraKey {
    Product = "Product",
    Category = "Category",
    PriceLimit = "PriceLimit"
}

export enum CouponApplicability {
    CouponNotFound = 'CouponNotFound',
    CountOfApplicationWasReached = 'CountOfApplicationWasReached',
    CouponIsNotValidYet = 'CouponIsNotValidYet',
    CouponIsNotValidAlready = 'CouponIsNotValidAlready',
    CouponIsApplicableOnlyOnProducts = 'CouponIsApplicableOnlyOnProducts',
    CouponIsApplicableOnlyOnCategory = 'CouponIsApplicableOnlyOnCategory',
    CouponIsApplicableOnlyOnCategoryAndProducts = 'CouponIsApplicableOnlyOnCategoryAndProducts',
    CouponIsAppliedAlready = 'CouponIsAppliedAlready',
    CouponCannotBeCombined = 'CouponCannotBeCombined',
    OrderPriceLimitWasNotReached = 'OrderPriceLimitWasNotReached',
    OK = 'OK'
}

export interface GaOrderEvent {
    send_to: string;
    value: number;
    currency: string;
    transaction_id: string | number;
}

export interface GiftPriceSelector {
    priceLevel: number;
}

export interface StoredCartAdjustCartResult {
    Inserted?: StoredCartProductSelector[];
    Failed?: StoredCartProductSelector[];
    Error?: boolean;
    Cart?: CartSelector;
}

export interface ICartChangeRequest {
    productId: number;
    count: number;
    unitType?: UnitType
}

export interface VatInfoSelector {
    vat: number;
    priceWithVat: number;
    priceWithoutVat: number;
    priceVat: number;
}

export interface ICartCountsChange {
    success: boolean;
    changes: CartItemSelectorFull[];
    cart: CartSelector;
    oldCart?: CartSelector;
}

export interface QuickOrderProductEanSelector{
    id: number;
    ean: string;
}

export interface QuickOrderResultSelector {
    cart: CartSelector;
    productEans: QuickOrderProductEanSelector[];
}
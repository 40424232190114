/**
 * Module providing custom validation functionality
 * Do not introduce validators which are standard part of Angular please
 */
import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {CrnValidatorDirective} from "./crn-validator.directive";
import {VatValidatorDirective} from "./vat-validator.directive";
import {CompareValidatorDirective} from "./compare-validator.directive";
import {ValidatorMessage} from "./validation-msg";
import {ValidatorDetailMessage} from "./validation-detail-msg";

@NgModule({
    imports: [CommonModule, FormsModule],
    declarations: [
        CompareValidatorDirective,
        CrnValidatorDirective,
        VatValidatorDirective,
        ValidatorMessage,
        ValidatorDetailMessage
    ],
    exports: [
        CompareValidatorDirective,
        CrnValidatorDirective,
        VatValidatorDirective,
        ValidatorMessage,
        ValidatorDetailMessage
    ]
})

export class ValidationModule {}
import {Pipe, PipeTransform} from "@angular/core";

declare let moment: any;

// Vstupni hodnota je TimeSpan ve stylue 00:00 (bez datumu), s datumem pak pouzit DatePipe

@Pipe({name: 'time'})
export class TimePipe implements PipeTransform {

    constructor() {}

    transform(value: string, format: string): string {
        if(value && format) {
            const res: string = moment('1900-01-01 ' + value).format(format);
            return res;
        } else {
            return null;
        }
    }

}
import {Component, Input, ViewChild, ViewEncapsulation} from '@angular/core';
import {CategoryItemSelector, PageModeType, Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";
import {Subject} from "rxjs";
import {LoginComponent} from "../login/login.component";
import {LuigiSuggestComponent} from "../luigi-suggest/luigi-suggest.component";

@Component({
    selector: 'cmp-header',
    templateUrl: '../../tpl/app-header.html',
    styleUrls: ['../../assets/styles/3-layout/header.scss'],
    encapsulation: ViewEncapsulation.None
})

export class AppHeaderComponent extends Translatable {

    @Input() pageMode: PageModeType;

    @ViewChild(LoginComponent, { static: false }) loginComponent: LoginComponent;
    @ViewChild(LuigiSuggestComponent, { static: false }) suggest: LuigiSuggestComponent;

    categories: CategoryItemSelector[];

    responseMenuDisplay: boolean = false;

    ngUnsubscribe: Subject<any> = new Subject<any>();

    constructor(public dataSvc: DataService, seSvc: SettingsService) {
        super(dataSvc, seSvc);
    }

    ngOnInit(): void {

    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        super.ngOnDestroy();
    }

    setItemStorage(key: string, value: string) {
        sessionStorage.setItem(key,value);
    }
}
import {Component, ElementRef, HostListener, Input, Renderer2, ViewEncapsulation} from '@angular/core';
import {PageModeType, Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";
import {Subject} from "rxjs";

declare let $: any;

@Component({
    selector: 'cmp-header-language',
    templateUrl: '../../tpl/header-language.html',
    styleUrls: ['../../assets/styles/3-layout/header/language.scss'],
    encapsulation: ViewEncapsulation.None
})
export class HeaderLanguageComponent extends Translatable {

    @Input() pageMode: PageModeType;

    menuActive: boolean;

    ngUnsubscribe: Subject<any> = new Subject<any>();

    constructor(public dataSvc: DataService, seSvc: SettingsService, private elementRef: ElementRef,
                private renderer: Renderer2) {
        super(dataSvc, seSvc);


    }

    ngOnInit(): void {

    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        super.ngOnDestroy();
    }

    @HostListener('document:click', ['$event'])
    public OnClickOut(ev: any): void {
        const $target = $(ev.target);
        if ($target.closest('cmp-header-language').length === 0) {
            this.Hide();
        }
    }

    Toggle(): void {
        if (!this.menuActive) {
            this.Show();
        } else {
            this.Hide();
        }
    }

    private Show(): void {
        if (this.elementRef) {
            this.renderer.addClass(this.elementRef.nativeElement, "language--active");
        }
        this.menuActive = true;
    }

    private Hide(): void {
        if (this.elementRef) {
            this.renderer.removeClass(this.elementRef.nativeElement, "language--active");
        }
        this.menuActive = false;
    }
}
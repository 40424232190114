import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {AppFooterComponent} from "./app-footer.component";
import {FooterAboutComponent} from "./footer-about.component";
import {FooterSocialsComponent} from "./footer-socials.component";
import {SettingsSocialModule} from "../settings-social/settings-social.module";
import {ImageCacheBustModule} from "../../directives/image-cache-bust.module";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        SettingsSocialModule,
        ImageCacheBustModule
    ],
    declarations: [
        AppFooterComponent,
        FooterAboutComponent,
        FooterSocialsComponent
    ],
    exports: [AppFooterComponent],
    providers: [],
})

export class FooterModule {
}

import {Component, ElementRef, EventEmitter, HostListener, Output, Renderer2, ViewEncapsulation} from '@angular/core';
import {ArticleBaseSelector, Translatable} from "../../interfaces/general";
import {SettingsService} from "../../services/settings.service";
import {DataService} from "../../services/data.service";
import {filter, takeUntil} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {MenuService} from "../../services/menu.service";
import {NavigationEnd, Router} from "@angular/router";

declare let $: any;

@Component({
    templateUrl: '../../tpl/article-main-menu.html',
    selector: 'cmp-article-main-menu',
    styleUrls: ['../../assets/styles/3-layout/navigation.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ArticleMainMenuComponent extends Translatable {

    articles: ArticleBaseSelector[];
    @Output() noArticles: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        public dataSvc: DataService,
        seSvc: SettingsService,
        private http: HttpClient,
        private elementRef: ElementRef,
        private menuSvc: MenuService,
        private renderer: Renderer2,
        private router: Router
    ) {
        super(dataSvc, seSvc);

        this.menuSvc.toggleMenu
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.toggleMenu();
            });

        this.router.events
            .pipe(filter(evt => evt instanceof NavigationEnd))
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((_res: any) => {
                this.Hide();
            });
    }

    getArticles(): void {
        this.http.get<ArticleBaseSelector[]>(`api/article/getMainMenuLinks`)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(
                res => {
                    this.articles = res;
                    if (!this.articles || this.articles.length === 0) {
                        this.noArticles.emit(true);
                    }
                },
                () => {
                    this.noArticles.emit(true);
                }
            );
    }

    ngOnInit(): void {
        this.getArticles();
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    private _menu_element: any;
    private get menu_element(): any {
        if (!this._menu_element) {
            this._menu_element = this.elementRef.nativeElement;
        }

        return this._menu_element;
    }

    private menuActive: boolean = false;

    toggleMenu(): void {
        if (!this.menuActive) {
            this.Show();
        } else {
            this.Hide();
        }
    }

    private Show(): void {
        if (this.menu_element) {
            this.renderer.addClass(this.menu_element, "navigation--active");
        }
        this.menuActive = true;
        $('.responsive-menu').addClass('responsive-menu--active');
    }

    private Hide(): void {
        if (this.menu_element) {
            this.renderer.removeClass(this.menu_element, "navigation--active");
        }
        this.menuActive = false;
        $('.responsive-menu').removeClass('responsive-menu--active');
    }

    @HostListener('document:click', ['$event'])
    public OnClickOut(ev: any): void {
        const $target = $(ev.target);
        if ($target.closest('cmp-category-listing, .responsive-menu, .menu').length === 0) {
            this.Hide();
        }
    }
}

import {Component, ViewEncapsulation} from '@angular/core';
import {Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";
import {Subject} from "rxjs";
import {DomSanitizer} from "@angular/platform-browser";
import {HttpClient} from "@angular/common/http";
import {SettingItem} from "./common";
import {takeUntil} from "rxjs/operators";
import {loadFromSession, saveToSession} from "../../helpers/cookie.helper";

@Component({
    selector: 'cmp-notify-stripe',
    templateUrl: '../../tpl/notify-stripe.html',
    styleUrls: ['../../assets/styles/2-components/notify-stripe.scss'],
    encapsulation: ViewEncapsulation.None
})

export class NotifyStripeComponent extends Translatable {

    info: SettingItem;
    showNotification: boolean = false;
    notifyStripeDismissed: boolean = false;
    private ngUnsubscribe: Subject<any> = new Subject<any>();

    constructor(public dataSvc: DataService, public seSvc: SettingsService,
                private sanitizer: DomSanitizer, private http: HttpClient) {
        super(dataSvc, seSvc);
        this.notifyStripeDismissed = loadFromSession('notifyStripeDismissed');
    }

    ngOnInit(): void {
        if (!this.notifyStripeDismissed) {
            this.getData();
        }
    }

    hide(): void {
        this.showNotification = false;
        saveToSession('notifyStripeDismissed', true);
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        super.ngOnDestroy();
    }

    getData(): void {
        this.http.get<SettingItem[]>('api/settings/getNotifyStripe')
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(res => {
                this.showNotification = res && res.length > 0;
                if (this.showNotification) {
                    this.info = res[0];
                    if (this.info && this.info.value) {
                        this.showNotification = true;
                        this.info.value = this.sanitizer.bypassSecurityTrustHtml(<string>this.info.value);
                    } else {
                        this.showNotification = false;
                        this.info.value = null;
                    }
                }
                else {
                    this.showNotification = false;
                    this.info.value = null;
                }
            });
    }

}

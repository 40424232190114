import {EventEmitter, Injectable} from "@angular/core";
import {SearchRequest, SearchSelector} from "../interfaces/general";
import {Observable} from "rxjs";
import {ToQueryStringParam} from "../helpers/string.helper";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class SearchService {
    searchInputChanged: EventEmitter<string> = new EventEmitter<string>();

    fireSearchInputChanged(phrase: string): void {
        this.searchInputChanged.emit(phrase);
    }

    constructor(
        private http: HttpClient
    ) {
    }

    public SearchEshop(request: SearchRequest): Observable<SearchSelector> {
        return this.http.get<SearchSelector>(`api/search/eshop?qs=${ToQueryStringParam(request)}`);
    }
}
import {Pipe, PipeTransform} from "@angular/core";
import {IProductPriceSelector} from "../product/common";
import {ProductPriceUtils} from "../../helpers/ProductPriceUtils";


@Pipe({name: 'ProductPrice'})
export class ProductPricePipe implements PipeTransform {

    transform(prices: IProductPriceSelector[], amount: number): IProductPriceSelector {
        return ProductPriceUtils.GetPrice(prices, amount);
    }
}
import {Component, ViewEncapsulation} from '@angular/core';
import {IContact, Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";
import {Subject} from "rxjs";
import {IdentityService} from "../../services/identity.service";
import {ContactService} from "./services/contact.service";
import {takeUntil} from "rxjs/operators";

@Component({
    selector: 'cmp-footer-about',
    templateUrl: '../../tpl/footer-about.html',
    styleUrls: ['../../assets/styles/3-layout/footer/about.scss'],
    encapsulation: ViewEncapsulation.None
})

export class FooterAboutComponent extends Translatable {

    ngUnsubscribe: Subject<any> = new Subject<any>();
    public contact: IContact;
    logoPathPrefix: string;

    constructor(public dataSvc: DataService,
                seSvc: SettingsService,
                private idSvc: IdentityService,
                private contactSvc: ContactService) {
        super(dataSvc, seSvc);

        this.logoPathPrefix = this.seSvc.settings.imageServerPathPrefix + '/fotocache/images/';
    }

    ngOnInit(): void {
        this.contactSvc.GetContact()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(res => {
                this.contact = res;
            })
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        super.ngOnDestroy();
    }

    get logoPath(): string {
        return this.idSvc.logoSettings.logo;
    }
}
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PriceComponent} from "./price/price.component";
import { PriceAmountComponent } from './price-amount/price-amount.component';
import {ProductPricePipe} from "./price.pipe";

@NgModule({
    declarations: [
        PriceComponent,
        PriceAmountComponent,
        ProductPricePipe
    ],
    imports: [
        CommonModule
    ],
    exports: [
        PriceComponent,
        PriceAmountComponent,
        ProductPricePipe
    ]
})
export class PriceModule {
}

import {Component, OnInit} from '@angular/core';
import {Translatable} from "../../interfaces/general";
import {SettingsService} from "../../services/settings.service";
import {DataService} from "../../services/data.service";
import {GeneralDialogConfig} from "../general-dialog/general-dialog-config";
import {
    ICartImportedProductListDialogConfig
} from "../cart-product-list-dialog/interfaces/ICartProductListDialogConfig";
import {ICartProductListDialogItem} from "../cart-product-list-dialog/interfaces/ICartProductListDialogItem";
import {GeneralDialogRef} from "../general-dialog/common";
import {QuickOrderFilteredAddedProducts} from "../cart/common";

@Component({
    selector: 'app-cart-product-list-dialog',
    templateUrl: './cart-imported-products-dialog.html'
})
export class CartImportedProductsDialogComponent extends Translatable implements OnInit {

    public imageServerPathPrefix: string = null;
    public quickOrderProducts: QuickOrderFilteredAddedProducts = null;

    public senKey: string = 'order-top-order-dialog-title';

    constructor(
        seSvc: SettingsService,
        dataSvc: DataService,
        private config: GeneralDialogConfig<ICartImportedProductListDialogConfig>,
        private dialogRef: GeneralDialogRef
    ) {
        super(dataSvc, seSvc);
        this.imageServerPathPrefix = this.seSvc.settings.imageServerPathPrefix;
        if(this.config.data.CodeOfDialog ==='last'){
            this.senKey = 'order-last-order-dialog-title';
        }
        this.quickOrderProducts = this.config.data.QuickOrderProducts;
    }

    ngOnInit() {
    }

    public CloseDialog(): void {
        this.dialogRef.close(false);
    }

    public ConfirmDialog(): void {
        this.dialogRef.close(true);
    }

    public IsProductActive(product: ICartProductListDialogItem): boolean {
        return !!product.Price;
    }
}

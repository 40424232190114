export class DateHelper {
    public static toOADate(date: Date): number {
        return (+date - (+new Date(Date.UTC(1899, 11, 30)))) / (24 * 60 * 60 * 1000);
    }

    public static fromOADate(value: number): Date {
        return new Date((+new Date(Date.UTC(1899, 11, 30))) + value * (24 * 60 * 60 * 1000));
    }

    public static minutesInDay(date: Date): number {
        return date.getMinutes() + date.getHours() * 60;
    }

    public static AddDays(date: Date, dayCount: number): Date {
        const d: Date = new Date(date);
        d.setDate(date.getDate() + dayCount);
        return d;
    }

    public static ToLocaleDate(date: Date): Date {
        const d: Date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
        return d;
    }
}
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class GoogleMapService {

    private isAppended: boolean = false;

    constructor() {
    }

    public AppendToDOM(): Promise<boolean> {
        const p: Promise<boolean> = new Promise<boolean>((resolve) => {

            try {
                if (this.isAppended) {
                    resolve(true);
                } else {
                    const token = this.GetToken();

                    const script: any = document.createElement('script');
                    script.id = 'google-map-script';
                    script.src = `https://maps.google.com/maps/api/js?key=${token}`;
                    script.onload = () => {
                        console.log(`Nactena google mapa: '${token}'`);
                        resolve(true);
                    };
                    script.onerror = () => {
                        console.log(`Pri nacteni google mapy nastala chyba '${token}'`);
                        resolve(true);
                    };
                    const placeholder = document.getElementsByTagName('head')[0];
                    placeholder.appendChild(script);
                    this.isAppended = true;
                }
            } catch {
                resolve(true);
            }

        });

        return p;
    }

    private GetToken(): string {
        const isDev = location.host === 'localhost' || location.host === 'www.endorphinrepubliccz002.scdev.cz';

        if (isDev) {
            return 'AIzaSyB-voS0QnRkDt0v5An3PziPjTAbPpxCpG8';
        } else {
            return 'AIzaSyCcGeafwJHrKeD8Q1KY4IKWheggCXPulp4';
        }
    }

}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CartProductListDialogComponent} from './cart-product-list-dialog/cart-product-list-dialog.component';
import {PriceModule} from "../price/price.module";
import {RouterModule} from "@angular/router";
import {ImageCacheBustModule} from "../../directives/image-cache-bust.module";

@NgModule({
    declarations: [
        CartProductListDialogComponent
    ],
    imports: [
        CommonModule,
        PriceModule,
        RouterModule,
        ImageCacheBustModule
    ],
    exports: [
        CartProductListDialogComponent
    ],
    entryComponents: [
        CartProductListDialogComponent
    ]
})
export class CartProductListDialogModule {
}

export class Numeric {
    public static round(number: number, precision: number): number {
        let pow = Math.pow(10, precision);
        return (Math.round(number*pow)/pow);
    }

    public static parseFloatCulture(value: any, cultureId?: number): number | null {
        if (typeof(value) === 'number') { return value; }
        if (typeof(value) !== 'string') { return null; }

        if (value === '') {
            return null;
        }
        let res = NaN;
        switch (cultureId) {
            case 34:
                res = parseFloat(value.toString().replace(/[\s]/g,'').replace(/,/g, '.'));
                break;
            default:
                res = parseFloat(value);
                break;
        }

        if (Number.isNaN(res)) {
            return null;
        }

        return res;
    }

    public static toString(value: number, cultureId?: number): string {
        switch (cultureId) {
            case 34:
                let str = '' + value;
                str = str.replace(/\./g, ',');
                return str;
            default:
                return '' + value;
        }
    }

    public static isMultiple(cnt: number, factor: number): boolean {
        if (!factor) return true;
        return Math.round(cnt / factor) / (1 / factor) === cnt;
    }

    public static isNumber(n: any): boolean {
        return typeof(n) === 'number';
    }
}
/**
 * Module for full article page route.
 */
import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {BreadcrumbsModule} from "../breadcrumbs/breadcrumbs.module";
import {ArticleMainMenuComponent} from "./article-main-menu.component";
import {RouterModule} from "@angular/router";
import {ArticleService} from "./article.service";
import {ImageCacheBustModule} from "../../directives/image-cache-bust.module";
import {ArticleTopNewsComponent} from "./article-top-news.component";
import {ArticleListModule} from "./article-list.module";
import {ArticleBoxModule} from "../article-box/article-box.module";
import {GoogleTagService} from "../../services/google-tag.service";
import {PaginationModule} from "ngx-bootstrap/pagination";


@NgModule({
    imports: [
        BreadcrumbsModule,
        CommonModule,
        FormsModule,
        ImageCacheBustModule,
        RouterModule,
        PaginationModule.forRoot(),
        ArticleListModule,
        ArticleBoxModule
    ],
    declarations: [
        ArticleMainMenuComponent,
        ArticleTopNewsComponent
    ],
    exports: [
        ArticleMainMenuComponent,
        ArticleTopNewsComponent
    ],
    providers: [
        ArticleService,
        GoogleTagService
    ]
})

export class ArticleModule {
}

import {Component, Input} from '@angular/core';
import {ArticleBriefSelector, Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";
import {GoogleTagService} from "../../services/google-tag.service";

@Component({
    selector: 'cmp-article-box-list',
    templateUrl: '../../tpl/article-box-list.html'
})

export class ArticleBoxListComponent extends Translatable {

    @Input() type: string = '';
    @Input() articles: ArticleBriefSelector[];

    constructor(public dataSvc: DataService, seSvc: SettingsService,
                private gtSvc: GoogleTagService) {
        super(dataSvc, seSvc);
    }

    ngOnInit(): void {
    }

    onVisible(article: ArticleBriefSelector) {
        if(article.reservationID) {
            this.gtSvc.addGoogleTagReservedAdvertisementVisit(article.reservationID);
        }
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }
}
import {NgModule} from '@angular/core';
import {CropHtml} from "./cropHtml";
import {StockAvailability} from "./stockAvailability";
import {DecimalPipe} from "@angular/common";
import {ParamFormatPipe} from "./param-format.pipe";
import {StringFormatPipe} from "./string-format.pipe";
import {
    DomSanitizerSafeHtml2Pipe,
    DomSanitizerSafeHtmlPipe, DomSanitizerSafeResourceUrlPipe,
    DomSanitizerSafeStylePipe,
    DomSanitizerSafeUrlPipe
} from "./dom-sanitizer.pipes";
import {TimePipe} from "./time.pipe";

@NgModule({
    declarations: [
        CropHtml,
        StockAvailability,
        ParamFormatPipe,
        StringFormatPipe,
        DomSanitizerSafeStylePipe,
        DomSanitizerSafeHtmlPipe,
        DomSanitizerSafeHtml2Pipe,
        DomSanitizerSafeUrlPipe,
        DomSanitizerSafeResourceUrlPipe,
        TimePipe
    ],
    exports: [
        CropHtml,
        StockAvailability,
        ParamFormatPipe,
        StringFormatPipe,
        DomSanitizerSafeStylePipe,
        DomSanitizerSafeHtmlPipe,
        DomSanitizerSafeHtml2Pipe,
        DomSanitizerSafeUrlPipe,
        DomSanitizerSafeResourceUrlPipe,
        TimePipe
    ],
    providers: [
        DecimalPipe
    ]
})

export class CustomPipesModule {
}

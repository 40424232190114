import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable, Subject} from "rxjs";
import {StoreDeadlineSelector} from "./common";
import {DeliverySettingsService} from "../../services/delivery-settings.service";
import {DateHelper} from "../../helpers/date.helper";

@Injectable({
    providedIn: 'root'
})
export class StoreDeadlineService {

    public currentStoreDeadlines: StoreDeadlineSelector[];
    public showCrossDockFlag: boolean = false;
    public blockCrossDockBuy: boolean = false;
    private currentCompanyId: number;
    private currentDate: Date = new Date();
    private deadlineWarningTime: number = 15;
    private crossDockWarningTime: number = 30;

    public deadlinesLoaded: Subject<StoreDeadlineSelector[]> = new Subject<StoreDeadlineSelector[]>();

    //private ngStoreDeadlineUnsub: Subject<any> = new Subject<any>();

    constructor(private http: HttpClient, private dsSvc: DeliverySettingsService) {

        this.dsSvc.DeliverySettingsChanged
            .subscribe(() => {
                this.updateDeadlines();
            });

        setInterval(() => {
            this.updateDate(new Date());
        }, 5000);

        this.updateDeadlines();
    }

    private updateDate(date: Date): void {
        this.currentDate = date;

        let oaDate = Math.floor(DateHelper.toOADate(date));
        let diff = this.dsSvc.deliveryDateId - oaDate;
        this.showCrossDockFlag = diff == 1;

        this.blockCrossDockBuy = this.isAfterSelectedCrossDockDeadline();
    }

    public updateDeadlines(): Promise<StoreDeadlineSelector[]> {
        const p: Promise<StoreDeadlineSelector[]> = new Promise<StoreDeadlineSelector[]>((resolve, reject) => {

            let settings = this.dsSvc.settings;
            if (this.currentCompanyId != settings.companyId || !this.currentStoreDeadlines) {
                this.currentCompanyId = settings.companyId;

                if (settings.companyId) {
                    this.getStoreDeadline(settings.companyId)
                        .subscribe(res => {
                            this.currentStoreDeadlines = res;
                            resolve(res);
                            this.deadlinesLoaded.next(res);
                        }, () => {
                            reject();
                        });
                }
            }

        });

        return p;
    }

    getStoreDeadline(companyId: number): Observable<StoreDeadlineSelector[]> {
        return this.http.get<StoreDeadlineSelector[]>(`api/store/${companyId}/deadline`);
    }

    getDeadlineForDate(date: Date, deadLines: StoreDeadlineSelector[] = null): StoreDeadlineSelector {
        if (!date)
            return null;
        let dayOfWeek = this.getDayOfWeek(date);

        if (deadLines)
            return deadLines && deadLines.find(q => q.dayOfWeek == dayOfWeek);
        else
            return this.currentStoreDeadlines && this.currentStoreDeadlines.find(q => q.dayOfWeek == dayOfWeek);

    }

    getDeadlineForSelectedDate(): StoreDeadlineSelector {
        let deadline = this.getDeadlineForDate(DateHelper.fromOADate(this.dsSvc.deliveryDateId));
        return deadline;
    }

    getDeadlineForCurrentDate(): StoreDeadlineSelector {
        let deadline = this.getDeadlineForDate(this.currentDate);
        return deadline;
    }

    /**
     * Informace zda je zadaný čas po termínu k odeslání objednávky
     * @date datum a čas pro kontrolu
     * @minutes přepsání času kontroly touto hodnotou
     * @return true pokud po limitu, jinak vždy false
     */
    isAfterDeadline(date: Date, minutes?: number, deadlines: StoreDeadlineSelector[] = null): boolean {
        if (deadlines == null)
            deadlines = this.currentStoreDeadlines;

        let deadline = this.getDeadlineForDate(this.currentDate, deadlines);
        if (!date || !deadline)
            return false; // TODO tady musi byt true, potom upravit

        let diff = Math.floor(DateHelper.toOADate(date)) - Math.floor(DateHelper.toOADate(this.currentDate));
        minutes = minutes || DateHelper.minutesInDay(this.currentDate);
        return diff < 1 || diff === 1 && minutes >= deadline.deadlineMin;
    }

    /**
     * Informace zda je po termínu k odeslání objednávky
     */
    isAfterSelectedDeadline(): boolean {
        return this.isAfterDeadline(DateHelper.fromOADate(this.dsSvc.deliveryDateId));
    }

    /**
     * Informace zda je zadaný čas po termínu k odeslání objednávky s produkty z externího skladu
     * @date datum a čas pro kontrolu
     * @minutes přepsání času kontroly touto hodnotou
     * @return true pokud po limitu, jinak vždy false
     */
    isAfterCrossDockDeadline(date: Date, minutes?: number, deadlines: StoreDeadlineSelector[] = null): boolean {
        if (deadlines == null)
            deadlines = this.currentStoreDeadlines;

        let deadline = this.getDeadlineForDate(this.currentDate, deadlines);
        if (!date || !deadline)
            return false; // TODO tady musi byt true, potom upravit

        let diff = Math.floor(DateHelper.toOADate(date)) - Math.floor(DateHelper.toOADate(this.currentDate));
        minutes = minutes || DateHelper.minutesInDay(this.currentDate);
        return diff < 1 || diff === 1 && minutes >= deadline.crossDockMin;
    }

    /**
     * Informace zda je po termínu k odeslání objednávky s produkty z externího skladu
     */
    isAfterSelectedCrossDockDeadline(): boolean {
        return this.isAfterCrossDockDeadline(DateHelper.fromOADate(this.dsSvc.deliveryDateId));
    }

    /**
     * Informace zda je zadaný čas v okně 15min před termínem k odeslání objednávky
     * @date datum a čas pro kontrolu
     * @minutes přepsání času kontroly touto hodnotou
     * @return true pokud 15min před limitem, jinak vždy false
     */
    isBeforeDeadline(date: Date, minutes?: number): boolean {
        let deadline = this.getDeadlineForDate(this.currentDate);
        if (!date || !deadline)
            return false;

        let diff = Math.floor(DateHelper.toOADate(date)) - Math.floor(DateHelper.toOADate(this.currentDate));
        minutes = minutes || DateHelper.minutesInDay(this.currentDate);
        return diff === 1 && minutes >= deadline.deadlineMin - this.deadlineWarningTime;
    }

    /**
     * Informace zda je aktuální čas v okně 15min před termínem k odeslání objednávky
     */
    isBeforeSelectedDeadline(): boolean {
        return this.isBeforeDeadline(DateHelper.fromOADate(this.dsSvc.deliveryDateId));
    }

    /**
     * Informace zda je zadaný čas v okně 30min před termínem k odeslání objednávky z externího skladu
     * @date datum a čas pro kontrolu
     * @minutes přepsání času kontroly touto hodnotou
     * @return true pokud 15min před limitem, jinak vždy false
     */
    isBeforeCrossDockDeadline(date: Date, minutes?: number): boolean {
        let deadline = this.getDeadlineForDate(this.currentDate);
        if (!date || !deadline)
            return false;

        let diff = Math.floor(DateHelper.toOADate(date)) - Math.floor(DateHelper.toOADate(this.currentDate));
        minutes = minutes || DateHelper.minutesInDay(this.currentDate);
        return diff === 1 && minutes >= deadline.crossDockMin - this.crossDockWarningTime;
    }

    /**
     * Informace zda je aktuální čas v okně 30min před termínem k odeslání objednávky z externího skladu
     */
    isBeforeSelectedCrossDockDeadline(): boolean {
        return this.isBeforeCrossDockDeadline(DateHelper.fromOADate(this.dsSvc.deliveryDateId));
    }

    private getDayOfWeek(date: Date): number {
        let day = date.getDay();
        return day == 0 ? 7 : day;
    }
}
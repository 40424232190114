/**
 * Created by jiri.kopel on 06.03.2019.
 */
import {Component, ElementRef, Input, ViewChild, ViewEncapsulation} from "@angular/core";
import {SearchSelector, Translatable, SearchRequest, PageModeType} from "../../interfaces/general";
import {SettingsService} from "../../services/settings.service";
import {DataService} from "../../services/data.service";
import {HttpClient} from "@angular/common/http";
import {fromEvent, Subject} from "rxjs";
import {map, filter, debounceTime, switchMap, takeUntil, finalize} from "rxjs/operators";
import {NavigationStart, Router} from "@angular/router";
import {SearchService} from "../../services/search.service";
import {ToQueryStringParam} from "../../helpers/string.helper";
import {ArticleSearchService} from "../article-search-result/services/article-search.service";
import {IArticleSearchRequest} from "../article-search-result/interfaces/IArticleSearchRequest";

declare let $: any;

@Component({
    selector: 'cmp-flex-suggest',
    templateUrl: '../../tpl/flex-suggest.html',
    styleUrls: ['../../assets/styles/3-layout/header/search.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FlexSuggestComponent extends Translatable {

    @Input() pageMode: PageModeType;

    @ViewChild('phrase', { static: true }) phrase: ElementRef;

    searchBoxVisible: boolean = false;
    suggestVisible: boolean = false;
    suggestResult: SearchSelector;
    enterFired: Subject<any> = new Subject<any>();
    ngUnsubscribe: Subject<any> = new Subject<any>();

    constructor(
        dataSvc: DataService,
        seSvc: SettingsService,
        private http: HttpClient,
        private router: Router,
        private searchSvc: SearchService,
        private articleSearchSvc: ArticleSearchService
    ) {
        super(dataSvc, seSvc);

        this.router.events
            .pipe(
                filter((evt: any) => evt instanceof NavigationStart),
                takeUntil(this.unsubscribe)
            )
            .subscribe(() => {
                this.suggestVisible = false;
            });
    }

    ngAfterViewInit(): void {
        this.bindSuggest();
        addEventListener('click', () => {
            this.suggestVisible = false;
        });
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        this.enterFired.complete();
        super.ngOnDestroy();
    }

    public loading: boolean = false;

    private bindSuggest(): void {
        const searchBox = this.phrase.nativeElement;
        fromEvent(searchBox, 'input')
            .pipe(
                map((e: any) => e.target.value),
                filter(text => text.length > 2),
                debounceTime(333),
                switchMap((s: string) => {
                    this.loading = true;
                    return this.getSuggest(s)
                }),
                takeUntil(this.enterFired),
                takeUntil(this.ngUnsubscribe),
                finalize(() => {
                    this.loading = false;
                })
            )
            .subscribe((res: SearchSelector) => {
                this.suggestResult = res;
                this.suggestVisible = true;
                this.loading = false;
            }, () => {
                this.loading = false;
            });
    }

    searchKeyUp(evt: KeyboardEvent) {
        if (evt.key === 'Escape') {
            this.suggestVisible = false;
        }
    }

    search(phrase: string): void {
        if (phrase.length == 0) return;
        this.phrase.nativeElement.value = '';
        this.suggestVisible = false;
        this.router.navigateByUrl(`${this.pageMode === 'eshop' ? '/eshop' : ''}/vysledky-vyhledavani?q=${encodeURIComponent(phrase)}`)
    }

    searchKeyPress(evt: KeyboardEvent, phrase: string): void {
        this.searchSvc.fireSearchInputChanged(phrase);

        if (evt.key === 'Enter' || evt.key == 'NumpadEnter') {
            this.search(phrase);
            this.suggestVisible = false;
            this.enterFired.next();
            this.bindSuggest();
            return;
        }
    }

    onFormSubmit(): boolean {
        return false;
    }

    getSuggest(phrase: string): any {

        if (this.pageMode == 'eshop') {

            let request: SearchRequest = {
                phrase: phrase,
                pageIndex: 1,
                pageSize: 6,
                forSuggest: true,
                orderBy: 'rank',
                onlyAvailable: false
            };

            return this.http.get(`api/search/eshop?qs=${ToQueryStringParam(request)}`);

        } else {

            const request: IArticleSearchRequest = {
                Expression: phrase,
                ForSuggest: true,
                PageSize: 6,
                PageIndex: 1
            };
            return this.articleSearchSvc.Search(request)
                .pipe(
                    map(x => {
                        const ss: SearchSelector = {
                            articles: x.Items,
                            totalProductCount: null,
                            products: null,
                            categories: null,
                            parameters: null,
                            flags: null,
                            filterParameters: null
                        };
                        return ss;
                    })
                );

        }

    }

    public toggleSearch(): void {
        if (!this.searchBoxVisible) {
            $('.search').addClass("search--active");
            this.searchBoxVisible = true;
        }
        else {
            $('.search').removeClass("search--active");
            this.searchBoxVisible = false;
        }
    }

}

import {Component, Input} from "@angular/core";
import {Translatable} from "../../interfaces/general";
import {Subject} from "rxjs";
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";
import {HttpClient} from "@angular/common/http";
import {takeUntil} from "rxjs/operators";
import {SocialSelector} from "./common";

@Component({
    selector: 'cmp-settings-social',
    templateUrl: '../../tpl/settings-social.html'
})

export class SettingsSocialComponent extends Translatable {

    @Input() cssClass: string;

    data: SocialSelector;
    ngUnsubscribe: Subject<any> = new Subject<any>();

    constructor(dataSvc: DataService, public seSvc: SettingsService,
                private http: HttpClient) {
        super(dataSvc, seSvc);
    }

    ngOnInit(): void {
        this.getData();
    }

    private getData(): void {
        this.http.get<SocialSelector>(`api/settings/social`)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((res) => {
                this.data = res;
            });
    }
}
// Digest realm="FlexRealm", nonce="db34cb20d445e9639e1f36f55aa95376", qop="auth"
import {IUrl} from "../interfaces/general";
import {Router} from "@angular/router";
import {CurrencyPipe, DecimalPipe} from "@angular/common";

declare let StringView: any;

export class KeyValuePair {

    key: string;
    value: string;

    constructor (k: string, v: string) {
        this.key = k;
        this.value = v;
    }
}

export function parseMultiPartString(digest: string): Array<KeyValuePair> {

    digest = digest.replace(/Digest /i, '').replace(/\s*/g, '');

    let kvpArray = digest.split(',');
    let result: Array<KeyValuePair> = [];

    function fillResult(v:string, a:Array<KeyValuePair>) {
        let tmp = v.split('=');
        a.push(new KeyValuePair(tmp[0], tmp[1].replace(/"/g, '')))
    }

    kvpArray.forEach(v => fillResult(v, result));

    return result;
}

export function isRouteSecured(theRoute: string): boolean {
    return /^\/muj-ucet.*/.test(theRoute);
}

export function stripHtml(input: string): string {
    if(!input){
        return null;
    }
    const regex = /(<([^>]+)>)/ig;
    return input.replace(regex, '');
}

export function ToQueryStringParam<T>(obj: T) {
    return encodeURIComponent(new StringView(JSON.stringify(obj)).toBase64());
}

export function trim(input: string, char: string) {
    if (char === ']') char = '\\]';
    if (char === '\\') char = '\\\\';
    return input.replace(new RegExp(
        '^[' + char + ']+|[' + char + ']+$', 'g'
    ), '');
}

export function trimStart(input: string, char: string) {
    if (char === ']') char = '\\]';
    if (char === '\\') char = '\\\\';
    return input.replace(new RegExp(
        '^[' + char + ']+', 'g'
    ), '');
}

export function trimEnd(input: string, char: string) {
    if (char === ']') char = '\\]';
    if (char === '\\') char = '\\\\';
    return input.replace(new RegExp(
        '[' + char + ']+$', 'g'
    ), '');
}

export function getUrlParts(url: string): IUrl {
    const reg = /^(.+)\:\/\/(.+?)(\/.*?)?(?:\?(.*?))?(?:\#(.*?))?$/;
    let m = reg.exec(url);
    return m ? {
        schema: m[1] || '',
        domain: m[2] || '',
        path: m[3] || '',
        query: m[4] || '',
        hash: m[5] || ''
    } : null;
}

export function getRouteSegments(router: Router): string[] {
    let st: string[] = [];
    let segments = router.parseUrl(router.url).root.children['primary'].segments;
    for (let i = 0; i < segments.length; i++) {
        st[i] = segments.slice(0, i + 1).join('/');
    }
    return st;
}

/**
 * Formátování stringu daty. Data nesmí být zanořené.
 * Zaměňuje string "{abc}" za data.abc
 * @param template String který bude upraven.
 * @param data Data které budou vloženy do template.
 * @param způsoby formátování dat. Očekává se stejná struktura jako v data.
 */
export function stringFormat(locale: string, template: string, data: any, formats: any): string {
    try {
        let reg = /(?:{)(.*?)(?:})/g;

        let result = template.replace(reg, (_sub, args) => {
            let val = data && data[args];
            let format = formats && formats[args];
            if (format) {
                switch (format[0]) {
                    case 'number':
                        val = new DecimalPipe(locale).transform(val, format[1], format[2])
                        break;
                    case 'currency':
                        val = new CurrencyPipe(locale).transform(val, format[1], format[2], format[3], format[4])
                        break;
                }
            }
            return val;
        });

        return result
    } catch {
        return template;
    }
}
import {Component, Input, ViewEncapsulation} from "@angular/core";

@Component({
    selector: 'cmp-val-detail-msg',
    templateUrl: '../../tpl/validation-detail-msg.html',
    styleUrls: ['../../assets/styles/2-components/validation.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ValidatorDetailMessage {

    @Input() msg1: string;
    @Input() msg2: string;
    @Input() msg3: string;
    @Input() pcs: number;
    @Input() packages: number;

}
import {ICartProductListDialogItem} from "./ICartProductListDialogItem";
import {QuickOrderFilteredAddedProducts} from "../../cart/common";

export enum CartProductListDialogMode {
    Default = 0,
    OnlyProducts = 1
}

export interface ICartProductListDialogConfig {
    Products: ICartProductListDialogItem[];
    CodeOfDialog: string;
    Mode: CartProductListDialogMode
}

export interface ICartImportedProductListDialogConfig {
    QuickOrderProducts: QuickOrderFilteredAddedProducts;
    CodeOfDialog: string;
    Mode: CartProductListDialogMode;
}
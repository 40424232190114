import {Component, EventEmitter, HostListener, OnInit, ViewEncapsulation} from '@angular/core';
import {Translatable} from "../../../interfaces/general";
import {DataService} from "../../../services/data.service";
import {SettingsService} from "../../../services/settings.service";
import {HeaderDeliveryService} from "../services/header-delivery.service";
import {finalize, takeUntil} from "rxjs/operators";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ISelectBoxOptionDisablable} from "../../../helpers/ISelectBoxOption";
import {DeliverySettingsService} from "../../../services/delivery-settings.service";
import {FormUtils} from "../../../helpers/FormUtils";
import {StoreDeadlineService} from "../../store-deadline/store-deadline.service";
import {DateHelper} from "../../../helpers/date.helper";
import {StoreDeadlineSelector} from "../../store-deadline/common";
import {Enumerable} from "../../../helpers/EnumerableUtils";
import {IHeaderDeliveryRelation} from "../interfaces/IHeaderDeliveryRelation";
import {IHeaderCompanySelectBoxOptionDisablable} from "../interfaces/IHeaderCompanySelectBoxOptionDisablable";
import {GeneralDialogService, IMessageBoxConfig, MessageBoxButtons} from "../../general-dialog/general-dialog.service";
import {ScrollUtils} from "../../../helpers/ScrollUtils";
import {IHeaderDeliveryInfo} from "../interfaces/IHeaderDeliveryInfo";
import {IHeaderDeliveryHoliday} from "../interfaces/IHeaderDeliveryHoliday";
import {GeneralDialogMessageBoxComponent} from "../../general-dialog/general-dialog-message-box.component";
import {GeneralDialogConfig} from "../../general-dialog/general-dialog-config";
import {CredentialStorage} from "../../../services/credential-storage.service";

declare let $: any;

export interface IHeaderDeliveryCalendar {
    MinDate: Date;
    MaxDate: Date;
    DisabledDates: Date[];
    NonSelectableDates: Date[];
}

@Component({
    selector: 'cmp-header-delivery',
    templateUrl: './header-delivery.component.html',
    styleUrls: ['../../../assets/styles/3-layout/header/header-delivery.scss'],
    encapsulation: ViewEncapsulation.None
})
export class HeaderDeliveryComponent extends Translatable implements OnInit {

    public calendarLocalization: any = null;

    public dateInfo: IHeaderDeliveryCalendar = null;
    public holidayInfo: IHeaderDeliveryCalendar = null;
    public selectedDate: Date = null;

    public dateString: string = null;
    public companyName: string = null;

    public _IsDropDownVisible: boolean = false;

    public get IsDrowDownVisible(): boolean {
        return this._IsDropDownVisible;
    }

    public set IsDrowDownVisible(value: boolean) {
        this._IsDropDownVisible = value;
        if (value) {
            $('.overlay').addClass('overlay--active');
            this.dsSvc.IsOpenedDeliverySettings = true;
        } else {
            $('.overlay').removeClass('overlay--active');
            this.dsSvc.IsOpenedDeliverySettings = false;
        }
    }

    private deliveryInfo: IHeaderDeliveryInfo;
    public PickUpTypes: ISelectBoxOptionDisablable<number>[] = null;
    public DeliveryDates: ISelectBoxOptionDisablable<number>[] = null;
    public Relations: IHeaderDeliveryRelation[] = null;
    public Companies: IHeaderCompanySelectBoxOptionDisablable[] = null;
    public Holidays: IHeaderDeliveryHoliday[] = null;

    public Form: FormGroup = null;
    private currentTime: Date = new Date();

    constructor(
        dataSvc: DataService,
        seSvc: SettingsService,
        private headerDeliverySvc: HeaderDeliveryService,
        private fb: FormBuilder,
        private dsSvc: DeliverySettingsService,
        private sdSvc: StoreDeadlineService,
        private dialogSvc: GeneralDialogService
    ) {
        super(dataSvc, seSvc);

        this.CreateForm();
        this.UpdateDeliveryInfo().then(() => {
            if (!this.IsValid()) {
                this.IsDrowDownVisible = true;
                this.dataSvc.showOverlay = true;
            }
            this.showHolidayDialog();
        });

        this.calendarLocalization = this.seSvc.GetCalendarLocalization(this.seSvc.culture.cultureId);

        if (this.onlinePrices) {
            this.RefreshPriceList().subscribe(() => {
                this.EmitDeliverySettingsChanged();
            });
        }

        this.headerDeliverySvc
            .ShowDialogRequest
            .subscribe(() => {
                setTimeout(() => {
                    this.IsDrowDownVisible = true;
                    this.dataSvc.showOverlay = true;
                    ScrollUtils.ScrollUp();
                }, 0);
            });
    }

    ngOnInit() {
    }

    private RefreshDateInfo(): void {
        if (this.DeliveryDates && this.DeliveryDates.length) {
            const oaDates: number[] = this.DeliveryDates.map(x => x.Id);
            const oaHolidayDates: number[] = this.Holidays.map(x => x.Id);

            const minOADate: number = Math.min(...oaDates);
            const maxOADate: number = Math.max(...oaDates);

            const minHoliday: number = Math.min(...oaHolidayDates, ...oaDates);
            const maxHoliday: number = Math.max(...oaHolidayDates, ...oaDates);
            const diff: number = maxHoliday - minHoliday + 1;


            const disabledDates: Date[] = Enumerable.Range(minHoliday, diff).filter(x => {
                const isDisabled = !this.DeliveryDates
                    .map(option => option.Id)
                    .find(deliveryOADate => deliveryOADate == x && (this.dateIsSelectable(deliveryOADate) || this.dsSvc.deliveryDateId == null)
                        );

                return isDisabled;
            }).map(x => {
                const tmp: Date = DateHelper.fromOADate(x);
                return tmp;
            });

            const nonSelectable: Date[] = Enumerable.Range(minOADate, maxOADate - minOADate + 1).filter(x => {
                const isDisabled = !this.DeliveryDates
                    .map(option => option.Id)
                    .find(deliveryOADate => deliveryOADate == x && (this.dateIsSelectable(deliveryOADate) || this.dsSvc.deliveryDateId == null)
                    );
                return isDisabled;
            }).map(x => {
                const tmp: Date = DateHelper.fromOADate(x);
                return tmp;
            });

            let selectedDate = this.Form.value.DeliveryDate;
            let selectedOADate = Math.floor(DateHelper.toOADate(this.Form.value.DeliveryDate));
            let selectedDateDisabled = selectedOADate < minOADate || selectedOADate > maxOADate ||
                !!disabledDates.find(dd => dd == selectedDate) || this.Holidays.filter(x => {
                    let holiday = DateHelper.fromOADate(x.Id);
                    return holiday.getDate() == selectedDate.getDate() && holiday.getMonth() == selectedDate.getMonth() && holiday.getFullYear() == selectedDate.getFullYear();
                }).length > 0;



            this.dateInfo = {
                MinDate: DateHelper.fromOADate(minOADate),
                MaxDate: DateHelper.fromOADate(maxOADate),
                DisabledDates: disabledDates,
                NonSelectableDates:nonSelectable
            }
            this.holidayInfo = {
                MinDate: DateHelper.fromOADate(minHoliday),
                MaxDate: DateHelper.fromOADate(maxHoliday),
                DisabledDates: disabledDates,
                NonSelectableDates: nonSelectable
            }

            if (selectedDateDisabled) {
                this.selectedDate = this.dsSvc.deliveryDateId ? DateHelper.fromOADate(this.dsSvc.deliveryDateId) : null
                this.Form.patchValue({
                    DeliveryDate:  this.selectedDate
                });
            }
            else {
                this.selectedDate = selectedDate
            }
            this.SetTitle();
        } else {
            let now = new Date();
            this.dateInfo = {
                MinDate: now,
                MaxDate: now,
                DisabledDates: [now],
                NonSelectableDates: [now]
            }

            this.Form.patchValue({DeliveryDate: new Date()});
        }
    }

    private isValid: boolean = false;

    public RefreshValidity(): void {

        let day = this.selectedDate.getDate();
        let month = this.selectedDate.getMonth();
        let year = this.selectedDate.getFullYear();
        let selectedDate = {day:day,month:month,year:year};
        if(this.IsHoliday(selectedDate) || !this.IsSelectable(selectedDate)) {this.isValid = false; return;}

        const isValid: boolean = !!this.Relations.find(x => x.PickUpTypeId === this.PickUpTypeId && x.DateId === this.DeliveryDateId && x.CompanyId === this.CompanyId && x.StoreId === this.CompanyStoreId);
        this.isValid = isValid;
    }

    public IsValid(): boolean {
        return this.isValid;
    }

    public IsConfirmVisible(): boolean {
        const res: boolean = this.IsValid() && this.Form.dirty;
        return res;
    }

    private SetTitle(): void {
        if (this.dsSvc.deliveryDateId) {
            this.dateString = DateHelper.fromOADate(this.dsSvc.deliveryDateId).toLocaleDateString("cz-CS");
            let company = this.Companies.find(q => q.Id == this.dsSvc.companyId);
            this.companyName = company && company.Name;
        } else {
            this.dateString = null;
            this.companyName = null;
        }

        this.dsSvc.settings.dateString = this.dateString;
        this.dsSvc.settings.companyName = this.companyName;
    }

    public Reset(): void {
        if (this.Form.dirty) {
            const deliveryDate: Date = DateHelper.fromOADate(this.dsSvc.deliveryDateId);
            this.Form.patchValue({
                CompanyId: this.dsSvc.companyId,
                PickUpTypeId: this.dsSvc.pickUpTypeId,
                DeliveryDateId: DateHelper.fromOADate(this.dsSvc.deliveryDateId),
                DeliveryDate: deliveryDate
            }, {emitEvent: false, onlySelf: true});

            this.Holidays = this.getHolidaysOptions();
            this.DeliveryDates = this.getDatesOptions();
            this.PickUpTypes = this.getPickUpOptions();
            this.RefreshDateInfo();
            FormUtils.MakePristineGroup(this.Form);
            this.RefreshValidity();
        }
    }

    public Hide(): void {
        if (this.IsDrowDownVisible) {
            this.IsDrowDownVisible = false;
            this.dataSvc.showOverlay = false;
            this.Reset();
        }
    }

    private UpdateDeliveryInfo(): Promise<void> {

        const p: Promise<void> = new Promise<void>((resolve, reject) => {

            this.headerDeliverySvc
                .GetHeaderDeliveryInfo()
                .pipe(takeUntil(this.unsubscribe))
                .subscribe(res => {
                    this.deliveryInfo = res;
                    this.Relations = res.Relations || [];


                    this.Companies = this.getCompanyOptions();
                    this.Holidays = this.getHolidaysOptions();
                    this.DeliveryDates = this.getDatesOptions();
                    this.PickUpTypes = this.getPickUpOptions();


                    this.RefreshDateInfo();
                    this.RefreshValidity();

                    resolve();
                }, () => {
                    reject();
                });

        });

        return p;

    }

    private getCompanyOptions(): IHeaderCompanySelectBoxOptionDisablable[] {
        return this.deliveryInfo.Companies && this.deliveryInfo.Companies.map(x => <IHeaderCompanySelectBoxOptionDisablable>{
            Id: x.Id,
            Name: x.Name,
            StoreId: x.StoreId,
            Disabled: false
        });
    }

    private getDatesOptions(): ISelectBoxOptionDisablable<number>[] {
        const company = this.deliveryInfo.Companies.find(c => c.Id == this.CompanyId);

        if (!company) {
            return [];
        }

        const relations = this.deliveryInfo.DeliveryDates
            .filter(d => this.Relations.find(r => r.StoreId == company.StoreId && r.CompanyId == company.Id && r.DateId == d.Id))
            .map(x => <ISelectBoxOptionDisablable<number>>{Id: x.Id, Name: x.Name, Disabled: false});

        return relations;
    }

    private getHolidaysOptions(): IHeaderDeliveryHoliday[] {
        const company = this.deliveryInfo.Companies.find(c => c.Id == this.CompanyId);

        if (!company) {
            return [];
        }

        const relations = this.deliveryInfo.Holidays
            .filter(d =>  company.StoreId == d.StoreId);

        return relations;
    }

    private getPickUpOptions(): ISelectBoxOptionDisablable<number>[] {
        const company = this.deliveryInfo.Companies.find(c => c.Id == this.CompanyId);
        if (!company) {
            return [];
        }

        const date = this.deliveryInfo.DeliveryDates.find(d => d.Id == this.DeliveryDateId);
        if (!date) {
            return [];
        }

        const relations = this.Relations.filter(r => r.StoreId == company.StoreId && r.CompanyId == company.Id && r.DateId == date.Id
            && this.Holidays.filter(h => h.Id == r.DateId).length <= 0);
        if (!relations && relations.length) {
            return [];
        }

        const pickUpTypes = this.deliveryInfo.PickUpTypes && this.deliveryInfo.PickUpTypes
            .filter(p => relations.find(r => r.PickUpTypeId == p.Id))
            .map(x => <ISelectBoxOptionDisablable<number>>{Id: x.Id, Name: x.Name, Disabled: false});

        return pickUpTypes;
    }

    private deadlines: StoreDeadlineSelector[] = null;

    private CreateForm() {

        const deliveryDate: Date = DateHelper.fromOADate(this.dsSvc.deliveryDateId);

        this.Form = this.fb.group({
            CompanyId: this.fb.control(this.dsSvc.companyId, [Validators.required]),
            PickUpTypeId: this.fb.control(this.dsSvc.pickUpTypeId, [Validators.required]),
            DeliveryDate: this.fb.control(deliveryDate, [Validators.required])
        });

        this.Form.get('DeliveryDate').valueChanges.subscribe((newDate) => {

            if( (newDate > this.dateInfo.MaxDate) ||
                this.Holidays.filter(x => {
                let holiday = DateHelper.fromOADate(x.Id);
                return holiday.getDate() == newDate.getDate() && holiday.getMonth() == newDate.getMonth() && holiday.getFullYear() == newDate.getFullYear()
            }).length > 0 ||
                this.dateInfo.NonSelectableDates.filter(x=> x.getDate() == newDate.getDate() && x.getMonth() == newDate.getMonth() &&
                    x.getFullYear() == newDate.getFullYear()
                ).length > 0){
                this.Form.controls['DeliveryDate'].patchValue(this.selectedDate,{ emitEvent: false});
            }
            else{
                this.selectedDate = newDate;
            }
            this.PickUpTypes = this.getPickUpOptions();
        });

        this.Form.get('CompanyId').valueChanges.subscribe(() => {
            this.RefreshValidity();
            /*if (!this.isValid)*/ {
                const relationsByCompany = this.Relations.filter(x => x.CompanyId === this.CompanyId);

                if (relationsByCompany.length) {
                    const relationsByCompanyAndDate = relationsByCompany.filter(x => x.DateId === this.DeliveryDateId);

                    if (relationsByCompanyAndDate.length) {
                        //const relationsByCompanyAndDateAndPickUpType = relationsByCompanyAndDate.filter(x => x.PickUpTypeId === this.PickUpTypeId);

                        if (false/*relationsByCompanyAndDateAndPickUpType.length*/) {

                        } else {
                            this.Form.patchValue({
                                PickUpTypeId: relationsByCompanyAndDate[0].PickUpTypeId
                            });
                        }
                    } else {
                        this.Form.patchValue({
                            DeliveryDate: DateHelper.fromOADate(relationsByCompany[0].DateId),
                            PickUpTypeId: relationsByCompany[0].PickUpTypeId
                        }, {emitEvent: false});
                    }
                }
            }

            this.Holidays = this.getHolidaysOptions();
            this.DeliveryDates = this.getDatesOptions();
            this.PickUpTypes = this.getPickUpOptions();
            this.RefreshDateInfo();
        });

        this.Form.get('PickUpTypeId').valueChanges.subscribe(() => {
        });

        this.Form.valueChanges.subscribe(() => {
            this.RefreshValidity();
        });
    }

    public OnMainButtonClick() {
        this.IsDrowDownVisible = !this.IsDrowDownVisible;
        this.dataSvc.showOverlay = this.IsDrowDownVisible;

        if (!this.IsDrowDownVisible) {
            this.Reset();
        } else {
            this.currentTime = new Date();
            this.RefreshDateInfo();
            $('.overlay').addClass('overlay--active');
        }
    }

    private onlinePrices: boolean = true;

    public OnConfirmSettingsClick(): void {

        if (this.Form.valid) {
            this.dsSvc.setDeliverySettings(
                this.CompanyId,
                this.PickUpTypeId,
                this.DeliveryDateId
            );
            this.SetTitle();

            // online ceny
            if (this.onlinePrices) {
                this.RefreshPriceList().subscribe(() => {
                    this.EmitDeliverySettingsChanged();
                    FormUtils.MakePristineGroup(this.Form);
                    this.IsDrowDownVisible = false;
                    this.dataSvc.showOverlay = false;
                });
            }
            // offline ceny
            else {
                this.EmitDeliverySettingsChanged();
                FormUtils.MakePristineGroup(this.Form);
                this.IsDrowDownVisible = false;
                this.dataSvc.showOverlay = false;
            }

            this.showHolidayDialog();
        }

    }

    public OnResetClick(): void {
        this.Reset();
    }

    private EmitDeliverySettingsChanged(): void {
        this.dsSvc.EmitDeliverySettingsChanged({
            companyId: this.CompanyId,
            pickUpTypeId: this.PickUpTypeId,
            deliveryDateId: this.DeliveryDateId,
            companyName: this.companyName,
            dateString: this.dateString
        });
    }

    public dateIsSelectable(oaDate: number): boolean {
        let date = DateHelper.fromOADate(oaDate);

        return (!this.sdSvc.isAfterDeadline(date, DateHelper.minutesInDay(this.currentTime), this.deadlines)) ||
            (this.Holidays.filter( h => {
                let holiday: Date = DateHelper.fromOADate(h.Id);

                return holiday.getDate() == date.getDate() && holiday.getMonth() == date.getMonth() && holiday.getFullYear() == date.getFullYear();
            }).length == 0);
    }

    private get CompanyId(): number {
        return this.Form.get('CompanyId').value;
    }

    private get PickUpTypeId(): number {
        return this.Form.get('PickUpTypeId').value;
    }

    private get CompanyStoreId(): number {
        const companyId: number = this.Form.get('CompanyId').value;
        if (companyId) {
            const company = this.Companies.find(x => x.Id === companyId);

            if (company) {
                const storeId = company.StoreId;
                return storeId;
            }
        }
        return null;
    }

    private get DeliveryDateId(): number {
        const date: Date = this.Form.get('DeliveryDate').value;
        if (date) {
            const dd: Date = DateHelper.ToLocaleDate(date);
            const res: number = Math.floor(DateHelper.toOADate(dd));
            return res;
        } else {
            return null;
        }
    }

    @HostListener('document:click', ['$event'])
    public OnClickOut(ev: any): void {
        const $target = $(ev.target);
        if ($target.closest('.header-delivery, .ui-datepicker-group').length === 0) {
            this.Hide();
        }
    }

    public RefreshPriceList(): EventEmitter<boolean> {

        const ee: EventEmitter<boolean> = new EventEmitter();

        this.dataSvc.dataLoading = true;
        this.dsSvc.RefreshPriceList()
            .pipe(
                takeUntil(this.unsubscribe),
                finalize(() => {
                    this.dataSvc.dataLoading = false;
                })
            )
            .subscribe((res) => {
                if (!res.IsSuccess) {
                    this.ShowErrorMessage();
                    ee.emit(false);
                } else {
                    ee.emit(true);
                }
            }, () => {
                this.ShowErrorMessage();
                ee.emit(false);
            });

        return ee;
    }

    public ShowErrorMessage(): void {
        this.dialogSvc.showMessageBox<string>(
            this.sen['header-delivery-configuration'],
            this.sen['header-delivery-configuration-error-dialog-text'],
            [
                MessageBoxButtons.OK
            ]
        );
    }

    public IsHoliday(date: any): boolean {
        return this.Holidays.filter( h => {
            let holiday: Date = DateHelper.fromOADate(h.Id);

            return holiday.getDate() == date.day && holiday.getMonth() == date.month && holiday.getFullYear() == date.year;
        }).length > 0;
    }

    public IsSelectable(day: any): boolean {
        let date = new Date(day.year,day.month,day.day,23,59,59);
        let date2 = new Date(day.year,day.month,day.day,0,0,0);

        return date >= this.dateInfo.MinDate && date2 <= this.dateInfo.MaxDate &&
            this.dateInfo.NonSelectableDates.filter(x=> x.getDate() == date.getDate() && x.getMonth() == date.getMonth() &&
                x.getFullYear() == date.getFullYear()
            ).length ==0;
    }

    public MinDate(a : Date, b: Date): Date{
        return a < b ? a : b
    }

    public MaxDate(a : Date, b: Date): Date{
        return a > b ? a : b
    }

    public showHolidayDialog(){
        if(
            this.Holidays.filter(x => DateHelper.fromOADate(x.Id) > this.dateInfo.MinDate &&
                DateHelper.fromOADate(x.Id) < this.dateInfo.MaxDate). length > 0
        )
        {
            //if(CredentialStorage.authInfo.userName != localStorage.getItem("deliveryModalClosedBy")) {
            let holidayDate: Date = DateHelper.fromOADate(this.Holidays.filter(x => DateHelper.fromOADate(x.Id) > this.dateInfo.MinDate &&
                DateHelper.fromOADate(x.Id) < this.dateInfo.MaxDate)[0].Id);

            let msg = this.sen["header-delivery-holiday-dialog-msg1"] + " " +
                '<strong>' + holidayDate.getDate() + "." + (holidayDate.getMonth() + 1) + "." + '</strong>' +
                " " + this.sen["header-delivery-holiday-dialog-msg2"]
            const config: GeneralDialogConfig<IMessageBoxConfig<boolean>> = {
                data: {message: msg, buttons: [{text: 'Beru na vedomi', value: true}]},
                isCloseAble: true,
                title: this.sen["header-delivery-holiday-dialog-title"],
                cssClassModifier: ''
            }
            this.dialogSvc.open(GeneralDialogMessageBoxComponent, config).afterClosed.subscribe(() => {
                localStorage.setItem("deliveryModalClosedBy",CredentialStorage.authInfo.userName)
            });

            //}
        }
    }
}

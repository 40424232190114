/**
 * Component providing functionality of a full article detail.
 */
import {Component, Input, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {Translatable, ArticleSelector} from "../../interfaces/general";
import {SettingsService} from "../../services/settings.service";
import {DataService} from "../../services/data.service";
import {takeUntil} from "rxjs/operators";
import {ArticleService} from "./article.service";

@Component({
    templateUrl: '../../tpl/article-list-paged.html',
    selector: 'cmp-article-list-paged',
    styleUrls: ['../../assets/styles/2-components/article-list.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ArticleListPagedComponent extends Translatable {

    @Input() articleName: string = null;
    @Input() type: string = '';
    @Input() parentArticleId: number;
    @Input() pageSize: number;
    @Input() pageOffset: number = 0;

    page: number = 1;
    total: number;

    articles: ArticleSelector[];

    constructor(public dataSvc: DataService, seSvc: SettingsService, private artSvc: ArticleService) {
        super(dataSvc, seSvc);

    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    ngOnChanges(_changes: SimpleChanges): void {
        this.getChildArticles();
    }

    private getChildArticles(): void {
        this.artSvc.getPagedChildren(this.parentArticleId, this.page ,this.pageSize, this.pageOffset)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(
                res => {
                    this.articles = res.data;
                    this.total = res.total;
                }
            );
    }

    pageChanged(event: any): void {
        if (this.page != event.page) {
            this.page = event.page;
            this.getChildArticles();
        }
    }
}
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RatingShopComponent } from "./rating-shop.component";
import { RatingStarModule } from "../rating-star/rating-star.module"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RatingStarModule
    ],
    declarations: [
        RatingShopComponent
    ],
    exports: [
        RatingShopComponent
    ]
})

export class RatingShopModule { }

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderDeliveryComponent} from './header-delivery/header-delivery.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ValidationModule} from "../validation/validation.module";
import {CalendarModule} from "primeng/primeng";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {GeneralDialogModule} from "../general-dialog/general-dialog.module";

@NgModule({
    declarations: [
        HeaderDeliveryComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ValidationModule,
        BrowserAnimationsModule,
        CalendarModule,
        GeneralDialogModule
    ],
    exports: [
        HeaderDeliveryComponent
    ]
})
export class HeaderDeliveryModule {
}

import {Injectable} from '@angular/core';
import {CompanyIdentitySelector, LogoSelector, MottoSelector} from "../interfaces/general";
import {HttpClient} from "@angular/common/http";

declare let $: any;

export interface IUserData {
    IsOrderTrackingEnabled: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class IdentityService {

    public shopId: number;
    private _logoSettings: Map<number, LogoSelector> = new Map();
    private _mottoSettings: Map<number, Map<number, MottoSelector>> = new Map();

    public companyMessage: string;
    public imagePath: string;

    constructor(private http: HttpClient) {
    }

    public addLogoSettings(value: string): void {
        let items = JSON.parse(value);
        for (let key in items) {
            let id = +key;
            if (!Number.isNaN(id)) {
                this._logoSettings.set(id, items[key]);
            }
        }

        this.SetDomainThemeClass(this._logoSettings.get(this.shopId).themeClass);
    }

    public addMottoSettings(value: string): void {
        let items = JSON.parse(value);
        for (let shopKey in items) {
            let shopId = +shopKey;
            if (!Number.isNaN(shopId)) {
                let lang: Map<number, MottoSelector> = new Map();
                for (let cultureKey in items[shopKey]) {
                    let cultureId = +cultureKey;
                    if (!Number.isNaN(cultureId)) {
                        lang.set(cultureId, items[shopKey][cultureKey]);
                    }
                }
                this._mottoSettings.set(shopId, lang);
            }
        }
    }

    private theme: string = null;
    private domainTheme: string = null;

    public getCompanyIdentity(): void {
        this.http.get<CompanyIdentitySelector>('api/account/identity')
            .subscribe(res => {
                if (res) {
                    this.companyMessage = res.message;
                    this.imagePath = res.imagePath;

                    this.SetThemeClass(res.theme.CssClass);
                }
            });
    }

    public SetThemeClass(themeClass: string): void {
        if (this.theme) {
            $('body').removeClass(this.theme);
        }

        if (themeClass) {
            $('body').addClass(themeClass);
        }
        this.theme = themeClass;
    }

    public SetDomainThemeClass(themeClass: string): void {
        if (this.domainTheme) {
            $('body').removeClass(this.domainTheme);
        }

        if (themeClass) {
            $('body').addClass(themeClass);
        }
        this.domainTheme = themeClass;
    }

    get logoSettings(): LogoSelector {
        return this._logoSettings.get(this.shopId) || {logo: '', favicon: '', themeClass: ''};
    }

    getMottoSettings(cultureId: number): MottoSelector {
        return (this._mottoSettings.get(this.shopId) || new Map()).get(cultureId) || {motto: ''};
    }

    public setFavicon(imageServerPathPrefix: string): void {

        document.querySelector('link[rel="icon"]')
            .setAttribute('href', `${imageServerPathPrefix}/fotocache/images/${this.logoSettings.favicon}`);
    }

    private userData: IUserData = null;

    public get UserData(): IUserData {
        return this.userData;
    }

    public GetUserData(): Promise<IUserData> {
        const p: Promise<IUserData> = new Promise<IUserData>((resolve, reject) => {

            this.http.get<IUserData>('api/user/get-data')
                .subscribe(res => {
                    this.userData = res;
                    resolve(res);
                }, () => {
                    reject();
                })

        });

        return p;
    }
}
import {Component, Input, ViewEncapsulation} from '@angular/core';
import {IContactForHeader, PageModeType, Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";
import {Subject} from "rxjs";
import {ContactService} from "./services/contact.service";
import {takeUntil} from "rxjs/operators";

@Component({
    selector: 'cmp-header-contact',
    templateUrl: '../../tpl/header-contact.html',
    styleUrls: ['../../assets/styles/3-layout/header/header-contact.scss'],
    encapsulation: ViewEncapsulation.None
})

export class HeaderContactComponent extends Translatable {

    @Input() pageMode: PageModeType;

    ngUnsubscribe: Subject<any> = new Subject<any>();
    public contact: IContactForHeader;

    constructor(public dataSvc: DataService
                ,seSvc: SettingsService
                ,private contactSvc: ContactService) {
        super(dataSvc, seSvc);
    }

    ngOnInit(): void {
        this.GetContactForHeader();
    }

    private GetContactForHeader(){
        this.contactSvc.GetContactForHeader()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(res=>{
                this.contact = res;
            })
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        super.ngOnDestroy();
    }
}
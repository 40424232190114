import {Component, Input, OnInit} from '@angular/core';
import {Translatable} from "../../../interfaces/general";
import {SettingsService} from "../../../services/settings.service";
import {DataService} from "../../../services/data.service";
import {IProductPriceSelector} from "../../product/common";
import {ProductPriceUtils} from "../../../helpers/ProductPriceUtils";

@Component({
    selector: 'cmp-price-amount',
    templateUrl: './price-amount.component.html'
})
export class PriceAmountComponent extends Translatable implements OnInit {

    public ProductPriceUtils = ProductPriceUtils;

    @Input() prices: IProductPriceSelector[] = null;
    @Input() amount: number = null;
    @Input() decimalPattern: string = null;
    @Input() currencySymbol: string = null;
    @Input() withVat: boolean = false;
    @Input() isUnitPrice: boolean = false;
    @Input() multipackPcs: number = 0;
    @Input() volume: number = 0;
    @Input() unitValue: number = 0;


    constructor(
        seSvc: SettingsService,
        dataSvc: DataService
    ) {
        super(dataSvc, seSvc);
        this.currencySymbol = this.seSvc.settings.currencies[this.seSvc.currencyId];
        this.decimalPattern = `1.${this.seSvc.settings.decimalSettings.Detail}-${this.seSvc.settings.decimalSettings.Detail}`;
    }

    ngOnInit() {
    }

}

import {Component, ViewEncapsulation} from '@angular/core';
import {Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";
import {StoreDeadlineService} from "./store-deadline.service";
import {DeliverySettingsService} from "../../services/delivery-settings.service";
import {DeadlineWarningType, StoreDeadlineSelector} from "./common";
import {CartService} from "../cart/cart.service";

@Component({
    selector: 'cmp-store-deadline',
    templateUrl: '../../tpl/store-deadline.html',
    styleUrls: ['../../assets/styles/2-components/store-deadline.scss'],
    encapsulation: ViewEncapsulation.None
})

export class StoreDeadlineComponent extends Translatable {

    private currentDate: Date = new Date();
    private cartCrossDock: boolean = false;
    public deadline: StoreDeadlineSelector;
    public warningType: DeadlineWarningType

    constructor(
        public dataSvc: DataService,
        seSvc: SettingsService,
        private sdSvc: StoreDeadlineService,
        public dsSvc: DeliverySettingsService,
        private cartSvc: CartService
    ) {
		super(dataSvc, seSvc);

        setInterval(() => {
	        this.updateDate(new Date());
            this.updateWarningType();
        }, 60000);
        this.updateDate(new Date());
        this.updateWarningType();

        this.sdSvc.deadlinesLoaded
            .subscribe(() => {
                this.updateWarningType();
            });

        this.dsSvc.DeliverySettingsChanged
            .subscribe(() => {
                this.updateWarningType();
            });
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    private updateDate(date: Date): void {
        this.currentDate = date;
        if (this.cartSvc.cart && this.cartSvc.cart.cartContent) {
            this.cartCrossDock = this.cartSvc.cart.cartContent
                .filter(cc => cc.productDetail)
                .some(cc => cc.productDetail.availability && cc.productDetail.availability.crossDock);
        }
        else {
            this.cartCrossDock = false;
        }
    }

    private updateWarningType(): void {
        if (this.showError || !this.deadlinesLoaded) {
            this.warningType = 'deadlineError';
            return;
        }
        if (this.showCrossDockError) {
            this.warningType = 'crossDockError';
            return;
        }
        if (this.showWarning) {
            this.warningType =  'deadlineWarning';
            return;
        }
        if (this.showCrossDockWarning) {
            this.warningType =  'crossDockWarning';
            return;
        }
        this.warningType =  'none';
    }

    get showWarning(): boolean {
        return this.sdSvc.isBeforeSelectedDeadline();
    }

    get showError(): boolean {
        return this.sdSvc.isAfterSelectedDeadline();
    }

    get showCrossDockWarning(): boolean {
        return this.cartCrossDock && this.sdSvc.isBeforeSelectedCrossDockDeadline();
    }

    get showCrossDockError(): boolean {
        return this.cartCrossDock && this.sdSvc.isAfterSelectedCrossDockDeadline();
    }

    get deadlinesLoaded(): boolean {
        return !!this.sdSvc.currentStoreDeadlines;
    }

    get todayDeadline(): StoreDeadlineSelector {
        return this.sdSvc.getDeadlineForDate(this.currentDate);
    }
}
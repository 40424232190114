import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {ArticleBoxComponent} from './article-box.component';
import {ArticleBoxListComponent} from "./article-box-list.component";
import {ImageCacheBustModule} from "../../directives/image-cache-bust.module";
import {UiDirectivesModule} from "../../directives/ui-directives.module";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ImageCacheBustModule,
        UiDirectivesModule
    ],
    exports: [
        ArticleBoxComponent,
        ArticleBoxListComponent
    ],
    declarations: [
        ArticleBoxComponent,
        ArticleBoxListComponent
    ],
    providers: [],
})

export class ArticleBoxModule {
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PriceModule} from "../price/price.module";
import {RouterModule} from "@angular/router";
import { CartImportedProductsDialogComponent } from './cart-imported-products-dialog.component';
import {ImageCacheBustModule} from "../../directives/image-cache-bust.module";

@NgModule({
    declarations: [
        CartImportedProductsDialogComponent
    ],
    imports: [
        CommonModule,
        PriceModule,
        RouterModule,
        ImageCacheBustModule
    ],
    exports: [
        CartImportedProductsDialogComponent
    ],
    entryComponents: [
        CartImportedProductsDialogComponent
    ]
})
export class CartImportedProductsDialogModule {
}

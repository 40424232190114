import {Component, Input, OnInit} from '@angular/core';
import {AvailabilityType, IProductAvailability} from "../services/product-availability.service";
import {Translatable} from "../../../interfaces/general";
import {DataService} from "../../../services/data.service";
import {SettingsService} from "../../../services/settings.service";

@Component({
    selector: 'cmp-product-availability',
    templateUrl: './product-availability.component.html',
    styleUrls: ['../../../assets/styles/2-components/availability.scss']
})
export class ProductAvailabilityComponent extends Translatable implements OnInit {

    @Input() availability: IProductAvailability = null;
    @Input() type: AvailabilityType = 'Detail';

    constructor(
        dataSvc: DataService,
        public seSvc: SettingsService
    ) {
        super(dataSvc, seSvc);
    }

    ngOnInit() {
    }

}

import {Component, Input, ViewEncapsulation} from "@angular/core";
import {ArticleBriefSelector, Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";

@Component({
    selector:'cmp-article-list',
    templateUrl: '../../tpl/article-list.html',
    styleUrls: ['../../assets/styles/2-components/article-list.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ArticleListComponent extends Translatable {

    @Input() articles: ArticleBriefSelector[];
    @Input() title: string;

    constructor(public dataSvc: DataService, seSvc: SettingsService) {
        super(dataSvc, seSvc);

    }

}

import {IProductAmountSettings} from "../modules/product-amount/product-amount/product-amount.component";
import {ProductDetailModel, ProductSelector, ProductVariantDetailSelector} from "../modules/product/common";

export class ProductAmountAdapter implements IProductAmountSettings {

    // Factor ma hodnotu minimalniho mnozstvi!!! Pokud je MinQty == 0, tak MinQty = 1

    constructor(product: ProductSelector, isSizePackage: boolean) {
        this.Factor = isSizePackage ? 1 : product.variants[0].minQtyOrder || 1;
        this.PackageFactor = [product.variants[0].unitsInPackage, product.variants[0].unitsInLayer, product.variants[0].unitsOnPallete];
        this.MaxQuantity = product.variants[0].maxQtyOrder;
        this.MinQuantity = isSizePackage ? 1 : (product.variants[0].minQtyOrder || 1);
        this.ProductId = product.variants[0].id;
        this.Unit = product.variants[0].unit.shortCut;
    }

    Factor: number;
    MaxQuantity: number;
    MinQuantity: number;
    ProductId: number;
    Unit: string;
    PackageFactor: Array<number>;

    public static FromProductVariantDetailSelector(product: ProductVariantDetailSelector, isSizePackage: boolean): IProductAmountSettings {
        return {
            Factor: isSizePackage ? 1 : product.minQtyOrder || 1,
            PackageFactor: [product.unitsInPackage, product.unitsInLayer, product.unitsOnPallete],
            MaxQuantity: product.maxQtyOrder,
            MinQuantity: isSizePackage ? 1 : product.minQtyOrder || 1,
            ProductId: product.id,
            Unit: product.unit && product.unit.shortCut,
        };
    }

    public static FromProductDetailModel(product: ProductDetailModel, isSizePackage: boolean): IProductAmountSettings {
        return {
            Factor: isSizePackage ? 1 : product.minQtyOrder || 1,
            PackageFactor: [product.unitsInPackage, product.unitsInLayer, product.unitsOnPallete],
            MaxQuantity: product.maxQtyOrder,
            MinQuantity: isSizePackage ? 1 : product.minQtyOrder || 1,
            ProductId: product.variantId,
            Unit: product.unit && product.unit.shortCut,
        };
    }
}
import {Component, ViewEncapsulation} from "@angular/core";
import {ArticleBriefSelector, Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {ArticleService} from "./article.service";
import {SettingsService} from "../../services/settings.service";

@Component({
    selector:'cmp-articles-top-news',
    templateUrl: '../../tpl/article-top-news.html',
    styleUrls: ['../../assets/styles/2-components/article-list.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ArticleTopNewsComponent extends Translatable {

    articles: Array<ArticleBriefSelector>;
    imagePathPrefix: string;

    constructor(public dataSvc: DataService, private artSvc: ArticleService, seSvc: SettingsService) {
        super(dataSvc, seSvc);
    }

    ngOnInit(): void {
        this.getData();
    }

    private getData() {
        this.artSvc.getTopNews()
            .subscribe((res) => {
                  this.articles = res;
              });
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

}

import {Pipe, PipeTransform} from '@angular/core';
import {stringFormat} from "../../helpers/string.helper";
import {SettingsService} from "../../services/settings.service";

@Pipe({
    name: 'format'
})

export class StringFormatPipe implements PipeTransform {
    constructor(private seSvc: SettingsService) {
    }

    transform(value: any, ...args: any[]): any {
        return stringFormat(this.seSvc.getLocale(), value, args[0], args[1])
    }
}
import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {NoAutocompleteDirective} from "./no-autocomplete.directive";
import {ObserveVisibilityDirective} from "./observe-visibility.directive";

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        NoAutocompleteDirective,
        ObserveVisibilityDirective
    ],
    exports: [
        NoAutocompleteDirective,
        ObserveVisibilityDirective
    ]
})

export class UiDirectivesModule {}
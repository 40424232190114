import {Component, Input, ViewEncapsulation} from "@angular/core";

@Component({
    selector: 'cmp-preloader',
    templateUrl: '../../tpl/preloader.html',
    styleUrls: ['../../assets/styles/2-components/preloader.scss'],
    encapsulation: ViewEncapsulation.None

})

export class PreloaderComponent {

    @Input() loading: boolean = false;

    constructor () {}
}

import {Component, Input, ViewEncapsulation} from '@angular/core';
import {PageModeType, Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";
import {Subject} from "rxjs";
import {IdentityService} from "../../services/identity.service";

@Component({
    selector: 'cmp-header-logo',
    templateUrl: '../../tpl/header-logo.html',
    styleUrls: ['../../assets/styles/3-layout/header/logo.scss'],
    encapsulation: ViewEncapsulation.None
})

export class HeaderLogoComponent extends Translatable {

    @Input() pageMode: PageModeType;

    ngUnsubscribe: Subject<any> = new Subject<any>();

    linkCms: string[] = ['/'];
    linkEshop: string[] = ['/', 'eshop'];

    logoPathPrefix: string;

    constructor(public dataSvc: DataService, seSvc: SettingsService, private idSvc: IdentityService) {
        super(dataSvc, seSvc);

        this.logoPathPrefix = this.seSvc.settings.imageServerPathPrefix + '/fotocache/images/';
    }

    ngOnInit(): void {

    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        super.ngOnDestroy();
    }

    get logoPath(): string {
        return this.idSvc.imagePath || this.idSvc.logoSettings.logo;
    }
}
import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {ToQueryStringParam} from "../../../helpers/string.helper";

export interface IProductAvailability {
    ProductId: number;
    VisibilityId: number;
    Quantity: number;
    IsBuyable: boolean;
    IsInStock: boolean;
    CrossDock: boolean;
}

export type AvailabilityType = 'Detail' | 'Catalog';

export interface IProductAvailabilityRequest {
    ProductIds: number[];
}

export interface IProductAvailabilityResponse {
    Availabilities: IProductAvailability[];
}

@Injectable({
    providedIn: 'root'
})
export class ProductAvailabilityService {

    constructor(
        private http: HttpClient
    ) {
    }

    public GetProductAvailabilities(productIds: number[]): Observable<IProductAvailabilityResponse> {
        return this.http.get<IProductAvailabilityResponse>(`api/availability/get-by-product-ids?qs=${ToQueryStringParam(<IProductAvailabilityRequest>{
            ProductIds: productIds
        })}`);
    }

}

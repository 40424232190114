import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {IContact, IContactForHeader} from "../../../interfaces/general";
import {SocialSelector} from "../../settings-social/common";

@Injectable()
export class ContactService {
    constructor(private http: HttpClient) {
    }

    public GetContactForHeader(): Observable<IContactForHeader>{
        return this.http.get<IContactForHeader>('api/contact/getContactForHeader');
    }

    public GetContact(): Observable<IContact>{
        return this.http.get<IContact>('api/contact/getContact');
    }

    public getSocialData(): Observable<SocialSelector> {
        return this.http.get<SocialSelector>('api/settings/social');
    }
}
import {Component, ViewEncapsulation} from '@angular/core';
import {IContact, Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {ContactService} from "./services/contact.service";

@Component({
    selector: 'cmp-app-copyright',
    templateUrl: '../../tpl/app-copyright.html',
    styleUrls: ['../../assets/styles/3-layout/copyright.scss'],
    encapsulation: ViewEncapsulation.None
})

export class AppCopyrightComponent extends Translatable {

    copyright: string;
    domainCopyright: string;
    copyrightYear: string;
    public contact: IContact;

    ngUnsubscribe: Subject<any> = new Subject<any>();

    constructor(public dataSvc: DataService, seSvc: SettingsService, private contactSvc: ContactService) {
        super(dataSvc, seSvc);

        this.setCopyright();
        this.getData();
    }

    ngOnInit(): void {
        this.getData();
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        super.ngOnDestroy();
    }

    private setCopyright(): void {
        let year = (new Date()).getFullYear().toString();
        this.copyright = this.sen['app-copyright'].replace('yyyy', year);
    }

    private getData(): void {
        this.contactSvc.GetContact()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(res => {
                this.contact = res;
                let year = (new Date()).getFullYear().toString();
                this.copyrightYear = this.sen['app-copyright-year'].replace('yyyy', year) + this.contact.name;
            })
    }
}
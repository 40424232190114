import {Component} from "@angular/core";
import {Translatable} from "../../interfaces/general";
import {SettingsService} from "../../services/settings.service";
import {DataService} from "../../services/data.service";
import {StoredCartAdjustCartResult, UnitType} from "../cart/common";
import {GeneralDialogConfig} from "../general-dialog/general-dialog-config";
import {GeneralDialogRef} from "../general-dialog/common";
import {CartFromStoredDialogConfig} from "./common";
import {StoredCartProductSelector} from "../saved-carts/common";
import {filter, takeUntil} from "rxjs/operators";
import {NavigationStart, Router} from "@angular/router";
import {ProductPriceUtils} from "../../helpers/ProductPriceUtils";


@Component({
    selector: 'cmp-cart-multi-change-modal',
    templateUrl: '../../tpl/cart-multi-change-modal.html'
})
export class CartMultiChangeModalComponent extends Translatable {

    public UnitType = UnitType;
    public ProductPriceUtils = ProductPriceUtils;

    cartResult: StoredCartAdjustCartResult;

    timeoutId: any;
    timeOutDelay: number = 0;

    imageServerPathPrefix: string;

    constructor(
        public dataSvc: DataService,
        public seSvc: SettingsService,
        private config: GeneralDialogConfig<CartFromStoredDialogConfig>,
        private dialogRef: GeneralDialogRef,
        private router: Router
    ) {
        super(dataSvc, seSvc);

        if (this.config && this.config.data) {
            this.cartResult = this.config.data.result;
        }

        if (this.timeOutDelay > 0) {
            if (this.timeoutId) clearTimeout(this.timeoutId);
            this.timeoutId = setTimeout(() => {
                this.CloseDialog();
            }, this.timeOutDelay);
        }

        this.imageServerPathPrefix = this.seSvc.settings.imageServerPathPrefix

        this.router.events
            .pipe(
                filter((evt: any) => evt instanceof NavigationStart),
                takeUntil(this.unsubscribe)
            )
            .subscribe(() => {
                this.dialogRef.close();
            });
    }

    CloseDialog(): void {
        this.dialogRef.close();
        this.cartResult = null;
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    public IsProductActive(product: StoredCartProductSelector): boolean {
        return !!product.Price;
    }

    public UnitRatio(product: StoredCartProductSelector): number {
        if (product.UnitType === UnitType.Pieces && product.Product && product.Product.isWeightProduct && product.Product.weight) {
            return product.Product.weight;
        } else {
            return 1;
        }
    }
}

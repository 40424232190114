import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {NotifyStripeComponent} from "./notify-stripe.component";
import {NotifyStripeCompanyComponent} from "./notify-stripe-company.component";


@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [NotifyStripeComponent, NotifyStripeCompanyComponent],
    exports: [NotifyStripeComponent, NotifyStripeCompanyComponent]
})

export class NotifyStripeModule {
}
import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {IArticleSearchRequest} from "../interfaces/IArticleSearchRequest";
import {PageResult} from "../../../helpers/page-result";
import {Observable} from "rxjs";
import {ArticleBriefSelector} from "../../../interfaces/general";
import {ToQueryStringParam} from "../../../helpers/string.helper";

@Injectable()
export class ArticleSearchService {

    constructor(
        private http: HttpClient
    ) {
    }

    public Search(request: IArticleSearchRequest): Observable<PageResult<ArticleBriefSelector>> {
        return this.http.get<PageResult<ArticleBriefSelector>>(`api/search/search-articles?qs=${ToQueryStringParam(request)}`);
    }
}

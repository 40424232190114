import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {AppHeaderComponent} from "./app-header.component";
import {HeaderLanguageComponent} from "./header-language.component";
import {HeaderLogoComponent} from "./header-logo.component";
import {HeaderMottoComponent} from "./header-motto.component";
import {FlexSuggestModule} from "../flex-suggest/flex-suggest.module";
import {LoginModule} from "../login/login.module";
import {CartIndicatorModule} from "../cart-indicator/cart-indicator.module";
import {HeaderResponsiveMenuComponent} from "./header-responsive-menu.component";
import {HeaderContactComponent} from "./header-contact.component";
import {HeaderDeliveryDateComponent} from "./header-delivery-date.component";
import {defineLocale} from 'ngx-bootstrap/chronos';
import {csLocale} from 'ngx-bootstrap/locale';
import {FormsModule} from "@angular/forms";
import {HeaderDeliveryModule} from "../header-delivery/header-delivery.module";
import {StoreDeadlineModule} from "../store-deadline/store-deadline.module";
import {ContactService} from "./services/contact.service";
import {ImageCacheBustModule} from "../../directives/image-cache-bust.module";
import {BsDatepickerModule} from "ngx-bootstrap/datepicker";

defineLocale('cs', csLocale);

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FlexSuggestModule,
        LoginModule,
        CartIndicatorModule,
        BsDatepickerModule.forRoot(),
        FormsModule,
        HeaderDeliveryModule,
        StoreDeadlineModule,
        ImageCacheBustModule
    ],
    declarations: [
        AppHeaderComponent,
        HeaderLanguageComponent,
        HeaderLogoComponent,
        HeaderMottoComponent,
        HeaderResponsiveMenuComponent,
        HeaderContactComponent,
        HeaderDeliveryDateComponent
    ],
    exports: [AppHeaderComponent],
    providers: [ContactService],
})

export class HeaderModule {
}

/**
 * Provides route guarding mechanism for secured routes
 * @description
 * If the user wants to navigate by any means to the route, which is secured by this guard
 * the guard either brings up the dialog with warning or it navigates to "you must login" route
 * https://angular.io/docs/ts/latest/api/router/index/CanActivate-interface.html
 */
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from "@angular/router";
import {CredentialStorage} from "./credential-storage.service";

@Injectable()
export class ReadOnlyGuardService {

    dummy: any;

    constructor(private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): any {
        this.dummy = route;//just to disable "'route' is declared but its value is never read" compile-time error

        let canGo: boolean = CredentialStorage.authInfo != null && CredentialStorage.authInfo.readOnly != true;

        if (!canGo) {
            this.router.navigateByUrl('/chyba/403')
                .then(() => {
                    return false;
                });
        } else return true;
    }

}
import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {MenuComponent} from "./menu.component";
import {RouterModule} from "@angular/router";
import {ImageCacheBustModule} from "../../directives/image-cache-bust.module";
import {HoverDelayDirective} from "../../directives/hover-delay.directive";
import { ActionCategoryFilterModule } from '../category/action-category-filter.module';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ImageCacheBustModule,
        ActionCategoryFilterModule
    ],
    declarations: [
        MenuComponent,
        HoverDelayDirective
    ],
    exports: [
        MenuComponent,
        HoverDelayDirective
    ]
})

export class MenuModule {
}

import {EventEmitter, Injectable} from "@angular/core";
import {DataService} from "./data.service";
import {Router} from "@angular/router";
import {CartService} from "../modules/cart/cart.service";
import {CredentialStorage} from "./credential-storage.service";
import {HttpClient} from "@angular/common/http";
import {GeneralDialogService, MessageBoxButtons} from "../modules/general-dialog/general-dialog.service";
import {Sentences} from "../helpers/sentences";
import {GeneralDialogConfig} from "../modules/general-dialog/general-dialog-config";
import {CartProductListDialogComponent} from "../modules/cart-product-list-dialog/cart-product-list-dialog/cart-product-list-dialog.component";
import {
    CartProductListDialogMode,
    ICartProductListDialogConfig
} from "../modules/cart-product-list-dialog/interfaces/ICartProductListDialogConfig";
import {finalize} from "rxjs/operators";

export interface IUserOrdersStatus {
    lastOrder: boolean;
    topOrders: boolean;
}

@Injectable()
export class MenuService {

    toggleMenu: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
    public userOrdersStatus: IUserOrdersStatus;

    constructor(
        private dataSvc: DataService,
        private router: Router,
        private cartSvc: CartService,
        private http: HttpClient,
        private generalDialogSvc: GeneralDialogService
    ) {
        this.GetUserOrdersStatus();
    }

    public GetUserOrdersStatus(): void {
        if (CredentialStorage.userLoggedIn) {
            this.http.get<IUserOrdersStatus>('api/user/orders-status')
                .subscribe((res) => {
                    this.userOrdersStatus = res;
                });
        }
    }


    public async TopOrdersToCart(): Promise<any> {

        this.dataSvc.dataLoading = true;
        this.cartSvc.GetTopOrderProducts()
            .pipe(finalize(() => {
                this.dataSvc.dataLoading = false;
            }))
            .subscribe((res) => {
                const config: GeneralDialogConfig<ICartProductListDialogConfig> = {
                    data: {
                        Products: res,
                        CodeOfDialog: 'most-common',
                        Mode: CartProductListDialogMode.Default
                    },
                    cssClassModifier: 'cart-added',
                    isCloseAble: false,
                    title: Sentences.sen['order-overview-most-common']
                };
                const dialog = this.generalDialogSvc.open(CartProductListDialogComponent, config);

                dialog.afterClosed
                    .subscribe(state => {
                        if (state) {
                            this.dataSvc.dataLoading = true;
                            this.cartSvc.TopOrdersToCartAsync().then((res) => {
                                this.dataSvc.dataLoading = false;
                                if(res) {
                                    this.router.navigateByUrl('/kosik');
                                } else {
                                    this.ShowActionErrorDialog(Sentences.sen['order-overview-most-common']);
                                }
                            }).catch(() => {
                                this.dataSvc.dataLoading = false;
                            });
                        }
                    });
            }, () => {
                this.ShowActionErrorDialog(Sentences.sen['order-overview-most-common']);
            });

        // this.generalDialogSvc.showMessageBox(
        //     Sentences.sen['order-overview-most-common'],
        //     Sentences.sen['most-common-dialog-text'],
        //     [MessageBoxButtons.Yes, MessageBoxButtons.No]
        // ).subscribe((val: string) => {
        //     if (val === MessageBoxButtons.Yes.value) {
        //         this.dataSvc.dataLoading = true;
        //         this.cartSvc.TopOrdersToCartAsync().then(() => {
        //             this.dataSvc.dataLoading = false;
        //             this.router.navigateByUrl('/kosik');
        //         }).catch(() => {
        //             this.dataSvc.dataLoading = false;
        //         });
        //     }
        // });
    }

    public async LastOrderToCart(): Promise<any> {

        this.dataSvc.dataLoading = true;
        this.cartSvc.GetLastOrderProducts()
            .pipe(finalize(() => {
                this.dataSvc.dataLoading = false;
            }))
            .subscribe((res) => {
                const config: GeneralDialogConfig<ICartProductListDialogConfig> = {
                    data: {
                        Products: res,
                        CodeOfDialog: 'last',
                        Mode: CartProductListDialogMode.Default
                    },
                    cssClassModifier: 'cart-added',
                    isCloseAble: false,
                    title: Sentences.sen['order-overview-last']
                };
                const dialog = this.generalDialogSvc.open(CartProductListDialogComponent, config);

                dialog.afterClosed
                    .subscribe(state => {
                        if (state) {
                            this.dataSvc.dataLoading = true;
                            this.cartSvc.LastOrderToCartAsync().then((res) => {
                                this.dataSvc.dataLoading = false;
                                if(res) {
                                    this.router.navigateByUrl('/kosik');
                                } else {
                                    this.ShowActionErrorDialog(Sentences.sen['order-overview-last']);
                                }
                            }).catch(() => {
                                this.dataSvc.dataLoading = false;
                                this.ShowActionErrorDialog(Sentences.sen['order-overview-last']);
                            });
                        }
                    });
            }, () => {
                this.ShowActionErrorDialog(Sentences.sen['order-overview-last']);
            });

    }

    private ShowActionErrorDialog(title: string): void {
        this.generalDialogSvc.showMessageBox(
            title,
            Sentences.sen['action-error'],
            [
                MessageBoxButtons.OK
            ],
            'error-message'
        );
    }

    public get TopOrders(): boolean {
        return !!(this.userOrdersStatus && this.userOrdersStatus.topOrders);
    }

    get LastOrder(): boolean {
        return !!(this.userOrdersStatus && this.userOrdersStatus.lastOrder);
    }

    public async CreateNewOrder(): Promise<any> {
        if (!this.cartSvc.IsEmpty) {
            let empty = await this.confirmEmptyCartModal();
            if (empty === null) return;
            if (empty === false) {
                await this.router.navigateByUrl(`/kosik`);
            }
            if (empty === true) {
                this.dataSvc.dataLoading = true;
                await this.cartSvc.emptyCartAsync();
                this.dataSvc.dataLoading = false;
                await this.router.navigateByUrl(`/kosik`);
            }
        } else {
            this.router.navigateByUrl(`/kosik`);
        }
    }

    async confirmEmptyCartModal(): Promise<boolean | null> {
        const p: Promise<boolean> = new Promise<boolean>((resolve, reject) => {

            this.generalDialogSvc.showMessageBox<string>(Sentences.sen['new-order-dialog-title'], Sentences.sen['new-order-dialog-text'], [
                MessageBoxButtons.Yes,
                MessageBoxButtons.No
            ]).subscribe((res: string) => {
                if(res === MessageBoxButtons.Yes.value || res === MessageBoxButtons.No.value)
                    resolve(res === MessageBoxButtons.Yes.value);
                else
                    resolve();

            }, () => {
                reject();
            });

        });

        return p;
    }
}

/**
 * manual reporting
 */
import {Injectable, Injector} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {ErrorSelector} from "../interfaces/general";

@Injectable()
export class ErrorService {

    constructor (private injector: Injector) {
    }

    handleError(error: any, subject?: string): void {
        const http: HttpClient = <HttpClient>this.injector.get(HttpClient);
        let errorSelector: ErrorSelector;

        try {
            errorSelector = {
                message: `${error.name}|${error.message}`,
                subject: subject || null,
                stack: error.stack || new Error().stack,
                url: location.href
            };
        } catch (e) {
            errorSelector = {
                message: 'Unable to stringify error object.',
                subject: subject || null,
                stack: new Error().stack,
                url: location.href
            };
        }

        http.post<ErrorSelector>('api/error/report', errorSelector)
            .subscribe(() => {});
    }
}
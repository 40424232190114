import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProductAmountComponent} from './product-amount/product-amount.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ValidationModule} from "../validation/validation.module";
import {ProductAmountPipe} from "./product-amount.pipe";
import {CartProductAmountPipe} from "./cart-product-amount.pipe";
import {SavedCartItemAmountPipe} from "./saved-cart-item-amount-pipe";

@NgModule({
    declarations: [
        ProductAmountComponent,
        ProductAmountPipe,
        CartProductAmountPipe,
        SavedCartItemAmountPipe
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ValidationModule
    ],
    exports: [
        ProductAmountComponent,
        ProductAmountPipe,
        CartProductAmountPipe,
        SavedCartItemAmountPipe
    ]
})
export class ProductAmountModule {
}

import {Injectable} from '@angular/core';
import {Observable, Subject} from "rxjs";
import {IDeliverySettings} from "../interfaces/general";
import {loadFromLocalStorage, removeFromLocalStorage, saveToLocalStorage} from "../helpers/cookie.helper";
import {HttpClient} from "@angular/common/http";
import {IRefreshPriceListResult} from "../modules/header-delivery/interfaces/IRefreshPriceListResult";

@Injectable({
    providedIn: 'root'
})
export class DeliverySettingsService {

    private deliverySettingsChanged: Subject<IDeliverySettings> = new Subject<IDeliverySettings>();

    public get DeliverySettingsChanged(): Observable<IDeliverySettings> {
        return this.deliverySettingsChanged;
    }

    public settings: IDeliverySettings = null;

    constructor(
        private http: HttpClient
    ) {
        this.EmitDeliverySettingsChanged({
            companyId: this.companyId,
            pickUpTypeId: this.pickUpTypeId,
            deliveryDateId: this.deliveryDateId,

            companyName: '',
            dateString: ''
        });
    }

    public EmitDeliverySettingsChanged(value: IDeliverySettings): void {
        this.settings = value;
        this.deliverySettingsChanged.next(value);
    }


    setDeliverySettings(companyId: number, pickUpTypeId: number, deliveryDateId: number): void {
        saveToLocalStorage('ds_companyId', companyId);
        saveToLocalStorage('ds_pickUpTypeId', pickUpTypeId);
        saveToLocalStorage('ds_deliveryDateId', deliveryDateId);
    }

    // public get storeId(): number {
    //     const ds_storeId = loadFromLocalStorage('ds_storeId');
    //     if (ds_storeId) {
    //         const ret = parseInt(ds_storeId);
    //         return ret;
    //     } else {
    //         return null;
    //     }
    // }

    public get companyId(): number {
        const ds_companyId = loadFromLocalStorage('ds_companyId');
        if (ds_companyId) {
            const ret = parseInt(ds_companyId);
            return ret;
        } else {
            return null;
        }
    }

    public get pickUpTypeId(): number {
        const ds_pickUpTypeId = loadFromLocalStorage('ds_pickUpTypeId');
        if (ds_pickUpTypeId) {
            const ret = parseInt(ds_pickUpTypeId);
            return ret;
        } else {
            return null;
        }
    }

    public get deliveryDateId(): number {
        const ds_deliveryDateId = loadFromLocalStorage('ds_deliveryDateId');
        if (ds_deliveryDateId) {
            const ret = parseInt(ds_deliveryDateId);
            return ret;
        } else {
            return null;
        }
    }

    public ClearDeliverySettings(): void {
        removeFromLocalStorage('ds_storeId');
        removeFromLocalStorage('ds_pickUpTypeId');
        removeFromLocalStorage('ds_deliveryDateId');
        removeFromLocalStorage('ds_companyId');
    }

    public RefreshPriceList(): Observable<IRefreshPriceListResult> {
        return this.http.get<IRefreshPriceListResult>('api/header-delivery/refresh-prices');
    }

    public IsOpenedDeliverySettings: boolean = false;
}

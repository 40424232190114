import {Pipe, PipeTransform} from "@angular/core";
import {CartItemSelectorFull, UnitType} from "../cart/common";
import {IProductAmountSettings} from "./product-amount/product-amount.component";
import {Sentences} from "../../helpers/sentences";
import {MathUtils} from "../../helpers/MathUtils";

@Pipe({name: 'CartProductAmount'})
export class CartProductAmountPipe implements PipeTransform {

    transform(cartItem: CartItemSelectorFull): IProductAmountSettings {
        if (!cartItem || !cartItem.productDetail)
            return null;

        let res: IProductAmountSettings = null;

        let product = cartItem.productDetail;

        if (cartItem.unitType === UnitType.Original) {

            res = {
                Factor: product.minQtyOrder || 1,
                PackageFactor: [product.unitsInPackage, product.unitsInLayer, product.unitsOnPallete],
                MaxQuantity: product.maxQtyOrder || Number.MAX_VALUE,
                MinQuantity: product.minQtyOrder || 1,
                ProductId: product.id,
                Unit: (product.unit && product.unit.shortCut) || Sentences.sen['app-pcs']
            }

        } else if (cartItem.unitType === UnitType.Pieces) {

            let minQty: number = (product.minQtyOrder && MathUtils.Round(product.minQtyOrder / product.weight, 2)) || 1;
            const diff: number = minQty - MathUtils.Round(minQty, 0);
            if (diff > 0) {
                minQty = MathUtils.Round(minQty, 0) + 1;
            } else {
                minQty = MathUtils.Round(minQty, 0);
            }

            res = {
                Factor: minQty,
                PackageFactor: [product.unitsInPackage, product.unitsInLayer, product.unitsOnPallete],
                MaxQuantity: (product.maxQtyOrder && MathUtils.Round(product.maxQtyOrder / product.weight, 2)) || Number.MAX_VALUE,
                MinQuantity: minQty,
                ProductId: product.id,
                Unit: Sentences.sen['app-pcs']
            };

        }

        return res;
    }

}
import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

export interface IAdultnessInfo {
    IsAdult: boolean | null;
}

@Injectable()
export class AdultConfirmationService {

    constructor(
        private http: HttpClient
    ) {
    }

    public GetAdultnessInfo(): Observable<IAdultnessInfo> {
        return this.http.get<IAdultnessInfo>('api/user/get-adultness-info');
    }

    public SetAdultnessInfo(isAdult: boolean): Observable<boolean> {
        return this.http.post<boolean>('api/user/save-adultness-info', { IsAdult: isAdult });
    }

}

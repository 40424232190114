import {Injectable} from "@angular/core";
import {Subject} from "rxjs";
import {debounceTime, take} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {DataService} from "./data.service";
import {OrderItemSelector} from "../modules/cart/common";
import {loadFromSession} from "../helpers/cookie.helper";

export interface IReservationMetrics {
    reservationId: number;
    viewVisitCount?: number;
    clickCount?: number;
    conversionCount?: number;
    productCode?: string;
}

export interface IReservationProduct {
    reservationId: number;
    productCode: string;
}

@Injectable({
    providedIn: 'root'
})
export class RbpMetricsService {

    sendMetricsSubject: Subject<any> = null;
    private collectedRbpMetrics: IReservationMetrics[] = [];
    private visitedRbpProductCodes: IReservationProduct[] = [];
    debug: boolean = false;

    constructor(private http: HttpClient,
                public dataSvc: DataService) {

        if(dataSvc.domain.includes("scdev.cz")) {
            this.debug = true;
        }

        //flush collected merics at navigation out and browser or session close
        window.addEventListener("beforeunload", (event) => {
            this.sendCollectedMetrics();
            return event;
        });
    }

    ngOnDestroy(): void {
        this.sendCollectedMetrics();
    }

    public addRbpVisitLocal(reservationID: number, productCode?: string): void {
        if(this.debug) {
            console.log("Collected reservation metrics: Reservation Visit, reservation ID ", reservationID);
            console.log(this.collectedRbpMetrics);
        }

        let crm = this.collectedRbpMetrics.find(crm => crm.reservationId == reservationID);

        if(!crm) {
            crm = {
                reservationId: reservationID,
                viewVisitCount: 1,
                clickCount: 0,
                conversionCount: 0,
                productCode: productCode
            }

            this.collectedRbpMetrics.push(crm);
        }
        else {
            crm.viewVisitCount++;
        }

        this.saveMetrics();

        if(productCode) {
            if(!this.visitedRbpProductCodes.find(c => c.productCode == productCode)) {
                let newVisitedProduct: IReservationProduct = {reservationId: reservationID, productCode: productCode};
                this.visitedRbpProductCodes.push(newVisitedProduct);
            }
        }
    }

    public addRbpClickLocal(reservationID: number, productCode?: string): void {
        if(this.debug) {
            console.log("Collected reservation metrics: Reservation Click, reservation ID ", reservationID);
            console.log(this.collectedRbpMetrics);
        }

        let reservationFromSession = loadFromSession('reservationId');

        if (reservationFromSession != reservationID) sessionStorage.setItem('reservationId', reservationID.toString());

        let crm = this.collectedRbpMetrics.find(crm => crm.reservationId == reservationID);

        if(!crm) {
            crm = {
                reservationId: reservationID,
                viewVisitCount: 0,
                clickCount: 1,
                conversionCount: 0,
                productCode: productCode
            }

            this.collectedRbpMetrics.push(crm);
        }
        else {
            crm.clickCount++;
        }

        this.saveMetrics();

        if(productCode) {
            if(!this.visitedRbpProductCodes.find(c => c.productCode == productCode)) {
                let newVisitedProduct: IReservationProduct = {reservationId: reservationID, productCode: productCode};
                this.visitedRbpProductCodes.push(newVisitedProduct);
            }
        }
    }

    private addRbpConversionItemLocal(reservationID: number, pcs: number): void {
        let crm = this.collectedRbpMetrics.find(crm => crm.reservationId == reservationID);

        if(!crm) {
            crm = {
                reservationId: reservationID,
                viewVisitCount: 0,
                clickCount: 0,
                conversionCount: pcs,
                productCode: null
            }

            this.collectedRbpMetrics.push(crm);
        }
        else {
            crm.conversionCount + pcs;
        }
    }

    public addRbpConversionLocal(orderItems: OrderItemSelector[]) {
        orderItems.forEach(oi => {
            let filtered = this.visitedRbpProductCodes.filter(rpc => rpc.productCode == oi.code);

            filtered.forEach(f => {
                this.addRbpConversionItemLocal(f.reservationId, oi.count);
            });
        });

        this.saveMetrics();

        if(this.debug) {
            console.log("Collected reservation metrics: Conversion counts.");
            console.log(this.collectedRbpMetrics);
        }

    }

    public saveMetrics() {
        if(!this.sendMetricsSubject) {
            this.sendMetricsSubject = new Subject<any>();

            this.sendMetricsSubject
                .pipe(debounceTime(30000))
                .subscribe(() => {
                    this.sendCollectedMetrics();

                    if(this.debug) {
                        console.log("Sending reservation metrics:");
                        console.log(this.collectedRbpMetrics);
                    }
                })
        }

        this.sendMetricsSubject.next();
    }

    public sendCollectedMetrics() {
        if(this.collectedRbpMetrics && this.collectedRbpMetrics.length) {
            this.http.post('api/generallogic/collect', this.collectedRbpMetrics)
                .pipe(take(1))
                .subscribe(() => {
                    this.collectedRbpMetrics = [];
                    if(this.debug) {
                        console.log("SendingDone");
                    }
                })
        }
    }

    public addRbpAddToCartLocal(reservationID: number, productCode?: string): void {
        let crm = {
            reservationId: reservationID,
            viewVisitCount: 0,
            clickCount: 0,
            conversionCount: 1,
            productCode: productCode
        }
        this.collectedRbpMetrics.push(crm);
        this.sendMetricsSubject = null;
        this.saveMetrics();
    }

}

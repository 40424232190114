/**
 * Little shopping cart view available on every page.
 */
import {Component, ViewEncapsulation} from '@angular/core';
import {CartService} from "../cart/cart.service";
import {Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";
import { CartItemSelectorBrief } from "../cart/common";
import { HttpClient } from "@angular/common/http";

@Component({
    selector: 'cmp-cart-indicator',
    templateUrl: '../../tpl/cart-indicator.html',
    styleUrls: ['../../assets/styles/3-layout/header/mini-cart.scss'],
    encapsulation: ViewEncapsulation.None
})

export class CartIndicatorComponent extends Translatable {

    imagePathPrefix: string;
    currencySymbol: string;
    decimalPattern: string = '1.0-0';
    checkCartLimitMessage: string = null;

    constructor(public dataSvc: DataService, public seSvc: SettingsService,
        public cartSvc: CartService, private http: HttpClient) {
        super(dataSvc, seSvc);

        this.decimalPattern = `1.${this.seSvc.settings.decimalSettings.Basket}-${this.seSvc.settings.decimalSettings.Basket}`;
        this.currencySymbol = this.seSvc.settings.currencies[this.seSvc.currencyId];
        this.imagePathPrefix = this.seSvc.settings.imageServerPathPrefix + '/fotocache/suggest/images/produkty/';

        this.cartSvc.cartContentChanged.subscribe(() => {
            this.http.get<string>('api/cart/checkCartLimit').subscribe((res) => {
                this.checkCartLimitMessage = res;
            })
        });
    }

    ngOnInit(): void {
        this.cartSvc.getCartContentForToken(false);
    }

    removeFromCart(cartItemId: number) {
        let req: CartItemSelectorBrief = {
            id: cartItemId
        };
        this.cartSvc.removeFromCart(req);
    }
}

import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable, ReplaySubject, Subject} from "rxjs";
import {CategoryItemSelector, FilterCategoryItemSelector} from "../../../interfaces/general";
import {DeliverySettingsService} from "../../../services/delivery-settings.service";
import {takeUntil} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class CategoryService {

    public categories: CategoryItemSelector[] = null;
    private dataReplay: ReplaySubject<CategoryItemSelector[]> = null;
    public lastCategories: CategoryItemSelector[] = null;

    ngUnsubscribe: Subject<any> = new Subject<any>();

    constructor(
        private http: HttpClient,
        private dsSvc: DeliverySettingsService
    ) {
        this.dsSvc.DeliverySettingsChanged
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
                this.categories = null;
                this.dataReplay = null;
                this.GetRootCategories();
            });
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    public GetRootCategories(): Observable<CategoryItemSelector[]> {
        if (!this.dataReplay) {
            this.dataReplay = new ReplaySubject();
            this.http.get<CategoryItemSelector[]>(`api/category/getRootCategories`)
                .subscribe(res => {
                    this.categories = res;
                    this.dataReplay.next(this.categories);
                    this.dataReplay.complete();
                });
        }

        return this.dataReplay;
    }

    public GetCategory(categoryId: number): Observable<CategoryItemSelector> {
        let sub: Subject<any> = new Subject<any>();
        this.http.get<CategoryItemSelector>(`api/category/${categoryId}`)
        .subscribe(res => {
            if (res) {
                if (res.subcategories && res.subcategories.length)
                    this.lastCategories =  res.subcategories;
                if (res.siblingCategories && res.siblingCategories.length)
                    this.lastCategories = res.siblingCategories;
            }

            sub.next(res);
            sub.complete();
        });

        return sub;
    }

    public get SuitableCategories(): CategoryItemSelector[] {
        if (this.lastCategories && this.lastCategories.length > 0) {
            return this.lastCategories;
        }
        else {
            return this.categories;
        }
    }

    public getCategoriesForFilter(categoryId: number): Observable<FilterCategoryItemSelector[]> {
        
        let categoriesForFilter: ReplaySubject<FilterCategoryItemSelector[]> = new ReplaySubject<FilterCategoryItemSelector[]>();
        this.http.get<FilterCategoryItemSelector[]>(`api/category/getCategoriesForFilter?forCategory=${categoryId}`)
            .subscribe(res => {
                if (res) {
                    categoriesForFilter.next(res);
                    categoriesForFilter.complete();
                }
            });
        return categoriesForFilter;
    }

}

import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {CartChangeModalComponent} from "./cart-change-modal.component";
import {ImageCacheBustModule} from "../../directives/image-cache-bust.module";
import {GeneralDialogModule} from "../general-dialog/general-dialog.module";
import {CartMultiChangeModalComponent} from "./cart-multi-change-modal.component";
import {PriceModule} from "../price/price.module";
import {ProductAvailabilityModule} from "../product-availability/product-availability.module";

@NgModule({
    imports: [
        CommonModule,
        ImageCacheBustModule,
        RouterModule,
        GeneralDialogModule,
        PriceModule,
        ProductAvailabilityModule
    ],
    declarations: [
        CartChangeModalComponent,
        CartMultiChangeModalComponent
    ],
    exports: [
        CartChangeModalComponent
    ],
    entryComponents: [
        CartChangeModalComponent,
        CartMultiChangeModalComponent
    ]
})

export class CartChangeModalModule {
}

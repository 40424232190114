import {Component, ViewEncapsulation} from '@angular/core';
import {Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";
import {BsLocaleService} from "ngx-bootstrap/datepicker";


@Component({
    selector: 'cmp-header-delivery-date',
    templateUrl: '../../tpl/header-delivery-date.html',
    styleUrls: ['../../../../../node_modules/ngx-bootstrap/datepicker/bs-datepicker.scss'],
    encapsulation: ViewEncapsulation.None
})

export class HeaderDeliveryDateComponent extends Translatable {

    selectedDate: any;
    today: any;

    constructor(public dataSvc: DataService, seSvc: SettingsService, private localeService: BsLocaleService) {
        super(dataSvc, seSvc);

        this.localeService.use('cs');

        this.today = new Date();
        this.selectedDate = new Date();
    }

    ngOnInit(): void {

    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    dateChange(newDate: Date): void {
        console.log(newDate);
    }
}
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from "@angular/router";
import {GoogleMapService} from "./google-map.service";

@Injectable()
export class GoogleMapGuard implements CanActivate {

    constructor(
        private gmSvc: GoogleMapService
    ) {
    }

    canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Promise<boolean> {
        return this.gmSvc.AppendToDOM();
    }
}
import {ProductSelector} from "../modules/product/common";
import {ArticleBriefSelector} from "../interfaces/general";

declare let $: any;

export interface IScrollToArgs {
    selector: string;
    speed?: number;
    margin?: number;
}

export class ScrollUtils {

    public static ScrollUp(): void {
        ScrollUtils.ScrollTo({selector: 'html, body'});
    }

    public static ScrollTo(data: IScrollToArgs): void {
        const d: IScrollToArgs = Object.assign({}, {
            selector: data.selector,
            speed: data.speed == null ? 250 : data.speed,
            margin: data.margin == null ? 0 : data.margin
        })
        const elements = $(`${d.selector}`);

        if (elements.length) {
            $('html, body').animate({
                scrollTop: elements.offset().top - d.margin
            }, d.speed);
        }
    }

    public static ScrollToOffset(offest: number, speed: number = 250): void {
        $('html, body').animate({
            scrollTop: offest
        }, speed);
    }

}

export interface IScrollStrategy<T> {
    Scroll(data: T): void;
}

export class NoScrollStrategy<T> implements IScrollStrategy<T> {
    Scroll(_data: T) {
    }
}

export class ScrollToOffsetStrategy<T> implements IScrollStrategy<T> {
    constructor(private offset: number) {
    }

    Scroll(_data: T) {
        setTimeout(() => {
            ScrollUtils.ScrollToOffset(this.offset);
        }, 0);
    }
}

export class ScrollToTopStrategy<T> implements IScrollStrategy<T> {
    Scroll(_data: T) {
        setTimeout(() => {
            ScrollUtils.ScrollTo({selector: `html, body`});
        }, 0);
    }
}

export class ScrollToCatalogTopStrategy<T> implements IScrollStrategy<T> {
    Scroll(_data: T) {
        setTimeout(() => {
            ScrollUtils.ScrollTo({selector: 'cmp-product-list', margin: 125});
        }, 0);
    }
}

export class ScrollToIdStrategy implements IScrollStrategy<number|string> {
    Scroll(id: any) {
        if (id !== null && id !== undefined) {
            setTimeout(() => {
                ScrollUtils.ScrollTo({selector: `#${id}`, margin: 25});
            }, 0);
        }
    }
}

export class ScrollToProductStrategy implements IScrollStrategy<ProductSelector[]> {
    Scroll(data: ProductSelector[]) {
        if (data && data.length) {
            setTimeout(() => {
                ScrollUtils.ScrollTo({selector: `.product__image-wrapper[id=${data[0].variantKey}], .product-row__image-wrapper[id=${data[0].variantKey}]`, margin: 25});
            }, 0);
        }
    }
}

export class ScrollToArticleStrategy implements IScrollStrategy<ArticleBriefSelector[]> {
    Scroll(data: ArticleBriefSelector[]) {
        if (data && data.length) {
            setTimeout(() => {
                ScrollUtils.ScrollTo({selector: `.article-box__link[id='article${data[0].articleId}']`, margin: 25});
            }, 0);
        }
    }
}

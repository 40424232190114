import {Component, Input, ViewEncapsulation} from '@angular/core';
import {ArticleBriefSelector, Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";

@Component({
    selector: 'cmp-article-box',
    templateUrl: '../../tpl/article-box.html',
    styleUrls: ['../../assets/styles/2-components/article-box.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ArticleBoxComponent extends Translatable {

    @Input() type: string = null;
    @Input() article: ArticleBriefSelector;

    imagePathPrefix: string;

    constructor(public dataSvc: DataService, seSvc: SettingsService) {
        super(dataSvc, seSvc);

        this.imagePathPrefix = `${this.seSvc.settings.imageServerPathPrefix}/fotocache/artnwhp/images/`;

    }
}
import {EventEmitter, Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {IHeaderDeliveryInfo} from "../interfaces/IHeaderDeliveryInfo";

export interface IHeaderDeliveryInfoRequest {
    StoreId: number;
    PickUpTypeId: number;
    DeliveryDateId: number;
}

@Injectable({
    providedIn: 'root'
})
export class HeaderDeliveryService {

    public ShowDialogRequest: EventEmitter<void> = new EventEmitter();
    public HeaderDeliveryInfo: IHeaderDeliveryInfo;

    constructor(
        private http: HttpClient
    ) {
    }

    public GetHeaderDeliveryInfo(): Observable<IHeaderDeliveryInfo> {
        let request = this.http.get<IHeaderDeliveryInfo>(`api/header-delivery/get-header-delivery-info`);

        request.subscribe(res => {
            this.HeaderDeliveryInfo = res;
        });

        return request;
    }

    public ShowHeaderDeliveryDialog(): void {
        this.ShowDialogRequest.emit();
    }
}

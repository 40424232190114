import {FormArray, FormControl, FormGroup} from "@angular/forms";

export class FormUtils {

    public static MakeDirtyGroup(f: FormGroup): void {
        for (let obj in f.controls) {
            if (f.controls[obj] instanceof FormArray) {
                this.MakeDirtyArray(<FormArray>f.controls[obj]);
            } else {
                f.controls[obj].markAsDirty();
            }
        }
    }

    public static MakeDirtyArray(f: FormArray): void {
        for (let i = 0; i < f.controls.length; i++) {
            if (f.controls[i] instanceof FormArray) {
                this.MakeDirtyArray(<FormArray>f.controls[i]);
            } else if (f.controls[i] instanceof FormGroup) {
                this.MakeDirtyGroup(<FormGroup>f.controls[i]);
            } else if (f.controls[i] instanceof FormControl) {
                f.controls[i].markAsDirty();
            }
        }
    }

    public static MakeUntouchedGroup(f: FormGroup): void {
        for (let obj in f.controls) {
            if (f.controls[obj] instanceof FormArray) {
                this.MakeUntouchedArray(<FormArray>f.controls[obj]);
            } else {
                f.controls[obj].markAsUntouched();
            }
        }
    }

    public static MakeUntouchedArray(f: FormArray): void {
        for (let i = 0; i < f.controls.length; i++) {
            if (f.controls[i] instanceof FormArray) {
                this.MakeUntouchedArray(<FormArray>f.controls[i]);
            } else if (f.controls[i] instanceof FormGroup) {
                this.MakeUntouchedGroup(<FormGroup>f.controls[i]);
            } else if (f.controls[i] instanceof FormControl) {
                f.controls[i].markAsUntouched();
            }
        }
    }

    public static MakeTouchedGroup(f: FormGroup): void {
        for (let obj in f.controls) {
            if (f.controls[obj] instanceof FormArray) {
                this.MakeTouchedArray(<FormArray>f.controls[obj]);
            } else {
                f.controls[obj].markAsTouched();
            }
        }
    }

    public static MakeTouchedArray(f: FormArray): void {
        for (let i = 0; i < f.controls.length; i++) {
            if (f.controls[i] instanceof FormArray) {
                this.MakeTouchedArray(<FormArray>f.controls[i]);
            } else if (f.controls[i] instanceof FormGroup) {
                this.MakeTouchedGroup(<FormGroup>f.controls[i]);
            } else if (f.controls[i] instanceof FormControl) {
                f.controls[i].markAsTouched();
            }
        }
    }


    public static MakePristineGroup(f: FormGroup): void {
        for (let obj in f.controls) {
            if (f.controls[obj] instanceof FormArray) {
                this.MakePristineArray(<FormArray>f.controls[obj]);
            } else {
                f.controls[obj].markAsPristine();
            }
        }
    }

    public static MakePristineArray(f: FormArray): void {
        for (let i = 0; i < f.controls.length; i++) {
            if (f.controls[i] instanceof FormArray) {
                this.MakePristineArray(<FormArray>f.controls[i]);
            } else if (f.controls[i] instanceof FormGroup) {
                this.MakePristineGroup(<FormGroup>f.controls[i]);
            } else if (f.controls[i] instanceof FormControl) {
                f.controls[i].markAsPristine();
            }
        }
    }

    public static UpdateValueAndValidityGroup(f: FormGroup): void {
        for (let obj in f.controls) {
            if (f.controls[obj] instanceof FormArray) {
                this.UpdateValueAndValidityArray(<FormArray>f.controls[obj]);
            } else {
                f.controls[obj].updateValueAndValidity();
            }
        }
    }

    public static UpdateValueAndValidityArray(f: FormArray): void {
        for (let i = 0; i < f.controls.length; i++) {
            if (f.controls[i] instanceof FormArray) {
                this.UpdateValueAndValidityArray(<FormArray>f.controls[i]);
            } else if (f.controls[i] instanceof FormGroup) {
                this.UpdateValueAndValidityGroup(<FormGroup>f.controls[i]);
            } else if (f.controls[i] instanceof FormControl) {
                f.controls[i].updateValueAndValidity();
            }
        }
    }


}
import {Component, Input, ViewEncapsulation} from '@angular/core';
import {PageModeType, Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";
import {Subject} from "rxjs";
import {IdentityService} from "../../services/identity.service";

@Component({
    selector: 'cmp-header-motto',
    templateUrl: '../../tpl/header-motto.html',
    styleUrls: ['../../assets/styles/3-layout/header/motto.scss'],
    encapsulation: ViewEncapsulation.None
})

export class HeaderMottoComponent extends Translatable {

    @Input() pageMode: PageModeType;

    ngUnsubscribe: Subject<any> = new Subject<any>();

    constructor(public dataSvc: DataService, seSvc: SettingsService, private idSvc: IdentityService) {
        super(dataSvc, seSvc);


    }

    ngOnInit(): void {

    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        super.ngOnDestroy();
    }

    get mottoHtml(): string {
        let item = this.idSvc.getMottoSettings(this.seSvc.culture.cultureId);
        return item && item.motto;
    }
}
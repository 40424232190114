import {Pipe, PipeTransform} from '@angular/core';
import {ParameterValueSelector} from "../product/common";

@Pipe({
    name: 'paramFormat'
})

export class ParamFormatPipe implements PipeTransform {
    constructor() {
    }

    transform(value: ParameterValueSelector): any {
        if (value.stringValue)
            return value.stringValue;
        if (value.numericValue || value.numericValue === 0)
            return value.numericValue;

        return '\u00A0'; // == '&nbsp;'
    }
}